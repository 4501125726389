import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components'
import Text from 'lasagna/components/text'
import PropTypes from 'prop-types'
import { useDropdownMenu, useDropdownToggle, Dropdown } from 'react-overlays'
import { COLOR_WHITE } from '../constants/layout'
import { COLOR_INPUT_LIGHT_BORDER, COLOR_TEXT_PRIMARY } from 'lasagna/constants/colors'
import Chevron from './chevron'
import { observer } from 'mobx-react'

const PickerItemContainer = styled('div')`
  border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
  border-radius: 5px;
  padding: 10px 16px;
  background: ${COLOR_WHITE};
  min-width: 350px;
`

const ToggleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const MenuContainer = styled('div')`
  position: absolute;
  display: ${props => (props.visible ? 'flex' : 'none')};
  background: ${COLOR_WHITE};
  border-radius: 5px;
`

const OptionWrapper = styled(TouchableOpacity)`
  margin: 4px 0;
`

const ChevronWrapper = observer(({ open }) => {
  return <Chevron open={open} color={COLOR_TEXT_PRIMARY} />
})

const Toggle = ({ selected, placeholder }) => {
  const { show } = useDropdownMenu()
  const [props, { toggle }] = useDropdownToggle()
  return (
    <div id='picker' onClick={toggle} {...props} data-testid='Picker'>
      <PickerItemContainer>
        <ToggleContainer>
          {selected && <Text>{selected}</Text>}
          {!selected && <Text>{placeholder}</Text>}
          <ChevronWrapper open={show} />
        </ToggleContainer>
      </PickerItemContainer>
    </div>
  )
}

const Menu = ({ onSelect, options = [] }) => {
  const { show, props } = useDropdownMenu({ flip: true })
  // eslint-disable-next-line no-unused-vars
  const [_props, { toggle }] = useDropdownToggle()

  const onOptionSelected = value => {
    onSelect(value)
    toggle()
  }

  return (
    <MenuContainer {...props} visible={show}>
      <PickerItemContainer>
        {options.map(({ key, label }) => (
          <OptionWrapper onPress={() => onOptionSelected(key)} key={key} testID='PickerItem'>
            <Text>{label}</Text>
          </OptionWrapper>
        ))}
      </PickerItemContainer>
    </MenuContainer>
  )
}

const Picker = ({ selected, onSelect, options, placeholder }) => {
  return (
    <Dropdown>
      {({ props }) => (
        <View {...props}>
          <Toggle selected={selected} placeholder={placeholder} />
          <Menu onSelect={onSelect} options={options} />
        </View>
      )}
    </Dropdown>
  )
}

Picker.propTypes = {
  selected: PropTypes.string,
  onSelect: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string
}

Menu.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.array
}

Toggle.propTypes = {
  selected: PropTypes.string,
  placeholder: PropTypes.string
}

export default observer(Picker)
