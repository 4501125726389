import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { Platform, Image } from 'react-native'
import { COLOR_WHITE } from '../../constants/layout'
import { TouchableOpacity } from 'react-native-web'

const LogoImage = styled(Image)`
  width: 180px;
  height: 37px;
  margin-top: 3px;
  background-color: ${({ background }) => background};
`

const Logo = ({ onPress, background = COLOR_WHITE, style }) => (
  <TouchableOpacity onPress={onPress} disabled={!!onPress}>
    <LogoImage
      background={background}
      style={style}
      source={Platform.OS === 'web' ? require('../../assets/images/logo.svg') : require('../../assets/images/logo.png')}
    />
  </TouchableOpacity>
)

Logo.propTypes = {
  onPress: PropTypes.func,
  background: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

export default Logo
