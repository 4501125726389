import React, { useEffect } from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useStore } from '../helpers/store'
import ContentContainer from '../components/layout/content_container'
import ContractNotFound from '../components/contracts/not_found'
import ContractDetails from '../components/contracts/details'
import OneOffContractDetails from '../components/contracts/one_off_details'
import DownloadButton from '../components/contracts/download_button'
import OverviewLink from '../components/contracts/overview_link'
import Spinner from '../components/spinner'
import { SERVICE_TYPES } from '../constants/service_types'

const ButtonContainer = styled(View)`
  align-items: center;
  margin-top: 18px;
`

const Contract = ({
  match: {
    params: { id, token }
  }
}) => {
  const {
    contracts: { fetchContract, get },
    session: { currentUser }
  } = useStore()

  useEffect(() => {
    fetchContract(id, token)
  }, [])

  const contract = get(id)

  if (contract === undefined) return <Spinner />

  if (contract === null) return <ContractNotFound />

  const { downloadPdfPath, serviceType } = contract

  return (
    <ContentContainer testID='ContractDetails'>
      {currentUser && <OverviewLink />}
      {!SERVICE_TYPES[serviceType]?.oneOff && (
        <ContractDetails contract={contract} title={i18n.t('contracts.details.title')} />
      )}
      {SERVICE_TYPES[serviceType]?.oneOff && (
        <OneOffContractDetails contract={contract} title={i18n.t('contracts.details.title')} />
      )}
      <ButtonContainer>
        <DownloadButton path={downloadPdfPath} />
      </ButtonContainer>
    </ContentContainer>
  )
}

Contract.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      token: PropTypes.string
    })
  })
}

export default observer(Contract)
