import React from 'react'
import PropTypes from 'lasagna/prop_types'
import { View } from 'react-native'
import Card from 'lasagna/components/layout/card'
import BaseText from 'lasagna/components/text'
import { COLOR_BLACK } from 'lasagna/constants/colors'
import styled from 'styled-components/native'
import ContractModel from '../../models/contracts'
import { TEMPORARY_CLEANING } from '../../constants/service_types'

const PriceCard = styled(Card)`
  padding: 30px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Text = styled(BaseText)`
  color: ${COLOR_BLACK};
`

const TotalPrice = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  margin-right: 4px;
`

const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
`

const PricePerMonth = styled(View)`
  flex-direction: row;
  align-items: baseline;
`

const Price = ({ contract: { price, hasMonthlyPrice, hasPerDeliveryPrice, serviceType, vat } }) => {
  return (
    <PriceCard>
      <View>
        <Title>{i18n.t('contracts.details.total_price')}</Title>
        {serviceType === TEMPORARY_CLEANING && <Text>{i18n.t('contracts.details.total_events.all_events')}</Text>}
      </View>
      <View>
        <PricePerMonth>
          <TotalPrice>{i18n.t('contracts.details.formatted_price', { price })}</TotalPrice>
          {hasMonthlyPrice && <Text>{i18n.t('contracts.details.per_month')}</Text>}
          {hasPerDeliveryPrice && <Text>{i18n.t('contracts.details.per_delivery')}</Text>}
        </PricePerMonth>
        <BaseText>{i18n.t('contracts.details.vat_excluded', { vat })}</BaseText>
      </View>
    </PriceCard>
  )
}

Price.propTypes = {
  contract: PropTypes.modelOf(ContractModel)
}

export default Price
