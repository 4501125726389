import { types, flow } from 'mobx-state-tree'
import Customer from '../models/customer'
import api from '../api'
import sortBy from 'lodash/sortBy'

const CustomersStore = types
  .model('CustomersStore', {
    collection: types.map(Customer),
    loading: types.optional(types.boolean, false)
  })
  .views(self => ({
    get(id) {
      return self.collection.get(id)
    },
    get customers() {
      return sortBy(Array.from(self.collection.values()), 'companyName')
    },
    get customersCount() {
      return self.customers.length
    }
  }))
  .actions(self => ({
    fetchCustomers: flow(function* () {
      self.loading = true

      const {
        data: {
          facilityServices: {
            facilities: { nodes }
          }
        }
      } = yield api(self).fetchCustomers()

      const customers = nodes.map(customer => self.put(customer))

      self.loading = false
      return customers
    }),
    put(data) {
      const { serviceAddress, ...rest } = data
      const customer = {
        address: serviceAddress?.address,
        ...rest
      }

      return self.collection.put(customer)
    }
  }))

export default CustomersStore
