import React from 'react'
import { View, Image, Platform } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { useStore } from '../../helpers/store'
import { COLOR_BLACK, COLOR_TEXT_DARK, COLOR_LIGHT_ICON } from '../../constants/layout'
import NavigationCard from 'lasagna/components/layout/navigation_card'
import { useNavigation } from '../../helpers/navigation'
import { contractOfferDetailsPath } from '../../helpers/urls'
import { SERVICE_TYPES } from '../../constants/service_types'

const Title = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 16px;
`

const ContractTitle = styled(Text)`
  color: ${COLOR_TEXT_DARK};
  font-weight: 600;
`
const ContractDescription = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
`

const Description = styled(Text)`
  margin-left: 4px;
`

const ContractCard = styled(NavigationCard)`
  margin-bottom: 8px;
`

const ServiceTypeIcon = styled(Image)`
  height: 13px;
  ${({ type }) => (type === SERVICE_TYPES.water_dispenser.key ? 'width: 10px;' : 'width: 11px;')}
`

const Container = styled(View)`
  padding-bottom: 18px;
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_LIGHT_ICON};
  margin-bottom: 18px;
`

const ContractOffers = () => {
  const {
    contracts: { offers }
  } = useStore()

  if (!offers.length) return null

  const navigate = useNavigation()
  const goToOffer = id => navigate(contractOfferDetailsPath(id))

  return (
    <Container testID='ContractOffers'>
      <Title>{i18n.t('contracts.offers.title')}</Title>
      {offers.map(({ id, customer, serviceType }) => (
        <ContractCard
          elevation={1}
          key={id}
          onPress={() => goToOffer(id)}
          chevronColor={COLOR_LIGHT_ICON}
          chevronRight
          testID='ContractOfferCard'
        >
          <ContractTitle>{customer.name}</ContractTitle>
          <ContractDescription>
            {SERVICE_TYPES[serviceType] && (
              <>
                {SERVICE_TYPES[serviceType]?.iconBlack && (
                  <ServiceTypeIcon
                    source={
                      Platform.OS === 'web'
                        ? require(`../../${SERVICE_TYPES[serviceType].iconBlack}.svg`)
                        : require(`../../${SERVICE_TYPES[serviceType].iconBlack}.png`)
                    }
                    type={serviceType}
                  />
                )}
                <Description>{i18n.t(`service_types.${serviceType}.title`)}</Description>
              </>
            )}
          </ContractDescription>
        </ContractCard>
      ))}
    </Container>
  )
}

export default ContractOffers
