import React from 'react'
import { useHistory } from '../../components/router'
import openURL from './open_url'

export const useNavigation = () => {
  const history = useHistory()
  return (url, params = {}, requireAuth = false) => {
    if (url.startsWith('http')) {
      return openURL(url, requireAuth)
    }
    return history.push(url, params)
  }
}

export const withNavigation = BaseComponent => props => {
  const navigate = useNavigation()
  return <BaseComponent {...props} navigate={navigate} />
}
