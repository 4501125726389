import React, { useState } from 'react'
import { View, Platform, Image, TouchableOpacity } from 'react-native'
import PropTypes from 'lasagna/prop_types'
import Card from 'lasagna/components/layout/card'
import Icon from 'lasagna/components/icon'
import BaseText from 'lasagna/components/text'
import { COLOR_WHITE, COLOR_BLACK } from 'lasagna/constants'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import { DateTime } from 'luxon'
import CancellationModal from './cancellation_modal'
import RescheduleModal from './reschedule_modal'
import {
  COLOR_LABEL_LIGHT,
  COLOR_LIGHT_ICON,
  COLOR_HIGHLIGHT_BLUE,
  COLOR_BACKGROUND_DARK,
  COLOR_GREEN_ACTION,
  COLOR_RED_ACTION
} from '../../constants/layout'
import { SERVICE_TYPES } from '../../constants/service_types'
import AppointmentModel from '../../models/appointment'

const Text = styled(BaseText)`
  color: ${COLOR_BLACK};
`

const DetailsCard = styled(Card)`
  padding: 20px;
  margin-bottom: ${({ last }) => (last ? 0 : '18px')};
`

const Detail = styled(View)`
  align-items: center;
  flex-direction: row;
  flex-shrink: 1;
`

const ContractText = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 4px 8px;
`

const ServiceTypeIcon = styled(Image)`
  height: 13px;
  width: 11px;
  margin-right: 4px;
`

const StatusLabel = styled(Card)`
  background-color: ${({ backgroundColor }) => backgroundColor || COLOR_LABEL_LIGHT};
  margin-left: 6px;
  padding: 4px 10px;
`

const Status = styled(Text)`
  font-weight: 600;
  font-size: 12px;
  color: ${({ color }) => color || COLOR_BLACK};
`

const Label = styled(View)`
  border-radius: 5px;
  background: ${COLOR_BACKGROUND_DARK};
  margin-left: 6px;
  padding: 4px 10px;
`

const LabelText = styled(Text)`
  color: ${COLOR_WHITE};
`

const ExtraLabel = styled(StatusLabel)`
  background: ${COLOR_HIGHLIGHT_BLUE};
`

const Extra = styled(Status)`
  color: ${COLOR_WHITE};
`

const StatusTime = styled(View)`
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`

const ProvidersDetail = styled(Detail)`
  align-items: flex-start;
`

const ProviderNames = styled(ContractText)`
  line-height: 16px;
  margin: 0;
`

const Providers = styled(View)`
  margin-left: 8px;
  flex: 1;
  flex-wrap: wrap;
`

const ProvidersIcon = styled(Icon)`
  margin-top: 2px;
`

const ProvidersTitle = styled(ContractText)`
  margin: 0;
`

const Actions = styled(View)`
  border-top-width: 1px;
  border-top-color: ${COLOR_LIGHT_ICON};
  margin-top: 10px;
  padding-top: 10px;
  flex-shrink: 1;
`

const ReportIssue = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
`

const ReportIssueLink = styled(Text)`
  flex-wrap: wrap;
  flex-shrink: 1;
`

const LinkText = styled(Text)`
  font-size: 14px;
  text-decoration: underline;
`

const ActionButtonText = styled(Text)`
  font-size: 13px;
`

const ActionButton = styled(TouchableOpacity)`
  border-radius: 16px;
  min-width: 110px;
  padding: 6px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const RescheduleButton = styled(ActionButton)`
  background-color: ${COLOR_GREEN_ACTION};
`

const CancelButton = styled(ActionButton)`
  background-color: ${COLOR_RED_ACTION};
`

const Row = styled(View)`
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 15px;
`

const AppointmentsList = ({ reportIssue, appointment, last }) => {
  const [showCancellationModal, setShowCancellationModal] = useState()
  const [showRescheduleModal, setShowRescheduleModal] = useState()
  const {
    id,
    serviceType,
    startTimeObj,
    cancelled,
    provider,
    extra,
    serviceAddress,
    confirmed,
    canRequestReschedule,
    isBankHoliday
  } = appointment

  const isFutureEvent = startTimeObj >= DateTime.local()

  return (
    <DetailsCard last={last}>
      {showRescheduleModal && (
        <RescheduleModal
          appointment={appointment}
          handleClose={() => {
            setShowRescheduleModal(false)
          }}
        />
      )}
      {showCancellationModal && (
        <CancellationModal
          appointment={appointment}
          handleClose={() => {
            setShowCancellationModal(false)
          }}
        />
      )}
      <StatusTime>
        <Detail>
          <Icon name='domain' set='MaterialCommunityIcons' color={COLOR_BLACK} size={14} />
          <ContractText>{serviceAddress}</ContractText>
        </Detail>
        {extra && (
          <ExtraLabel elevation={0}>
            <Extra>++ {i18n.t('schedule.extra')}</Extra>
          </ExtraLabel>
        )}
        {cancelled && (
          <StatusLabel elevation={0} backgroundColor={COLOR_RED_ACTION}>
            <Status color={COLOR_WHITE}>{i18n.t('schedule.cancelled')}</Status>
          </StatusLabel>
        )}
        {confirmed && (
          <Label>
            <LabelText>{i18n.t('schedule.confirmed')}</LabelText>
          </Label>
        )}
        {isBankHoliday && (
          <StatusLabel elevation={0}>
            <Status>{i18n.t('schedule.holiday')}</Status>
          </StatusLabel>
        )}
      </StatusTime>
      <Detail>
        <Icon name='schedule' color={COLOR_BLACK} size={14} />
        <ContractText>{startTimeObj.toLocaleString(DateTime.TIME_SIMPLE)}</ContractText>
      </Detail>
      <Detail>
        {SERVICE_TYPES[serviceType] && (
          <>
            {SERVICE_TYPES[serviceType]?.iconBlack && (
              <ServiceTypeIcon
                source={
                  Platform.OS === 'web'
                    ? require(`../../${SERVICE_TYPES[serviceType].iconBlack}.svg`)
                    : require(`../../${SERVICE_TYPES[serviceType].iconBlack}.png`)
                }
              />
            )}
            <ContractText>{i18n.t(`service_types.${serviceType}.title`)}</ContractText>
          </>
        )}
      </Detail>
      {provider && (
        <ProvidersDetail>
          <ProvidersIcon name='person-outline' color={COLOR_BLACK} size={14} />
          <Providers>
            <ProvidersTitle>{i18n.t('schedule.selected_date.providers')}:</ProvidersTitle>
            <ProviderNames>{provider.fullName}</ProviderNames>
          </Providers>
        </ProvidersDetail>
      )}
      <Actions>
        <Row>
          {!cancelled && canRequestReschedule && (
            <RescheduleButton onPress={() => setShowRescheduleModal(true)}>
              <ActionButtonText>{i18n.t('schedule.actions.reschedule.cta')}</ActionButtonText>
            </RescheduleButton>
          )}
          {!cancelled && isFutureEvent && (
            <CancelButton onPress={() => setShowCancellationModal(true)}>
              <ActionButtonText>{i18n.t('schedule.actions.cancel')}</ActionButtonText>
            </CancelButton>
          )}
        </Row>
        <ReportIssue onPress={() => reportIssue(id)}>
          <ReportIssueLink>
            <LinkText>{i18n.t('schedule.selected_date.report_issue')}</LinkText>
            <Icon name='arrow-top-right' set='MaterialCommunityIcons' color={COLOR_BLACK} size={16} />
          </ReportIssueLink>
        </ReportIssue>
      </Actions>
    </DetailsCard>
  )
}

AppointmentsList.propTypes = {
  reportIssue: PropTypes.func,
  appointment: PropTypes.modelOf(AppointmentModel),
  last: PropTypes.bool
}

export default observer(AppointmentsList)
