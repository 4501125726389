import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { useLocation } from 'react-router-dom'
import PropTypes from 'lasagna/prop_types'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import { COLOR_BLACK } from '../../../constants/layout'
import { useNavigation } from '../../../helpers/navigation'
import { homePagePath, schedulePath, contactUsPath, invoicesPath, contractsPath } from '../../../helpers/urls'
import { useStore } from '../../../helpers/store'
import AccountDropdown from './account_dropdown'
import NewBooking from './new_booking'
import LanguageSelector from './language_selector'

const MenuContainer = styled(View)`
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  margin-right: -20px;
`

const MenuItemContainer = styled(TouchableOpacity)`
  padding: 20px;
`

const MenuItemText = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: bold;
  ${({ active }) =>
    active &&
    `
    text-decoration: underline;
    text-decoration-color: ${COLOR_BLACK};
  `}
`

const MenuItem = ({ onPress, children, active }) => (
  <MenuItemContainer onPress={onPress}>
    {typeof children === 'string' ? <MenuItemText active={active}>{children}</MenuItemText> : children}
  </MenuItemContainer>
)

MenuItem.propTypes = {
  onPress: PropTypes.func,
  children: PropTypes.node,
  active: PropTypes.bool
}

const Navbar = () => {
  const navigate = useNavigation()

  const { pathname: currentPath } = useLocation()

  const {
    session: { currentUser }
  } = useStore()

  return (
    <MenuContainer>
      <MenuItem
        onPress={() => navigate(homePagePath())}
        active={currentPath === homePagePath() || currentPath === homePagePath() + '/'}
      >
        {i18n.t('navigation.overview')}
      </MenuItem>
      {currentUser && (
        <>
          <MenuItem onPress={() => navigate(contractsPath())} active={currentPath === contractsPath()}>
            {i18n.t('navigation.contracts')}
          </MenuItem>
          <MenuItem onPress={() => navigate(schedulePath())} active={currentPath === schedulePath()}>
            {i18n.t('navigation.schedule')}
          </MenuItem>
          <MenuItem onPress={() => navigate(contactUsPath())} active={currentPath === contactUsPath()}>
            {i18n.t('navigation.contact_us')}
          </MenuItem>
          <MenuItem onPress={() => navigate(invoicesPath())} active={currentPath === invoicesPath()}>
            {i18n.t('navigation.invoices')}
          </MenuItem>
          <NewBooking />
        </>
      )}
      <LanguageSelector />
      <AccountDropdown />
    </MenuContainer>
  )
}

export default Navbar
