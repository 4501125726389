import { DateTime } from 'luxon'

export const MIN_ALLOWED_RESCHEDULE_DATE = DateTime.now().plus({ day: 2 }).toJSDate()
export const MAX_ALLOWED_RESCHEDULE_DATE = DateTime.now()
  .startOf('month')
  .plus({ month: 2 })
  .minus({ day: 1 })
  .toJSDate()

export const EXCLUDED_DAYS = [0]

export const RESCHEDULE_EVENT_CUSTOMER_REASONS = [
  'customer_request_location_occupied',
  'customer_request_service_not_needed',
  'customer_request_construction_works',
  'other'
]
