import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import ServiceCard from '../new_booking/service_card'
import { useStore } from '../../helpers/store'
import { useResponsive } from 'lasagna/components/responsive'

const Container = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ desktop }) => (desktop ? 'space-around' : 'space-between')};
`

const NewBooking = () => {
  const {
    pages: {
      dashboard: { activeServiceTypes }
    }
  } = useStore()

  const { isDesktop } = useResponsive()

  const services = isDesktop
    ? ['maintenance_cleaning', 'fruit_basket_delivery', 'water_dispenser', 'coffee_machine']
    : ['fruit_basket_delivery', 'water_dispenser']

  return (
    <Container testID='NewBookingCards' desktop={isDesktop}>
      {services.map(serviceType => (
        <ServiceCard
          serviceType={serviceType}
          key={serviceType}
          active={activeServiceTypes.indexOf(serviceType) > -1}
        />
      ))}
    </Container>
  )
}

export default NewBooking
