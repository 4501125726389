import React, { useState } from 'react'
import DropDownPicker from 'react-native-dropdown-picker'
import { Image, View } from 'react-native'
import styled from 'styled-components/native'
import { useStore } from '../../../helpers/store'

const OptionIcon = styled(Image)`
  height: 15px;
  width: 20px;
`

const Container = styled(View)`
  margin-right: 8px;
`

const LanguageSelector = () => {
  const {
    session: {
      updateLocale,
      locale: currentLocale,
      countryConfig: { localesIcons }
    }
  } = useStore()

  const [open, setOpen] = useState(false)
  const [locale, setLocale] = useState(currentLocale)

  if (!localesIcons || localesIcons.length <= 1) return null

  const LANGUAGE_OPTIONS = localesIcons.map(({ key, icon }) => ({
    value: key,
    icon: () => <OptionIcon source={require(`../../../${icon}.svg`)} />
  }))

  return (
    <Container>
      <DropDownPicker
        items={LANGUAGE_OPTIONS}
        open={open}
        setOpen={setOpen}
        value={locale}
        setValue={setLocale}
        onChangeValue={updateLocale}
        style={{ flexDirection: 'row', alignItems: 'center', borderRadius: '0', marginTop: '-8px' }}
        containerStyle={{ width: '45px', marginLeft: '10px', marginTop: '12px' }}
        listItemContainerStyle={{ marginTop: '12px', marginBottom: '8px', alignItems: 'center' }}
        showTickIcon={false}
      />
    </Container>
  )
}

export default LanguageSelector
