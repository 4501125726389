import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { View } from 'react-native'
import { COLOR_BLACK } from 'lasagna/constants/colors'
import Icon from 'lasagna/components/icon'
import Text from 'lasagna/components/text'
import { useResponsive } from 'lasagna/components/responsive'
import Button from '../button'
import { COLOR_ACTION, TRANSPARENT } from '../../constants/layout'
import RejectModal from './reject_modal'
import AcceptModal from './accept_modal'

const ActionButton = styled(Button)`
  margin: 6px;
  ${({ isDesktop }) => isDesktop && 'flex-basis: 240px;'}
`

const Container = styled(View)`
  ${({ isDesktop }) => isDesktop && 'flex-direction: row;'}
  margin-top: 12px;
`

const ActionText = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: 600;
  margin-left: 4px;
`

const Actions = ({ id, token, acceptOffer }) => {
  const [showRejectModal, setShowRejectModal] = useState()
  const [showAcceptModal, setShowAcceptModal] = useState()
  const { isDesktop } = useResponsive()

  return (
    <Container isDesktop={isDesktop} testID='OfferActions'>
      <ActionButton
        onPress={() => setShowAcceptModal(true)}
        color={COLOR_ACTION}
        textColor={COLOR_BLACK}
        borderColor={COLOR_ACTION}
        borderWidth={1}
        isDesktop={isDesktop}
        testID='OfferAcceptModalOpenButton'
      >
        <Icon name='check-circle-outline' color={COLOR_BLACK} size={20} set='MaterialCommunityIcons' />
        <ActionText>{i18n.t('contracts.offer.accept_offer')}</ActionText>
      </ActionButton>
      <ActionButton
        onPress={() => setShowRejectModal(true)}
        color={TRANSPARENT}
        textColor={COLOR_BLACK}
        borderColor={COLOR_BLACK}
        borderWidth={1}
        isDesktop={isDesktop}
        testID='OfferRejectModalOpenButton'
      >
        <Icon name='close-circle-outline' color={COLOR_BLACK} size={20} set='MaterialCommunityIcons' />
        <ActionText>{i18n.t('contracts.offer.reject_offer')}</ActionText>
      </ActionButton>
      {showRejectModal && (
        <RejectModal handleClose={() => setShowRejectModal(false)} id={id} token={token} testID='OfferRejectModal' />
      )}
      {showAcceptModal && (
        <AcceptModal
          handleClose={() => setShowAcceptModal(false)}
          testID='OfferAcceptModal'
          acceptOffer={acceptOffer}
        />
      )}
    </Container>
  )
}

Actions.propTypes = {
  id: PropTypes.string,
  token: PropTypes.string,
  acceptOffer: PropTypes.func
}

export default Actions
