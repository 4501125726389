/*
   When adding or modifying a reason here please add
   a corresponding translation key in phraseapp in the form -
   contact_form.reasons.${reason}
*/
export const REASONS = [
  'quality_feedback',
  'customer_area_feedback',
  'further_services',
  'contract',
  'invoices',
  'edit_appointment',
  'facility_changes',
  'general',
  'key_exchange',
  'accident'
]

export const EVENT_CONTACT_REASONS = ['quality_feedback', 'accident', 'edit_appointment']
