export const OTHER_REASONS = 'other_reasons'
export const CHANGE_REQUEST = 'change_request'
export const REJECTION_REASONS = [
  'employing_own_cleaner',
  'continues_existing_contract',
  'found_better_alternative',
  'contract_duration_too_long',
  'too_expensive',
  CHANGE_REQUEST,
  OTHER_REASONS
]
