import React from 'react'
import PropTypes from 'prop-types'
import { View, Platform, Image } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import Icon from 'lasagna/components/icon'
import { COLOR_BACKGROUND_DARK, COLOR_ACTION } from '../../constants/layout'
import { USER } from '../../constants/access'
import { useNavigation } from '../../helpers/navigation'
import { rootPath } from '../../helpers/urls'
import ContentContainer from '../layout/content_container'
import Button from '../button'

const Container = styled(View)`
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`

const OopsImage = styled(Image)`
  width: 170px;
  height: 40px;
`

const LostImage = styled(Image)`
  width: 414px;
  height: 328px;
  margin-top: 24px;
`

const ButtonText = styled(Text)`
  color: ${COLOR_BACKGROUND_DARK}
  font-size: 16px;
  font-weight: 500;
  margin-left: 8px;
`

const Description = styled(Text)`
  margin: 12px 0 24px;
  font-weight: 500;
`

const BackButton = styled(Button)`
  min-width: 200px;
`

const ContractNotFound = ({ access = USER }) => {
  const navigate = useNavigation()
  const goToRoot = () => navigate(rootPath())
  return (
    <ContentContainer testID='ContractNotFound' access={access}>
      <Container>
        <OopsImage
          source={
            Platform.OS === 'web' ? require('../../assets/images/oops.svg') : require('../../assets/images/oops.png')
          }
        />
        <Description>{i18n.t('contracts.not_found.title')}</Description>
        <BackButton color={COLOR_ACTION} onPress={goToRoot}>
          <Icon name='arrow-back' color={COLOR_BACKGROUND_DARK} size={20} />
          <ButtonText>{i18n.t('contracts.not_found.back')}</ButtonText>
        </BackButton>
        <LostImage
          source={
            Platform.OS === 'web' ? require('../../assets/images/lost.svg') : require('../../assets/images/lost.png')
          }
        />
      </Container>
    </ContentContainer>
  )
}

ContractNotFound.propTypes = {
  access: PropTypes.string
}

export default ContractNotFound
