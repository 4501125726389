import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import { useResponsive } from 'lasagna/components/responsive'
import styled from 'styled-components/native'
import PropTypes from 'prop-types'
import Logo from '../components/layout/logo'
import { COLOR_BLACK, COLOR_LABEL_LIGHT } from '../constants/layout'
import SignInForm from '../components/sign_in/form'
import SignInInfo from '../components/sign_in/info'
import LanguageSelector from '../components/layout/main_header/language_selector'
import { withStore } from '../helpers/store'
import { withNavigation } from '../helpers/navigation'
import { rootPath } from '../helpers/urls'
import VersionNumber from '../components/version_number'

const Container = styled(({ ...props }) => <View {...props} />)`
  background: ${COLOR_LABEL_LIGHT};
  min-height: 100%;
  justify-content: space-between;
  padding: ${({ isDesktop }) => (isDesktop ? '12px 50px;' : '20px')};
`

const MainContent = styled(({ ...props }) => <View {...props} />)`
  ${({ isDesktop }) =>
    isDesktop &&
    `
    flex-direction: row;
    padding: 40px 20px;
    justify-content: center;
  `}
`

const Footer = styled(Text)`
  color: ${COLOR_BLACK};
  text-align: center;

  ${({ isDesktop }) =>
    isDesktop
      ? `
      border-top-color: ${COLOR_BLACK};
      border-top-width: 1px;
      padding: 20px;
    `
      : `
      padding: 10px 0 20px;
      width: 80%;
      align-self: center;
    `}
`

const Header = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`

const SignIn = ({
  store: {
    session: { isSignedIn }
  },
  navigate
}) => {
  useEffect(() => {
    if (isSignedIn) {
      navigate(rootPath())
    }
  })

  if (isSignedIn) return null

  const { isDesktop } = useResponsive()

  return (
    <Container isDesktop={isDesktop}>
      <Header>
        <Logo background={COLOR_LABEL_LIGHT} />
        <LanguageSelector />
      </Header>
      <MainContent isDesktop={isDesktop}>
        <SignInInfo />
        <SignInForm />
      </MainContent>
      <Footer isDesktop={isDesktop}>{i18n.t('sign_in.footer')}</Footer>
      <VersionNumber />
    </Container>
  )
}

SignIn.propTypes = {
  store: PropTypes.shape({
    session: PropTypes.shape({
      isSignedIn: PropTypes.bool.isRequired
    })
  }),
  navigate: PropTypes.func.isRequired
}

export default withNavigation(withStore(observer(SignIn)))
