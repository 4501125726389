import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'styled-components/native'
import Card from 'lasagna/components/layout/card'
import ScheduleTabHeader from './schedule_tab_header'
import Row from '../table/row'
import Cell from '../table/cell'
import { COLOR_TABLE_BORDER, COLOR_WHITE, COLOR_TABLE_HEADER_BACKGROUND } from '../../constants/layout'
import { WEEKDAYS } from '../../constants/contracts'

const Table = styled(View)`
  margin: 20px 12px;
  border-top-color: ${COLOR_TABLE_BORDER};
  border-top-width: 1px;
  border-left-color: ${COLOR_TABLE_BORDER};
  border-left-width: 1px;
`

const ServiceOverview = ({ schedule: { weeks }, customerId }) => {
  return (
    <>
      <ScheduleTabHeader customerId={customerId} />
      <Card>
        <Table>
          <Row>
            <Cell large header>
              {i18n.t('contracts.details.recurrency.title')}
            </Cell>
            <Cell header background={COLOR_WHITE}>
              {i18n.t('contracts.details.schedule.monday')}
            </Cell>
            <Cell header background={COLOR_WHITE}>
              {i18n.t('contracts.details.schedule.tuesday')}
            </Cell>
            <Cell header background={COLOR_WHITE}>
              {i18n.t('contracts.details.schedule.wednesday')}
            </Cell>
            <Cell header background={COLOR_WHITE}>
              {i18n.t('contracts.details.schedule.thursday')}
            </Cell>
            <Cell header background={COLOR_WHITE}>
              {i18n.t('contracts.details.schedule.friday')}
            </Cell>
            <Cell header background={COLOR_WHITE}>
              {i18n.t('contracts.details.schedule.saturday')}
            </Cell>
            <Cell header background={COLOR_WHITE}>
              {i18n.t('contracts.details.schedule.sunday')}
            </Cell>
          </Row>
          {weeks.map(({ frequency, ...rest }) => (
            <Row key={frequency}>
              <Cell large>{frequency}</Cell>
              {WEEKDAYS.map(day => {
                return <Cell key={day} background={rest[day] ? COLOR_TABLE_HEADER_BACKGROUND : COLOR_WHITE} />
              })}
            </Row>
          ))}
        </Table>
      </Card>
    </>
  )
}

ServiceOverview.propTypes = {
  schedule: PropTypes.shape({
    weeks: PropTypes.arrayOf(
      PropTypes.shape({
        frequency: PropTypes.string,
        monday: PropTypes.bool,
        tuesday: PropTypes.bool,
        wednesday: PropTypes.bool,
        thursday: PropTypes.bool,
        friday: PropTypes.bool,
        saturday: PropTypes.bool,
        sunday: PropTypes.bool
      })
    )
  }),
  customerId: PropTypes.string
}

export default ServiceOverview
