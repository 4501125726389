import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import { COLOR_TEXT_PRIMARY } from 'lasagna/constants/colors'
import { useResponsive } from 'lasagna/components/responsive'
import Button from '../button'
import { COLOR_BACKGROUND_DARK } from '../../constants/layout'
import { useNavigation } from '../../helpers/navigation'
import { homePagePath } from '../../helpers/urls'

const Container = styled(Card)`
  padding: 50px 30px;
  justify-content: space-between;
  align-items: center;
  ${props =>
    props.isDesktop &&
    `
    flex-direction: row;
  `}
`

const Message = styled(Text)`
  color: ${COLOR_TEXT_PRIMARY};
`

const Title = styled(Message)`
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 12px;
`

const Content = styled(View)`
  flex-shrink: 1;
`

const BackButton = styled(Button)`
  padding: 0 50px;
  margin-top: 12px;
`

const ContactUsForm = () => {
  const navigate = useNavigation()
  const goToRoot = () => navigate(homePagePath())
  const { isDesktop } = useResponsive()

  return (
    <Container isDesktop={isDesktop} testID='ContactUsAcknowledgment'>
      <Content>
        <Title>{i18n.t('contact_form.thanks.title')}</Title>
        <Message>{i18n.t('contact_form.thanks.description')}</Message>
        <Message>{i18n.t('contact_form.thanks.more')}</Message>
      </Content>
      <BackButton
        textColor={COLOR_BACKGROUND_DARK}
        borderColor={COLOR_BACKGROUND_DARK}
        borderWidth={1}
        onPress={goToRoot}
      >
        {i18n.t('contact_form.thanks.back')}
      </BackButton>
    </Container>
  )
}

export default ContactUsForm
