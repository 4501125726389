import React from 'react'
import PropTypes from 'lasagna/prop_types'
import { View, Text, TouchableOpacity } from 'react-native'
import Card from 'lasagna/components/layout/card'
import Icon from 'lasagna/components/icon'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import { COLOR_BLACK, COLOR_BACKGROUND_DARK } from '../../constants/layout'
import openExternal from '../../helpers/navigation/open_external'
import { pdfPath } from '../../helpers/urls'
import Status from './status'

const InvoiceCard = styled(Card)`
  margin-bottom: 8px;
  padding: 18px 20px;
`

const ViewInvoice = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: bold;
  font-size: 14px;
`

const Amount = styled(Text)`
  color: ${COLOR_BLACK};
  font-size: 14px;
  margin-left: 4px;
`

const Pdf = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`

const InvoiceDate = styled(View)`
  margin-right: 30px;
`

const GrossAmount = styled(View)`
  flex-direction: row;
  margin: 6px 0 12px;
`

const Row = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  widht: 100%;
`

const Address = styled(Text)`
  margin: 0 0 4px 4px;
`

const Collective = styled(Text)`
  color: ${COLOR_BACKGROUND_DARK};
  font-weight: bold;
`

const Download = styled(Text)`
  font-weight: bold;
  text-decoration: underline;
`

const InvoiceList = ({ invoices }) => {
  const viewPdf = path => openExternal(pdfPath(path), true)

  return invoices.map(
    ({
      id,
      invoiceDate,
      downloadEventsXlsxPath,
      downloadPdfPath,
      grossAmount,
      balance,
      cancelled,
      cancellation,
      facilityAddress,
      legacy,
      isCollective,
      isIndividual,
      isMaterial
    }) => (
      <View key={id}>
        <InvoiceCard elevation={1}>
          <Row>
            {isCollective && <Collective>{i18n.t('invoices.collective')}</Collective>}
            {isIndividual && <Collective>{i18n.t('invoices.individual')}</Collective>}
            {isMaterial && <Collective>{i18n.t('invoices.material')}</Collective>}
          </Row>
          <Row>
            {!isCollective && facilityAddress && (
              <>
                <Icon name='domain' color={COLOR_BLACK} size={16} />
                <Address>{facilityAddress}</Address>
              </>
            )}
          </Row>
          <Row>
            <InvoiceDate>
              <Text>{invoiceDate}</Text>
            </InvoiceDate>
          </Row>
          <GrossAmount>
            <ViewInvoice>{i18n.t('invoices.total')}</ViewInvoice>
            <Amount>{i18n.t('invoices.amount', { netAmount: grossAmount })}</Amount>
          </GrossAmount>
          <Pdf onPress={() => viewPdf(downloadPdfPath)}>
            <Icon name='file-pdf-box' set='MaterialCommunityIcons' color={COLOR_BLACK} size={16} />
            <Download>{i18n.t('invoices.view')}</Download>
          </Pdf>
          {isCollective && downloadEventsXlsxPath && (
            <Pdf onPress={() => viewPdf(downloadEventsXlsxPath)}>
              <Icon name='file-table-outline' set='MaterialCommunityIcons' color={COLOR_BLACK} size={16} />
              <Download>{i18n.t('invoices.detailed_view')}</Download>
            </Pdf>
          )}
          {!legacy && <Status balance={balance} cancelled={cancelled} cancellation={cancellation} />}
        </InvoiceCard>
      </View>
    )
  )
}

InvoiceList.propTypes = {
  serviceType: PropTypes.string.isRequired,
  startTimeObj: PropTypes.luxon
}

export default observer(InvoiceList)
