import React, { useState } from 'react'
import Modal from 'react-native-modal'
import { TextInput, TouchableOpacity, View, Picker, ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'
import PropTypes from 'lasagna/prop_types'
import Icon from 'lasagna/components/icon'
import Card from 'lasagna/components/layout/card'
import Text from 'lasagna/components/text'
import { useResponsive } from 'lasagna/components/responsive'
import AppointmentCard from './card'
import Button from '../button'
import { COLOR_LIGHT_ICON, COLOR_HIGHLIGHT_RED, TRANSPARENT, COLOR_WHITE, COLOR_BLACK } from '../../constants/layout'
import AppointmentModel from '../../models/appointment'
import { useStore } from '../../helpers/store'
import { COLOR_INPUT_LIGHT_BORDER } from 'lasagna/constants'
import { CANCEL_EVENT_CUSTOMER_REASONS } from '../../constants/cancel_event'
import { openConfirmDialog } from '../../helpers/dialog.web'
import { CANCEL_EVENT_SUCCESS } from '../../constants/request_banner'

const Content = styled(Card)`
  padding: 32px;
  ${({ isDesktop }) =>
    isDesktop
      ? 'width: 60%;'
      : `
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    align-items: center;
  `};
`

const Container = styled(Modal)`
  ${({ isDesktop }) =>
    isDesktop ? 'justify-content: center; align-items: center;' : 'justify-content: flex-end; margin: 0;'}
`

const Close = styled(TouchableOpacity)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const Title = styled(Text)`
  font-size: 20px;
  font-weight: bold;
`

const ActionButton = styled(Button)`
  width: 240px;
  margin-top: 24px;
`

const ActionText = styled(Text)`
  color: ${props => (props.disabled ? COLOR_BLACK : COLOR_HIGHLIGHT_RED)};
  font-weight: 600;
  margin-left: 4px;
  font-size: 16px;
`

const Label = styled(Text)`
  margin: 32px 0 8px;
`

const PickerWrapper = styled(View)`
  max-width: 400px;
`

const StyledPicker = styled(Picker)`
  border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
  background: ${COLOR_WHITE};
  padding: 10px 16px;
  border-radius: 5px;
  color: ${COLOR_BLACK};
`

const Message = styled(TextInput)`
  border: 1px solid ${COLOR_LIGHT_ICON};
  border-radius: 5px;
  padding: 8px 16px;
  ${({ isDesktop }) => !isDesktop && 'min-height: 50px;'}
`

const CancellationModal = ({ appointment, handleClose }) => {
  const { isDesktop } = useResponsive()
  const {
    appointments: { cancel, setShowRequestBanner }
  } = useStore()

  const [reason, setReason] = useState()
  const [notes, setNotes] = useState('')
  const [requesting, setRequesting] = useState(false)

  const reasonOptions = CANCEL_EVENT_CUSTOMER_REASONS.map(key => ({
    key,
    label: i18n.t(`schedule.actions.reschedule.reasons.${key}`)
  }))

  const cancelActionDisabled = requesting || !reason

  const cancelAction = async () => {
    const canCancel = openConfirmDialog(i18n.t('schedule.actions.cancellation.confirm_dialog_title'))
    if (!canCancel) {
      return
    }
    setRequesting(true)
    const success = await cancel(appointment.id, reason, notes)
    if (success) {
      handleClose()
      setShowRequestBanner(CANCEL_EVENT_SUCCESS)
    }
    setRequesting(false)
  }

  return (
    <Container isVisible isDesktop={isDesktop}>
      <Content isDesktop={isDesktop}>
        <Close onPress={handleClose}>
          <Icon name='close' color={COLOR_LIGHT_ICON} />
        </Close>
        <Title>{i18n.t('schedule.actions.cancellation.title')}</Title>
        <AppointmentCard appointment={appointment} />
        <Label>{i18n.t('schedule.actions.cancellation.select_reason')}</Label>
        <PickerWrapper>
          <StyledPicker value={reason} onChange={e => setReason(e.target.value)}>
            <Picker.Item value={null} />
            {reasonOptions.map(({ key, label }) => (
              <Picker.Item key={key} label={label} value={key} />
            ))}
          </StyledPicker>
        </PickerWrapper>
        <Label>{i18n.t('schedule.actions.cancellation.add_message')}</Label>
        <Message numberOfLines={5} multiline onChangeText={setNotes} />
        <ActionButton
          onPress={cancelAction}
          color={TRANSPARENT}
          textColor={COLOR_HIGHLIGHT_RED}
          borderColor={COLOR_HIGHLIGHT_RED}
          borderWidth={1}
          disabled={cancelActionDisabled}
        >
          <ActionText disabled={cancelActionDisabled}>{i18n.t('schedule.actions.cancellation.confirm')}</ActionText>
          {requesting && <ActivityIndicator size={18} color={COLOR_BLACK} style={{ marginLeft: 5 }} />}
        </ActionButton>
      </Content>
    </Container>
  )
}

CancellationModal.propTypes = {
  appointment: PropTypes.modelOf(AppointmentModel),
  handleClose: PropTypes.func
}

export default CancellationModal
