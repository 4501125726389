import React from 'react'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useResponsive } from 'lasagna/components/responsive'
import { COLOR_TEXT_DARK, COLOR_ACTION, COLOR_BLACK, COLOR_HIGHLIGHT_RED } from '../../constants/layout'
import { SERVICE_TYPES } from '../../constants/service_types'
import Overview from './overview'
import GenericServiceDetails from './generic_service_details'

const Title = styled(Text)`
  font-size: 20px;
  color: ${COLOR_TEXT_DARK};
`

const Header = styled(View)`
  ${props => props.isDesktop && 'flex-direction: row;'}
  margin: 30px 20px 12px 10px;
  align-items: center;
  justify-content: space-between;
`

const ServiceTypeContainer = styled(View)`
  margin-left: 6px;
`

const ServiceType = styled(Text)`
  background-color: ${COLOR_ACTION};
  color: ${COLOR_BLACK};
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
`

const Row = styled(View)`
  flex-direction: row;
`

const Price = styled(Text)`
  color: ${COLOR_HIGHLIGHT_RED};
  margin-left: 4px;
  font-weight: 600;
`

const ContractDetails = ({
  contract: {
    customer,
    serviceType,
    firstServiceAt,
    address,
    serviceDetail: { serviceDescription },
    price,
    additionalAgreements
  },
  title
}) => {
  const { isDesktop } = useResponsive()
  return (
    <>
      <Header isDesktop={isDesktop}>
        <Row>
          <Title>{title}</Title>
          {SERVICE_TYPES[serviceType] && (
            <ServiceTypeContainer>
              <ServiceType>{i18n.t(`service_types.${serviceType}.title`)}</ServiceType>
            </ServiceTypeContainer>
          )}
        </Row>
        <Row>
          <Text>{i18n.t('contracts.details.price')}</Text>
          <Price>{i18n.t('contracts.details.formatted_price', { price })}</Price>
        </Row>
      </Header>
      <Overview firstServiceAt={firstServiceAt} address={address} customer={customer} />
      <GenericServiceDetails description={serviceDescription} agreements={additionalAgreements} />
    </>
  )
}

ContractDetails.propTypes = {
  contract: PropTypes.shape({
    customer: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    }),
    serviceType: PropTypes.string,
    firstServiceAt: PropTypes.string,
    address: PropTypes.string,
    serviceDetail: PropTypes.shape({
      serviceDescription: PropTypes.string
    }),
    price: PropTypes.number,
    additionalAgreements: PropTypes.string
  }).isRequired,
  title: PropTypes.string
}

export default observer(ContractDetails)
