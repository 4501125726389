import React from 'react'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Card from 'lasagna/components/layout/card'
import Icon from 'lasagna/components/icon'
import { SERVICE_TYPES, MAINTENANCE_CLEANING, TEMPORARY_CLEANING } from '../../constants/service_types'
import { RECURRENCY } from '../../constants/contracts'
import AcceptButton from './accept_button'
import DownloadButton from './download_button'
import DesktopMaintenanceCleaningTabs from './desktop_maintenance_cleaning_tabs'
import AdditionalAgreements from './additional_agreements'
import { durationInHours } from '../../helpers/duration'

const ButtonsContainer = styled(View)`
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 18px;
`

const Row = styled(View)`
  flex-direction: row;
`

const SummaryTitle = styled(Text)`
  width: 250px;
`

const AdditionalDetailLabel = styled(View)`
  flex-direction: row;
  align-items: center;
`

const LabelIcon = styled(Icon)`
  margin-right: 4px;
`

const FacilityGeneralDetailsCard = styled(Card)`
  flex: 2;
  margin-right: 8px;
`

const FacilityAdditionalDetailsCard = styled(Card)`
  flex: 1;
`

const AdditionalDetail = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  ${({ spaced }) => spaced && 'margin-bottom: 12px;'}
`

const PetInfo = styled(Text)`
  margin-left: 24px;
`

const MaintenanceCleaningServiceDetails = ({
  contract: {
    serviceDetail: {
      size,
      recurrency,
      areas,
      serviceDescription,
      vacuumCleanerIncluded,
      facilityHasPets,
      facilityHasPetsNotes
    },
    schedule,
    accepted,
    rejected,
    downloadPdfPath,
    additionalAgreements,
    customer: { id: customerId },
    serviceType,
    totalEvents,
    totalEventsByDuration,
    isTemporaryCleaningContract
  },
  acceptOffer,
  isOffer
}) => {
  const { showRecurrence, hasAreaBasedServiceDetails } = SERVICE_TYPES[serviceType]
  return (
    <>
      <Row>
        <FacilityGeneralDetailsCard>
          {hasAreaBasedServiceDetails && (
            <Row>
              <SummaryTitle>{i18n.t('contracts.details.size')}</SummaryTitle>
              <Text>{i18n.t('contracts.details.area', { size })}</Text>
            </Row>
          )}
          {showRecurrence && (
            <Row>
              <SummaryTitle>{i18n.t('contracts.details.recurrency.title')}</SummaryTitle>
              <Text>
                {recurrency >= RECURRENCY.weekly && i18n.t('contracts.details.recurrency.week', { recurrency })}
                {recurrency === RECURRENCY.monthly && i18n.t('contracts.details.recurrency.month')}
                {recurrency === RECURRENCY.fortnightly && i18n.t('contracts.details.recurrency.fortnight')}
              </Text>
            </Row>
          )}
          {!!totalEventsByDuration?.length && serviceType === TEMPORARY_CLEANING && (
            <>
              <Row>
                <SummaryTitle>{i18n.t('contracts.details.total_events.count')}</SummaryTitle>
                <Text>{totalEvents}</Text>
              </Row>
              <Row>
                <SummaryTitle>{i18n.t('contracts.details.total_events.overview')}</SummaryTitle>
                <View>
                  {totalEventsByDuration.map(({ count, duration }) => (
                    <Text key={duration}>
                      {count} x {durationInHours(duration)}
                    </Text>
                  ))}
                </View>
              </Row>
            </>
          )}
        </FacilityGeneralDetailsCard>
        {serviceType === MAINTENANCE_CLEANING && (
          <FacilityAdditionalDetailsCard>
            <AdditionalDetail spaced>
              <AdditionalDetailLabel>
                <LabelIcon name='robot-vacuum-variant' set='MaterialCommunityIcons' size={18} />
                <Text>{i18n.t('contracts.details.facility_details.vacuum_included')}</Text>
              </AdditionalDetailLabel>
              <Text>
                {vacuumCleanerIncluded
                  ? i18n.t('contracts.details.facility_details.yes')
                  : i18n.t('contracts.details.facility_details.no')}
              </Text>
            </AdditionalDetail>
            <AdditionalDetail>
              <AdditionalDetailLabel>
                <LabelIcon name='paw' set='MaterialCommunityIcons' size={18} />
                <Text>{i18n.t('contracts.details.facility_details.pets_present')}</Text>
              </AdditionalDetailLabel>
              <Text>
                {facilityHasPets
                  ? i18n.t('contracts.details.facility_details.yes')
                  : i18n.t('contracts.details.facility_details.no')}
              </Text>
            </AdditionalDetail>
            <PetInfo>{facilityHasPetsNotes}</PetInfo>
          </FacilityAdditionalDetailsCard>
        )}
      </Row>

      {additionalAgreements && <AdditionalAgreements agreements={additionalAgreements} />}

      {isOffer && (
        <ButtonsContainer>
          <DownloadButton path={downloadPdfPath} />
          <AcceptButton accepted={accepted} rejected={rejected} acceptOffer={acceptOffer} />
        </ButtonsContainer>
      )}

      <DesktopMaintenanceCleaningTabs
        areas={areas}
        schedule={schedule}
        customerId={customerId}
        description={serviceDescription}
        showRecurrence={SERVICE_TYPES[serviceType]?.showRecurrence}
        isTemporaryCleaningContract={isTemporaryCleaningContract}
      />
    </>
  )
}

MaintenanceCleaningServiceDetails.propTypes = {
  contract: PropTypes.shape({
    serviceDetail: PropTypes.shape({
      size: PropTypes.number,
      recurrency: PropTypes.number,
      serviceDescription: PropTypes.string,
      vacuumCleanerIncluded: PropTypes.bool,
      facilityHasPets: PropTypes.bool,
      facilityHasPetsNotes: PropTypes.string,
      areas: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          size: PropTypes.number,
          roomCount: PropTypes.number,
          name: PropTypes.string,
          floorType: PropTypes.string,
          recurrency: PropTypes.number
        })
      )
    }),
    schedule: PropTypes.object,
    accepted: PropTypes.bool,
    rejected: PropTypes.bool,
    downloadPdfPath: PropTypes.string,
    additionalAgreements: PropTypes.string,
    customer: PropTypes.shape({ id: PropTypes.string }),
    serviceType: PropTypes.string,
    totalEvents: PropTypes.number,
    totalEventsByDuration: PropTypes.arrayOf(
      PropTypes.shape({
        count: PropTypes.number,
        duration: PropTypes.number
      })
    ),
    isTemporaryCleaningContract: PropTypes.bool
  }).isRequired,
  acceptOffer: PropTypes.func,
  isOffer: PropTypes.bool
}

export default observer(MaintenanceCleaningServiceDetails)
