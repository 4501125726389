import React, { useState } from 'react'
import { View, TextInput } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import { useResponsive } from 'lasagna/components/responsive'
import { useStore } from '../../helpers/store'
import { useNavigation } from '../../helpers/navigation'
import { COLOR_TEXT_DARK, COLOR_ACTION, COLOR_HIGHLIGHT_RED } from '../../constants/layout'
import Button from '../button'
import Card from 'lasagna/components/layout/card'
import { COLOR_INPUT_LIGHT_BORDER, COLOR_TEXT_PRIMARY } from 'lasagna/constants/colors'
import ServiceCard from './card'
import FacilityPicker from '../contact_us/facility_picker'
import { newBookingSuccessPath } from '../../helpers/urls'
import useQueryParams from '../../helpers/hooks/use_query_params'
import { GENERIC_SERVICE } from '../../constants/service_types'

const FormCard = styled(Card)`
  padding: 28px 30px 80px;
`

const FormItem = styled(View)`
  ${props =>
    props.isDesktop &&
    `
    flex-direction: row;
  `}
  ${props => props.picker && 'z-index: 1;'}
  margin: 12px 0;
  align-items: baseline;
`

const Label = styled(View)`
  width: 150px;
  color: ${COLOR_TEXT_PRIMARY};
`

const Message = styled(TextInput)`
  padding: 10px 16px;
  border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
  width: 100%;
  border-radius: 5px;
  margin-top: 12px;
  &:focus {
    box-shadow: none;
  }
`

const SendButton = styled(Button)`
  width: 200px;
`

const ErrorContainer = styled(View)`
  margin: 15px 0;
  height: 40px;
  justify-content: center;
`

const ErrorText = styled(Text)`
  color: ${COLOR_HIGHLIGHT_RED};
  align-items: center;
`

const NewBookingForm = ({ service }) => {
  const [showError, setShowError] = useState(false)
  const [notes, setNotes] = useState('')
  const [facility, setFacility] = useState()
  const [customService, setCustomService] = useState('')

  const {
    session: { currentUser },
    verticals: { requestNewService }
  } = useStore()

  const { isDesktop } = useResponsive()

  const navigate = useNavigation()

  const query = useQueryParams()
  const customerContractId = query.get('contractId')
  const token = query.get('token')

  const isCustomService = service === GENERIC_SERVICE

  const submitForm = async () => {
    const serviceRequest = isCustomService ? customService : service
    const success = await requestNewService({
      service: serviceRequest,
      customerContractId,
      notes,
      token,
      facilityId: facility?.id
    })
    if (success) {
      navigate(newBookingSuccessPath())
    }
    setShowError(!success)
  }

  const disableSendAction = isCustomService
    ? (!!currentUser && !facility) || !customService
    : !!currentUser && !facility

  return (
    <View>
      <ServiceCard service={service} />
      <FormCard>
        {currentUser && (
          <FormItem isDesktop={isDesktop} picker>
            <Label />
            <FacilityPicker customer={facility} onSelect={setFacility} />
          </FormItem>
        )}
        {isCustomService && (
          <FormItem isDesktop={isDesktop}>
            <Label>
              <Text>{i18n.t('new_booking.form.custom_service_name')}</Text>
            </Label>
            <Message value={customService} onChangeText={setCustomService} />
          </FormItem>
        )}
        <FormItem isDesktop={isDesktop}>
          <Label>
            <Text>{i18n.t('new_booking.form.additional_details')}</Text>
          </Label>
          <Message value={notes} onChangeText={setNotes} multiline numberOfLines={8} />
        </FormItem>
      </FormCard>
      <ErrorContainer>{showError && <ErrorText>{i18n.t('contact_form.error')}</ErrorText>}</ErrorContainer>
      <SendButton textColor={COLOR_TEXT_DARK} color={COLOR_ACTION} onPress={submitForm} disabled={disableSendAction}>
        {i18n.t('new_booking.form.send')}
      </SendButton>
    </View>
  )
}

NewBookingForm.propTypes = {
  service: PropTypes.string.isRequired
}

export default NewBookingForm
