import React, { useState } from 'react'
import PropTypes from 'lasagna/prop_types'
import { View, TouchableOpacity, TextInput } from 'react-native'
import styled from 'styled-components/native'
import { useDropdownMenu, useDropdownToggle } from 'react-overlays'
import Text from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import { useResponsive } from 'lasagna/components/responsive'
import { COLOR_INPUT_LIGHT_BORDER, COLOR_TEXT_SECONDARY } from 'lasagna/constants/colors'
import { COLOR_WHITE } from '../../constants/layout'
import { useStore } from '../../helpers/store'
import CustomerModel from '../../models/customer'

const MenuContainer = styled(View)`
  display: ${props => (props.visible ? 'flex' : 'none')};
  background: ${COLOR_WHITE};
  box-sizing: border-box;
  border-radius: 5px;
`

const PickerItemContainer = styled(View)`
  border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
  border-radius: 5px;
  padding: 0 16px 16px;
  background: ${COLOR_WHITE};
  max-height: 300px;
  overflow-y: scroll;
  min-width: ${({ isDesktop }) => (isDesktop ? '400px;' : '284px')};
`

const OptionWrapper = styled(TouchableOpacity)`
  margin-top: 16px;
  flex-direction: row;
  align-items: center;
`

const SearchWrapper = styled(View)`
  margin-top: 16px;
  border-color: ${COLOR_INPUT_LIGHT_BORDER};
  border-bottom-width: 1px;
  flex-direction: row;
  align-items: center;
`

const Search = styled(TextInput)`
  padding: 8px 12px;
  border-radius: 5px;
  outline-width: 0;
`

const Option = styled(Text)`
  ${({ selected }) => selected && 'font-weight: bold;'}
  margin-right: 8px;
`

const FacilityPickerMenu = ({ onSelect, selectedCustomer }) => {
  const [customerFilter, setCustomerFilter] = useState('')

  const { show, props } = useDropdownMenu({})
  // eslint-disable-next-line no-unused-vars
  const [_props, { toggle }] = useDropdownToggle()

  const onOptionSelected = value => {
    onSelect(value)
    toggle()
  }

  const {
    customers: { customers }
  } = useStore()

  const filteredCustomers = customers.filter(
    ({ address }) => address.toLowerCase().indexOf(customerFilter.toLowerCase()) > -1
  )

  const { isDesktop } = useResponsive()

  return (
    <MenuContainer {...props} visible={show}>
      <PickerItemContainer isDesktop={isDesktop}>
        <SearchWrapper>
          <Icon name='search' size={20} color={COLOR_TEXT_SECONDARY} />
          <Search onChangeText={setCustomerFilter} placeholder={i18n.t('customers.search')} />
        </SearchWrapper>
        {filteredCustomers.map(customer => (
          <OptionWrapper key={customer.id} onPress={() => onOptionSelected(customer)} testID='Facility'>
            <Option selected={customer.id === selectedCustomer?.id}>{customer.address}</Option>
            {customer.id === selectedCustomer?.id && <Icon name='check' size={20} color={COLOR_TEXT_SECONDARY} />}
          </OptionWrapper>
        ))}
      </PickerItemContainer>
    </MenuContainer>
  )
}

FacilityPickerMenu.propTypes = {
  onSelect: PropTypes.func,
  selectedCustomer: PropTypes.modelOf(CustomerModel)
}

export default FacilityPickerMenu
