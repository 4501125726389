import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { View, TextInput } from 'react-native'
import PropTypes from 'prop-types'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import styled from 'styled-components/native'
import Icon from 'lasagna/components/icon'
import HeaderCard from './header_card'
import Sidebar from './sidebar'
import Package from './package'
import FacilityPicker from '../../contact_us/facility_picker'
import Picker from '../../picker'
import { FRUIT_BASKET_DELIVERY, SERVICE_TYPES } from '../../../constants/service_types'
import { COLOR_INPUT_LIGHT_BORDER } from 'lasagna/constants/colors'
import { COLOR_ALERT_BACKGROUND, COLOR_HIGHLIGHT_RED } from '../../../constants/layout'
import { useNavigation } from '../../../helpers/navigation'
import useQueryParams from '../../../helpers/hooks/use_query_params'
import { useStore } from '../../../helpers/store'
import { newBookingSuccessPath } from '../../../helpers/urls'

const Container = styled(View)`
  flex-direction: row;
`

const Main = styled(View)`
  width: 70%;
  margin-right: 24px;
`

const SectionTitle = styled(Text)`
  font-weight: bold;
  font-size: 16px;
  margin: 36px 0 12px;
`

const Section = styled(Card)`
  align-items: flex-start;
  ${({ zIndex }) => zIndex && `z-index: ${zIndex}`}
`

const Packages = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

const MessageInput = styled(TextInput)`
  border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
  border-radius: 5px;
  flex-grow: 1;
  padding: 8px 12px;
  width: 80%;
  margin: 12px 0;
`

const ErrorText = styled(Text)`
  color: ${COLOR_HIGHLIGHT_RED};
  align-items: center;
  margin-top: 12px;
`

const Alert = styled(View)`
  margin-top: 20px;
  background-color: ${COLOR_ALERT_BACKGROUND};
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AlertText = styled(Text)`
  font-weight: bold;
  flex: 1;
  margin-left: 15px;
`

const Funnel = ({ service }) => {
  const query = useQueryParams()
  const customerContractId = query.get('contractId')
  const token = query.get('token')

  const navigate = useNavigation()

  const config = SERVICE_TYPES[service]?.funnelConfig

  const frequencyOptions = config.recurrencePerWeek.map(count => ({
    key: count,
    label: i18n.t('new_booking.funnel.frequency.per_week', { count })
  }))

  const [frequency, setFrequency] = useState(config.recurrencePerWeek[0])
  const [facility, setFacility] = useState()
  const [selectedPackage, setSelectedPackage] = useState()
  const [message, setMessage] = useState('')
  const [showError, setShowError] = useState()

  const {
    session: { currentUser },
    verticals: { requestNewService }
  } = useStore()

  const submitForm = async () => {
    const notes = `Frequency: ${frequency}, Type: ${selectedPackage?.type}, ${message}`
    const success = await requestNewService({ service, customerContractId, notes, token, facilityId: facility?.id })
    if (success) {
      navigate(newBookingSuccessPath())
    }
    setShowError(!success)
  }

  return (
    <Container>
      <Main>
        <HeaderCard service={service} />
        <Text>{i18n.t(`service_types.${service}.description`)}</Text>
        <SectionTitle>{i18n.t('new_booking.funnel.select_frequency')}</SectionTitle>
        <Section zIndex={2}>
          <Picker
            selected={frequency && i18n.t('new_booking.funnel.frequency.per_week', { count: frequency })}
            onSelect={setFrequency}
            options={frequencyOptions}
          />
        </Section>
        {!!currentUser && (
          <>
            <SectionTitle>{i18n.t('new_booking.funnel.select_facility')}</SectionTitle>
            <Section zIndex={1}>
              <FacilityPicker customer={facility} onSelect={setFacility} />
            </Section>
          </>
        )}
        {service === FRUIT_BASKET_DELIVERY && (
          <Alert>
            <Icon name='alert-circle-outline' set='MaterialCommunityIcons' size={30} />
            <AlertText>{i18n.t('new_booking.funnel.alerts.fruit_basket_price_for_berlin_only')}</AlertText>
          </Alert>
        )}
        {config.packageOptions && (
          <>
            <SectionTitle>{i18n.t('new_booking.funnel.select_package')}</SectionTitle>
            <Packages>
              {config.packageOptions.map(servicePackage => (
                <Package
                  servicePackage={servicePackage}
                  selected={selectedPackage?.type === servicePackage.type}
                  key={servicePackage.type}
                  selectPackage={() => setSelectedPackage(servicePackage)}
                  serviceType={service}
                />
              ))}
            </Packages>
          </>
        )}
        <SectionTitle>{i18n.t('new_booking.funnel.additional_comments')}</SectionTitle>
        <Section>
          <MessageInput numberOfLines={5} multiline onChange={setMessage} />
        </Section>
        {showError && <ErrorText>{i18n.t('contact_form.error')}</ErrorText>}
      </Main>
      <Sidebar
        facility={facility}
        frequency={frequency}
        selectedPackage={selectedPackage}
        handleSubmit={submitForm}
        serviceType={service}
      />
    </Container>
  )
}

Funnel.propTypes = {
  service: PropTypes.string
}

export default observer(Funnel)
