import { types } from 'mobx-state-tree'

const AccountManager = types.model('AccountManager', {
  name: types.string,
  phone: types.string
})

const Account = types.model('Account', {
  id: types.identifier,
  name: types.string,
  address: types.maybeNull(types.string),
  managingDirector: types.maybeNull(types.string),
  email: types.string,
  phone: types.string,
  accountManager: types.maybeNull(AccountManager)
})

export default Account
