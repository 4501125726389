import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import { observer } from 'mobx-react'
import { useResponsive } from 'lasagna/components/responsive'
import ContentContainer from '../components/layout/content_container'
import Tabs from '../components/schedule/tabs'
import Calendar from '../components/schedule/calendar'
import { useStore } from '../helpers/store'
import { COLOR_BLACK } from '../constants/layout'
import styled from 'styled-components/native'
import RequestSuccessBanner from '../components/schedule/request_success_banner'

const Title = styled(Text)`
  color: ${COLOR_BLACK};
  font-size: 24px;
  font-weight: 600;
  margin-right: 6px;
`

const Header = styled(View)`
  padding: 15px 0;
  ${props =>
    props.isDesktop &&
    `
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 30px;
  `}
  justify-content: space-between;
`

const Heading = styled(View)`
  ${props =>
    !props.isDesktop &&
    `
    margin-bottom: 8px;
  `}
  align-items: baseline;
`

const AccountName = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  color: ${COLOR_BLACK};
  margin-top: 8px;
`

const Schedule = ({
  match: {
    params: { customerId }
  }
}) => {
  const {
    account: { account },
    appointments: { setSelectedCustomer, setShowRequestBanner, showRequestBannerType }
  } = useStore()

  setSelectedCustomer(customerId)
  const { isDesktop } = useResponsive()

  useEffect(() => {
    return () => {
      setShowRequestBanner(null)
    }
  }, [])

  return (
    <ContentContainer banner={<RequestSuccessBanner type={showRequestBannerType} />}>
      <Header isDesktop={isDesktop}>
        <Heading isDesktop={isDesktop}>
          <Title>{i18n.t('navigation.schedule')}</Title>
          <AccountName>{account?.name}</AccountName>
        </Heading>
      </Header>
      {!isDesktop && <Tabs />}
      {isDesktop && <Calendar />}
    </ContentContainer>
  )
}

Schedule.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      customerId: PropTypes.string
    })
  })
}

export default observer(Schedule)
