import React, { useState } from 'react'
import PropTypes from 'lasagna/prop_types'
import { View, Text, Platform, Image, TouchableOpacity } from 'react-native'
import Card from 'lasagna/components/layout/card'
import Icon from 'lasagna/components/icon'
import { COLOR_WHITE, COLOR_BLACK } from 'lasagna/constants'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import { DateTime } from 'luxon'
import CancellationModal from './cancellation_modal'
import RescheduleModal from './reschedule_modal'
import { COLOR_TEXT_DARK, COLOR_HIGHLIGHT_BLUE, COLOR_ACTION, COLOR_BACKGROUND_DARK } from '../../constants/layout'
import { SERVICE_TYPES } from '../../constants/service_types'
import AppointmentModel from '../../models/appointment'

const DetailsCard = styled(Card)`
  padding: 20px;
  margin-top: 12px;
`

const Detail = styled(View)`
  align-items: flex-start;
  flex-direction: row;
`

const ContractText = styled(Text)`
  color: ${COLOR_BLACK};
  font-size: 14px;
  margin-bottom: 8px;
  margin-left: 8px;
`

const ServiceTypeIcon = styled(Image)`
  height: 13px;
  width: 11px;
  margin-right: 4px;
`

const StatusLabel = styled(Card)`
  background-color: ${COLOR_ACTION};
  margin-left: 6px;
  padding: 4px 10px;
`

const Status = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: 600;
  font-size: 12px;
`

const Label = styled(View)`
  border-radius: 5px;
  background: ${COLOR_BACKGROUND_DARK};
  margin-left: 6px;
  padding: 4px 10px;
`

const LabelText = styled(Text)`
  color: ${COLOR_WHITE};
`

const ExtraLabel = styled(StatusLabel)`
  background: ${COLOR_HIGHLIGHT_BLUE};
`

const ProviderNames = styled(ContractText)`
  color: ${COLOR_BLACK};
`

const ProviderDetail = styled(Detail)`
  align-items: flex-start;
`

const Labels = styled(View)`
  justify-content: flex-end;
  flex-direction: row;
`

const ActionsToggle = styled(TouchableOpacity)`
  align-items: flex-end;
`

const ActionsDropdown = styled(Card)`
  position: absolute;
  top: 16px;
  right: 16px;
  align-items: flex-end;
  padding: 24px 24px 12px 12px;
  z-index: 2;
`

const ActionName = styled(Text)`
  text-decoration: underline;
`

const Close = styled(TouchableOpacity)`
  position: absolute;
  top: 4px;
  right: 4px;
`

const Action = styled(TouchableOpacity)`
  margin: 8px 0;
`

const AppointmentCard = ({ appointment, withActions }) => {
  const { serviceType, startTimeObj, cancelled, provider, extra, serviceAddress, confirmed } = appointment

  const [showRescheduleModal, setShowRescheduleModal] = useState()
  const [showCancellationModal, setShowCancellationModal] = useState()
  const [showActionDropdown, setShowActionDropdown] = useState()

  return (
    <DetailsCard elevation={1} testID='EventDetailsCard'>
      {showRescheduleModal && (
        <RescheduleModal
          appointment={appointment}
          handleClose={() => {
            setShowRescheduleModal(false)
            setShowActionDropdown(false)
          }}
        />
      )}
      {showCancellationModal && (
        <CancellationModal
          appointment={appointment}
          handleClose={() => {
            setShowCancellationModal(false)
            setShowActionDropdown(false)
          }}
        />
      )}
      {withActions && (
        <ActionsToggle onPress={() => setShowActionDropdown(true)}>
          <Icon name='dots-horizontal' set='MaterialCommunityIcons' color={COLOR_BLACK} size={20} />
        </ActionsToggle>
      )}
      {showActionDropdown && (
        <ActionsDropdown elevation={1}>
          <Close onPress={() => setShowActionDropdown(false)}>
            <Icon name='dots-horizontal' set='MaterialCommunityIcons' color={COLOR_BLACK} size={20} />
          </Close>
          <View>
            <Action onPress={() => setShowRescheduleModal(true)}>
              <ActionName>{i18n.t('schedule.actions.reschedule.cta')}</ActionName>
            </Action>
            <Action onPress={() => setShowCancellationModal(true)}>
              <ActionName>{i18n.t('schedule.actions.cancel')}</ActionName>
            </Action>
          </View>
        </ActionsDropdown>
      )}
      <Labels>
        {extra && (
          <ExtraLabel elevation={0}>
            <LabelText>++ {i18n.t('schedule.extra')}</LabelText>
          </ExtraLabel>
        )}
        {cancelled && (
          <StatusLabel elevation={0}>
            <Status>{i18n.t('schedule.cancelled')}</Status>
          </StatusLabel>
        )}
        {confirmed && (
          <Label>
            <LabelText>{i18n.t('schedule.confirmed')}</LabelText>
          </Label>
        )}
      </Labels>
      <Detail>
        <Icon name='domain' color={COLOR_TEXT_DARK} size={16} />
        <ContractText>{serviceAddress}</ContractText>
      </Detail>
      <Detail>
        <>
          {SERVICE_TYPES[serviceType]?.iconBlack && (
            <ServiceTypeIcon
              source={
                Platform.OS === 'web'
                  ? require(`../../${SERVICE_TYPES[serviceType].iconBlack}.svg`)
                  : require(`../../${SERVICE_TYPES[serviceType].iconBlack}.png`)
              }
            />
          )}
          <ContractText>{i18n.t(`service_types.${serviceType}.title`)}</ContractText>
        </>
      </Detail>
      <Detail>
        <Icon name='event' color={COLOR_TEXT_DARK} size={16} />
        <ContractText>{startTimeObj.toLocaleString(DateTime.DATE_HUGE)}</ContractText>
      </Detail>
      <Detail>
        <Icon name='schedule' color={COLOR_TEXT_DARK} size={16} />
        <ContractText>{startTimeObj.toLocaleString(DateTime.TIME_SIMPLE)}</ContractText>
      </Detail>
      {provider && (
        <ProviderDetail>
          <Detail>
            <Icon name='person-outline' color={COLOR_TEXT_DARK} size={16} />
            <ContractText>{i18n.t('schedule.selected_date.providers')}:</ContractText>
          </Detail>
          <ProviderNames>{provider.fullName}</ProviderNames>
        </ProviderDetail>
      )}
    </DetailsCard>
  )
}

AppointmentCard.propTypes = {
  appointment: PropTypes.modelOf(AppointmentModel),
  withActions: PropTypes.bool
}

export default observer(AppointmentCard)
