import { types } from 'mobx-state-tree'
import { DateTimeType } from '../helpers/date_time_type'
import Customer from './customer'
import GenericServiceDetail from './generic_service_detail'
import AreaWithRecurringTasksServiceDetail from './area_with_recurring_tasks_service_detail'
import AreaWithTasksServiceDetail from './area_with_tasks_service_detail'
import Schedule from './schedule'
import EventsByDuration from './events_by_duration'
import { PAUSED } from '../constants/contracts'
import { SERVICE_TYPES, TEMPORARY_CLEANING, MONTHLY_PRICING, PER_DELIVERY_PRICING } from '../constants/service_types'

const Contract = types
  .model('Contract', {
    id: types.identifier,
    customer: types.reference(Customer),
    serviceType: types.string,
    serviceName: types.maybeNull(types.string),
    firstServiceAt: types.string,
    address: types.string,
    downloadPdfPath: types.string,
    accepted: types.boolean,
    rejected: types.boolean,
    serviceDetail: types.maybe(
      types.union(AreaWithRecurringTasksServiceDetail, AreaWithTasksServiceDetail, GenericServiceDetail)
    ),
    price: types.number,
    schedule: types.maybeNull(Schedule),
    duration: types.maybeNull(types.number),
    probationWeeks: types.maybeNull(types.number),
    state: types.string,
    pauseEndDate: types.maybeNull(DateTimeType),
    endAt: types.maybeNull(DateTimeType),
    showEndDate: types.boolean,
    fromAutomatedLead: types.boolean,
    additionalAgreements: types.maybeNull(types.string),
    totalEvents: types.number,
    totalEventsByDuration: types.array(EventsByDuration)
  })
  .views(self => ({
    get paused() {
      return self.state === PAUSED
    },
    get isTemporaryCleaningContract() {
      return self.serviceType === TEMPORARY_CLEANING
    },
    get serviceTypeConfig() {
      return SERVICE_TYPES[self.serviceType] || {}
    },
    get hasMonthlyPrice() {
      return self.serviceTypeConfig.pricingType === MONTHLY_PRICING
    },
    get hasPerDeliveryPrice() {
      return self.serviceTypeConfig.pricingType === PER_DELIVERY_PRICING
    },
    get vat() {
      return self.serviceTypeConfig.vat
    },
    get oneOff() {
      return self.serviceTypeConfig.oneOff
    },
    get showRecurrence() {
      return self.serviceTypeConfig.showRecurrence
    },
    get hasAreaBasedServiceDetails() {
      return self.serviceTypeConfig.hasAreaBasedServiceDetails
    },
    get hasEndDate() {
      return self.serviceTypeConfig.hasEndDate
    }
  }))

export default Contract
