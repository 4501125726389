import React from 'react'
import PropTypes from 'lasagna/prop_types'
import { View } from 'react-native'
import { useResponsive } from 'lasagna/components/responsive'
import styled from 'styled-components/native'
const Wrapper = styled(View)`
  align-self: center;
  width: 1024px;
  max-width: 100%;
  padding: 15px;
  ${props =>
    props.isMobile &&
    `
    flex-shrink: 1;
    overflow-y: auto;
  `}
`
const WebWrapper = ({ children }) => {
  const { isMobile } = useResponsive()
  return <Wrapper isMobile={isMobile}>{children}</Wrapper>
}
WebWrapper.propTypes = {
  children: PropTypes.node
}
export default WebWrapper
