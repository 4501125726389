import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { ActivityIndicator, TextInput as UnstyledTextInput, View } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { useStore } from '../../helpers/store'
import { COLOR_LIGHT_BORDER } from 'lasagna/constants'
import PropTypes from 'prop-types'
import { COLOR_BLACK, COLOR_RED_ERROR_TEXT, COLOR_WHITE } from '../../constants/layout'
import { isValidEmailFormat, isValidMobileFormat } from '../../helpers/validation'
import { TouchableOpacity } from 'react-native-web'

const Row = styled(View)`
  flex-direction: row;
  margin-top: 10px;
`

const Label = styled(Text)`
  margin-right: 10px;
  width: 100px;
`

const TextInput = styled(UnstyledTextInput)`
  border: ${COLOR_LIGHT_BORDER};
  padding: 5px 10px;
  border-radius: 5px;
  flex: 1;
`

const ActionContainer = styled(View)`
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
`

const CancelButton = styled(TouchableOpacity)`
  border: 1px solid ${COLOR_BLACK};
  padding: 5px 10px;
  border-radius: 5px;
`
const CancelButtonText = styled(Text)`
  color: ${COLOR_BLACK};
`

const SaveButton = styled(TouchableOpacity)`
  padding: 5px 16px;
  border-radius: 5px;
  background: ${COLOR_BLACK};
  margin-left: 10px;
  flex-direction: row;
  ${props => props.disabled && 'opacity: 0.7;'}
`

const SaveButtonText = styled(Text)`
  color: ${COLOR_WHITE};
  margin-right: 5px;
`

const ErrorText = styled(Text)`
  font-size: 12px;
  color: ${COLOR_RED_ERROR_TEXT};
  text-align: right;
  margin-top: 5px;
`

const UnknownError = styled(Text)`
  font-size: 12px;
  color: ${COLOR_RED_ERROR_TEXT};
  text-align: center;
  margin-top: 20px;
`

const EditUserForm = ({ onCancel }) => {
  const {
    session: {
      currentUser: { firstname, lastname, email, mobile },
      updateUserContactDetails
    }
  } = useStore()

  const [newFirstname, setNewFirstname] = useState(firstname)
  const [newLastname, setNewLastname] = useState(lastname)
  const [newMobile, setNewMobile] = useState(mobile)
  const [newEmail, setNewEmail] = useState(email)
  const [errors, setErrors] = useState()
  const [loading, setLoading] = useState(false)

  const isValidMobile = useMemo(() => isValidMobileFormat(newMobile.trim()), [newMobile])
  const isValidEmail = useMemo(() => isValidEmailFormat(newEmail.trim()), [newEmail])
  const isValid = newFirstname.trim() && newLastname.trim() && isValidMobile && isValidEmail

  const saveAction = async () => {
    setLoading(true)
    const { errors } = await updateUserContactDetails(
      newFirstname.trim(),
      newLastname.trim(),
      newMobile.trim(),
      newEmail.trim()
    )

    if (!errors) {
      onCancel()
    } else {
      setErrors(errors)
    }
    setLoading(false)
  }

  useEffect(() => {
    setErrors()
  }, [newFirstname, newLastname, newEmail, newMobile])

  return (
    <>
      <Row>
        <Label>{i18n.t('account_details.edit_contact_form.labels.firstname')}</Label>
        <TextInput value={newFirstname} onChangeText={setNewFirstname} />
      </Row>
      <Row>
        <Label>{i18n.t('account_details.edit_contact_form.labels.lastname')}</Label>
        <TextInput value={newLastname} onChangeText={setNewLastname} />
      </Row>
      <Row>
        <Label>{i18n.t('account_details.phone')}</Label>
        <TextInput value={newMobile} onChangeText={setNewMobile} placeholder='+49 ----' />
      </Row>
      {!isValidMobile && (
        <ErrorText>{i18n.t('account_details.edit_contact_form.errors.mobile_format_incorrect')}</ErrorText>
      )}
      <Row>
        <Label>{i18n.t('account_details.email')}</Label>
        <TextInput value={newEmail} onChangeText={setNewEmail} />
      </Row>
      {(!isValidEmail || errors?.invalidEmail) && (
        <ErrorText>{i18n.t('account_details.edit_contact_form.errors.email_format_invalid')}</ErrorText>
      )}
      {errors?.emailAlreadyExist && (
        <ErrorText>{i18n.t('account_details.edit_contact_form.errors.email_already_exist')}</ErrorText>
      )}
      {errors?.unknownError && (
        <UnknownError>{i18n.t('account_details.edit_contact_form.errors.general_error')}</UnknownError>
      )}
      <ActionContainer>
        <CancelButton onPress={onCancel} thin>
          <CancelButtonText>{i18n.t('account_details.edit_contact_form.actions.cancel_button')}</CancelButtonText>
        </CancelButton>
        <SaveButton onPress={saveAction} disabled={loading || !isValid}>
          <SaveButtonText>{i18n.t('account_details.edit_contact_form.actions.save_button')}</SaveButtonText>
          {loading && <ActivityIndicator color={COLOR_WHITE} size={14} />}
        </SaveButton>
      </ActionContainer>
    </>
  )
}

EditUserForm.propTypes = {
  onCancel: PropTypes.func.isRequired
}

export default observer(EditUserForm)
