import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import BaseText from 'lasagna/components/text'
import { COLOR_BLACK } from 'lasagna/constants/colors'
import ContentContainer from '../components/layout/content_container'
import Button from '../components/button'
import { PUBLIC } from '../constants/access'
import { COLOR_ACTION } from '../constants/layout'
import openUrl from '../helpers/navigation/open_url'

const Text = styled(BaseText)`
  color: ${COLOR_BLACK};
  text-align: center;
`

const Content = styled(View)`
  align-items: center;
  padding-top: 40px;
`

const Title = styled(Text)`
  font-size: 24px;
  margin-bottom: 12px;
`

const ActionButton = styled(Button)`
  margin: 6px;
  width: 240px;
  margin-top: 30px;
`

const ActionText = styled(Text)`
  font-weight: 600;
  font-size: 18px;
`

const AutomatedOfferRejected = () => {
  return (
    <ContentContainer access={PUBLIC} testID='AutomatedOfferRejected'>
      <Content>
        <Title>{i18n.t('contracts.offer_rejected.title')}</Title>
        <Text>{i18n.t('contracts.offer_rejected.message')}</Text>
        <ActionButton
          onPress={() => openUrl(i18n.t('main_landing_page_url'))}
          color={COLOR_ACTION}
          textColor={COLOR_BLACK}
          borderColor={COLOR_ACTION}
          borderWidth={1}
        >
          <ActionText>{i18n.t('contracts.offer_rejected.return_to_home')}</ActionText>
        </ActionButton>
      </Content>
    </ContentContainer>
  )
}

export default AutomatedOfferRejected
