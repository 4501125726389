import React from 'react'
import { TouchableOpacity } from 'react-native'
import PropTypes from 'lasagna/prop_types'
import { observer } from 'mobx-react'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import Paper from 'lasagna/components/layout/paper'
import { useNavigation } from '../../../../helpers/navigation'
import { useStore } from '../../../../helpers/store'
import { signInPagePath, accountDetailsPath } from '../../../../helpers/urls'
import { COLOR_TEXT_DARK } from '../../../../constants/layout'

const DropdownItemContainer = styled(TouchableOpacity)`
  padding: 10px 0;
  flex-direction: row;
  min-width: 150px;
`

const DropdownItemText = styled(Text).attrs(() => ({
  numberOfLines: 1
}))`
  color: ${COLOR_TEXT_DARK};
  font-weight: 700;
  max-width: 250px;
`

const DropdownContainer = styled(Paper).attrs(() => ({ elevation: 1 }))`
  max-width: 350px;
  padding: 10px 30px;
`

const DropdownItem = observer(({ children, ...props }) => (
  <DropdownItemContainer {...props}>
    <DropdownItemText>{children}</DropdownItemText>
  </DropdownItemContainer>
))

DropdownItem.propTypes = {
  children: PropTypes.node
}

const AccountDropdownContent = () => {
  const navigate = useNavigation()
  const {
    session: { signOut, currentUser }
  } = useStore()

  if (!currentUser) return null

  const onSignOut = () => signOut().then(() => navigate(signInPagePath()))

  return (
    <DropdownContainer>
      <DropdownItem onPress={() => navigate(accountDetailsPath())}>{i18n.t('navigation.account_details')}</DropdownItem>
      <DropdownItem onPress={onSignOut}>{i18n.t('navigation.sign_out')}</DropdownItem>
    </DropdownContainer>
  )
}

export default observer(AccountDropdownContent)
