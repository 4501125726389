import React from 'react'
import PropTypes from 'lasagna/prop_types'
import { TouchableOpacity, View } from 'react-native'
import styled from 'styled-components/native'
import { DateTime } from 'luxon'
import { useResponsive } from 'lasagna/components/responsive'
import BaseText from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import Icon from 'lasagna/components/icon'
import { COLOR_BLACK } from 'lasagna/constants/colors'
import openExternal from '../../helpers/navigation/open_external'
import { pdfPath } from '../../helpers/urls'
import { getRecurrence } from '../../helpers/contracts'
import ContractModel from '../../models/contracts'
import { MAINTENANCE_CLEANING, TEMPORARY_CLEANING } from '../../constants/service_types'
import { COLOR_ACTION } from '../../constants/layout'
import { durationInHours } from '../../helpers/duration'

const Text = styled(BaseText)`
  color: ${COLOR_BLACK};
`

const Header = styled(View)`
  ${({ isDesktop }) =>
    isDesktop &&
    `
    align-items: center;
    flex-direction: row;
  `}
  justify-content: space-between;
  margin-bottom: 24px;
`

const Title = styled(Text)`
  font-size: 24px;
`

const Container = styled(View)`
  margin-top: 24px;
`

const Download = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: baseline;
  margin-right: 12px;
`

const DownloadText = styled(Text)`
  font-size: 16px;
  text-decoration: underline;
  font-weight: 600;
  margin-left: 4px;
`

const Detail = styled(View)`
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;
`

const CompanyName = styled(Text)`
  font-weight: 600;
  margin-right: 6px;
`

const Label = styled(CompanyName)`
  ${({ isDesktop }) => (isDesktop ? 'width: 200px;' : 'flex:1')}
  margin-left: ${({ noIcon }) => (noIcon ? '24px' : '6px')};
`

const SummaryCard = styled(Card)`
  padding: 20px 30px 30px;
`

const ServiceType = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  background-color: ${COLOR_ACTION};
  padding: 4px 8px;
  border-radius: 6px;
  margin: 8px 0;
`

const HeaderDetails = styled(View)`
  justify-content: flex-start;
  align-items: flex-start;
`

const Summary = ({
  contract: {
    downloadPdfPath,
    duration,
    probationWeeks,
    customer: { name: companyName, facilityType, facilityTypeNote },
    address,
    serviceDetail: {
      recurrency,
      size,
      numberOfEmployees,
      vacuumCleanerIncluded,
      facilityHasPets,
      facilityHasPetsNotes
    },
    serviceType,
    serviceName,
    firstServiceAt,
    endAt,
    totalEventsByDuration,
    oneOff,
    showRecurrence,
    hasEndDate,
    hasAreaBasedServiceDetails
  }
}) => {
  const downloadContract = () => openExternal(pdfPath(downloadPdfPath), true)

  const { isDesktop } = useResponsive()

  return (
    <Container>
      <Header isDesktop={isDesktop}>
        <HeaderDetails>
          <Title>{i18n.t('contracts.details.summary')}</Title>
          <Detail>
            <CompanyName>{i18n.t('contracts.details.company_name')}</CompanyName>
            <Text>{companyName}</Text>
          </Detail>
          <ServiceType>{serviceName || i18n.t(`service_types.${serviceType}.title`)}</ServiceType>
        </HeaderDetails>
        {downloadPdfPath && (
          <Download onPress={downloadContract}>
            <Icon color={COLOR_BLACK} name='file-pdf-box' set='MaterialCommunityIcons' size={18} />
            <DownloadText color={COLOR_BLACK}>{i18n.t('contracts.offer.pdf_download')}</DownloadText>
          </Download>
        )}
      </Header>
      <SummaryCard>
        <Detail>
          <Icon name='domain' set='MaterialCommunityIcons' size={18} color={COLOR_BLACK} />
          <Label isDesktop={isDesktop}>{i18n.t('contract.details.address')}</Label>
          <Text>{address}</Text>
        </Detail>
        {showRecurrence && serviceType !== TEMPORARY_CLEANING && (
          <Detail>
            <Icon name='repeat' set='MaterialCommunityIcons' size={18} color={COLOR_BLACK} />
            <Label isDesktop={isDesktop}>{i18n.t('contract.details.service_frequency')}</Label>
            <Text>{oneOff ? i18n.t('contracts.details.one_off') : getRecurrence(recurrency)}</Text>
          </Detail>
        )}
        {hasAreaBasedServiceDetails && (
          <>
            <Detail>
              <Icon name='image-size-select-small' set='MaterialCommunityIcons' size={18} color={COLOR_BLACK} />
              <Label isDesktop={isDesktop}>{i18n.t('contract.details.commercial_space')}</Label>
              <Text>{i18n.t('contracts.details.area', { size })}</Text>
            </Detail>
            <Detail>
              <Icon name='account-outline' set='MaterialCommunityIcons' size={18} color={COLOR_BLACK} />
              <Label isDesktop={isDesktop}>{i18n.t('contract.details.employee_count')}</Label>
              <Text>{numberOfEmployees}</Text>
            </Detail>
          </>
        )}
        {duration && (
          <Detail>
            <Icon name='clock-outline' set='MaterialCommunityIcons' size={18} color={COLOR_BLACK} />
            <Label isDesktop={isDesktop}>{i18n.t('contracts.details.duration')}</Label>
            <Text>
              {duration} {i18n.t('contracts.details.duration_months')}
            </Text>
          </Detail>
        )}
        {firstServiceAt && (
          <Detail>
            <Icon name='calendar-month' set='MaterialCommunityIcons' size={18} color={COLOR_BLACK} />
            <Label isDesktop={isDesktop}>
              {oneOff ? i18n.t('contracts.details.date') : i18n.t('contract.details.start_date')}
            </Label>
            <Text>{DateTime.fromISO(firstServiceAt).toLocaleString(DateTime.DATE_SHORT)}</Text>
          </Detail>
        )}
        {probationWeeks && (
          <Detail>
            <Icon name='clock-check-outline' set='MaterialCommunityIcons' size={18} color={COLOR_BLACK} />
            <Label isDesktop={isDesktop}>{i18n.t('contracts.details.probation')}</Label>
            <Text>
              {probationWeeks} {i18n.t('contracts.details.probation_weeks')}
            </Text>
          </Detail>
        )}
        {hasEndDate && (
          <Detail>
            <Icon name='calendar-month' set='MaterialCommunityIcons' size={18} color={COLOR_BLACK} />
            <Label isDesktop={isDesktop}>{i18n.t('contract.details.end_date')}:</Label>
            <Text>{DateTime.fromISO(endAt).toLocaleString(DateTime.DATE_SHORT)}</Text>
          </Detail>
        )}
        {facilityType && (
          <Detail>
            <Icon name='domain' set='MaterialCommunityIcons' size={18} color={COLOR_BLACK} />
            <Label isDesktop={isDesktop}>{i18n.t('contracts.details.facility_type')}:</Label>
            <Text>
              {i18n.t(`contracts.details.facility_types.${facilityType}`)}
              {facilityTypeNote && ` - ${facilityTypeNote}`}
            </Text>
          </Detail>
        )}
        {serviceType === MAINTENANCE_CLEANING && (
          <>
            <Detail>
              <Icon name='robot-vacuum-variant' set='MaterialCommunityIcons' size={18} color={COLOR_BLACK} />
              <Label isDesktop={isDesktop}>{i18n.t('contracts.details.facility_details.vacuum_included')}</Label>
              <Text>
                {vacuumCleanerIncluded
                  ? i18n.t('contracts.details.facility_details.yes')
                  : i18n.t('contracts.details.facility_details.no')}
              </Text>
            </Detail>
            <Detail>
              <Icon name='paw' set='MaterialCommunityIcons' size={18} color={COLOR_BLACK} />
              <Label isDesktop={isDesktop}>{i18n.t('contracts.details.facility_details.pets_present')}</Label>
              <Text>
                {facilityHasPets
                  ? i18n.t('contracts.details.facility_details.yes')
                  : i18n.t('contracts.details.facility_details.no')}
                {facilityHasPetsNotes && ` - ${facilityHasPetsNotes}`}
              </Text>
            </Detail>
          </>
        )}
        {serviceType === TEMPORARY_CLEANING &&
          totalEventsByDuration?.map(({ count, duration }) => (
            <Detail key={duration}>
              <Label isDesktop={isDesktop} noIcon>
                {count} {i18n.t('contracts.details.total_events.events')}
              </Label>
              <Text>{durationInHours(duration)}</Text>
            </Detail>
          ))}
      </SummaryCard>
    </Container>
  )
}

Summary.propTypes = {
  contract: PropTypes.modelOf(ContractModel)
}

export default Summary
