import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { View, TextInput } from 'react-native'
import PropTypes from 'lasagna/prop_types'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import { useResponsive } from 'lasagna/components/responsive'
import { useStore } from '../../helpers/store'
import { COLOR_HIGHLIGHT_RED, COLOR_ACTION } from '../../constants/layout'
import Button from '../button'
import Card from 'lasagna/components/layout/card'
import { COLOR_INPUT_LIGHT_BORDER, COLOR_TEXT_PRIMARY, COLOR_BLACK } from 'lasagna/constants/colors'
import ReasonPicker from './reason_picker'
import Acknowledgment from './acknowledgment'
import Event from './event'
import ReportEvent from './report_event'
import FacilityPicker from './facility_picker'
import AccountManager from './account_manager'
import AppointmentModel from '../../models/appointment'

const FormCard = styled(Card)`
  padding: 28px 30px 80px;
  margin-top: 15px;
  z-index: 1;
`

const FormItem = styled(View)`
  ${props =>
    props.isDesktop &&
    `
    flex-direction: row;
  `}
  margin: 12px 0;
  align-items: baseline;
`

const ReasonPickerContainer = styled(FormItem)`
  z-index: 2;
`

const FacilityPickerContainer = styled(FormItem)`
  z-index: 1;
`

const Label = styled(View)`
  width: 150px;
  flex-direction: row;
  color: ${COLOR_TEXT_PRIMARY};
`

const Required = styled(Text)`
  margin-left: 6px;
  color: ${COLOR_HIGHLIGHT_RED};
`

const Message = styled(TextInput)`
  padding: 10px 16px;
  border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
  width: 100%;
  border-radius: 5px;
  margin-top: 12px;
`

const SendButton = styled(Button)`
  width: 200px;
`

const ErrorContainer = styled(View)`
  margin: 15px 0;
  height: 40px;
  justify-content: center;
`

const ErrorText = styled(Text)`
  color: ${COLOR_HIGHLIGHT_RED};
  align-items: center;
`

const FacilityView = styled(View)`
  padding: 10px 16px;
  border-radius: 5px;
  border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
`

const ContactUsForm = ({ event }) => {
  const {
    contactForm: { submitContactUsForm },
    customers: { get: getCustomer },
    account: { account }
  } = useStore()

  const [success, setSuccess] = useState()
  const [reason, setReason] = useState()
  const [customer, setCustomer] = useState(getCustomer(event?.customerId))
  const [message, setMessage] = useState('')

  const { isDesktop } = useResponsive()

  const submitForm = async () => {
    const response = await submitContactUsForm(reason, message, event ? event.customerId : customer?.id, event?.id)
    setSuccess(response)
  }

  return (
    <View>
      {!success && (
        <View>
          {!event && <ReportEvent />}
          {event && <Event event={event} />}
          {account?.accountManager && <AccountManager />}
          <FormCard>
            <ReasonPickerContainer isDesktop={isDesktop}>
              <Label>
                <Text>{i18n.t('contact_form.reason')}</Text>
                <Required>*</Required>
              </Label>
              <ReasonPicker reason={reason} onSelect={setReason} eventSpecific={!!event} />
            </ReasonPickerContainer>
            <FacilityPickerContainer isDesktop={isDesktop}>
              <Label>
                <Text>{i18n.t('contact_form.facility.label')}</Text>
              </Label>
              {!event && <FacilityPicker customer={customer} onSelect={setCustomer} />}
              {event && (
                <FacilityView>
                  <Text>{customer?.address}</Text>
                </FacilityView>
              )}
            </FacilityPickerContainer>
            <FormItem isDesktop={isDesktop}>
              <Label>
                <Text>{i18n.t('contact_form.message')}</Text>
                <Required>*</Required>
              </Label>
              <Message value={message} onChangeText={setMessage} multiline numberOfLines={8} />
            </FormItem>
          </FormCard>
          <ErrorContainer>{success === false && <ErrorText>{i18n.t('contact_form.error')}</ErrorText>}</ErrorContainer>
          <SendButton
            disabled={!reason || !message || success}
            textColor={COLOR_BLACK}
            color={COLOR_ACTION}
            onPress={submitForm}
          >
            {i18n.t('contact_form.send')}
          </SendButton>
        </View>
      )}
      {success && <Acknowledgment />}
    </View>
  )
}

ContactUsForm.propTypes = {
  event: PropTypes.modelOf(AppointmentModel)
}

export default observer(ContactUsForm)
