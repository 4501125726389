import React, { useRef, useEffect } from 'react'
import { Platform } from 'react-native'
import { observer } from 'mobx-react'
import PropTypes from 'lasagna/prop_types'
import { useStore } from '../../../helpers/store'
import useWindowDimensions from '../../../helpers/hooks/use_window_dimensions'
import DrawerLayout from 'react-native-gesture-handler/DrawerLayout'
import SidebarContent from './sidebar/content'

const Sidebar = ({ children }) => {
  const {
    session: { sidebar, openSidebar, closeSidebar }
  } = useStore()
  const dimensions = useWindowDimensions()
  const drawerRef = useRef(null)

  useEffect(() => {
    if (drawerRef.current) {
      if (sidebar) {
        drawerRef.current.openDrawer()
      } else {
        drawerRef.current.closeDrawer()
      }
    }
  }, [sidebar])

  const renderNavigationView = () => <SidebarContent />

  return (
    <DrawerLayout
      ref={drawerRef}
      drawerPosition='right'
      drawerWidth={Math.min(dimensions.width, 600)}
      useNativeAnimations={Platform.OS !== 'web'}
      onDrawerOpen={openSidebar}
      onDrawerClose={() => {
        closeSidebar()
      }}
      renderNavigationView={renderNavigationView}
    >
      {children}
    </DrawerLayout>
  )
}

Sidebar.propTypes = {
  children: PropTypes.node
}

export default observer(Sidebar)
