import React from 'react'
import styled from 'styled-components/native'
import PropTypes from 'prop-types'
import { useResponsive } from 'lasagna/components/responsive'
import { TRANSPARENT, COLOR_BLACK } from '../../constants/layout'
import Button from '../button'

const ContractButton = styled(Button)`
  margin: 6px;
  padding: 0 30px;
  ${props =>
    props.isDesktop
      ? `min-width: 320px;
    max-width: 400px;`
      : 'width: 100%;'}
`

const AcceptButton = ({ accepted, rejected, acceptOffer }) => {
  const { isDesktop } = useResponsive()

  return (
    <ContractButton
      textColor={COLOR_BLACK}
      borderColor={COLOR_BLACK}
      borderWidth={1}
      color={TRANSPARENT}
      onPress={acceptOffer}
      stretched={false}
      disabled={accepted || rejected}
      isDesktop={isDesktop}
    >
      {accepted ? i18n.t('contracts.offer.accepted') : i18n.t('contracts.offer.accept')}
    </ContractButton>
  )
}

AcceptButton.propTypes = {
  accepted: PropTypes.bool,
  rejected: PropTypes.bool,
  acceptOffer: PropTypes.func.isRequired
}

export default AcceptButton
