import React from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { useStore } from '../helpers/store'
import ContentContainer from '../components/layout/content_container'
import { COLOR_TEXT_DARK } from '../constants/layout'
import Invoices from '../components/invoices'
import NewBookingBanner from '../components/new_booking/banner'

const Title = styled(Text)`
  color: ${COLOR_TEXT_DARK};
  font-size: 24px;
  font-weight: 500;
  margin-right: 6px;
`

const AccountName = styled(Text)`
  font-weight: 600;
  color: ${COLOR_TEXT_DARK};
  margin-top: 10px;
`

const Header = styled(View)`
  padding: 15px 0 30px;
`

const InvoicesPage = () => {
  const {
    account: { account }
  } = useStore()

  return (
    <ContentContainer>
      <NewBookingBanner />
      <Header>
        <Title>{i18n.t('navigation.invoices')}</Title>
        <AccountName>{account?.name}</AccountName>
      </Header>
      <Invoices />
    </ContentContainer>
  )
}

export default observer(InvoicesPage)
