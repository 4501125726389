import React from 'react'
import { Image, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import { useResponsive } from 'lasagna/components/responsive'
import { useNavigation } from '../../helpers/navigation'
import { newBookingPath } from '../../helpers/urls'

const BannerImage = styled(Image)`
  width: 100%;
  height: 200px;
  margin: 12px 0;
`

const Banner = () => {
  const { isDesktop } = useResponsive()
  const navigate = useNavigation()

  if (!isDesktop) return null

  return (
    <TouchableOpacity onPress={() => navigate(newBookingPath())}>
      <BannerImage source={require('../../assets/images/banners/verticals.png')} />
    </TouchableOpacity>
  )
}

export default Banner
