import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { View, TouchableOpacity } from 'react-native'
import { useResponsive } from 'lasagna/components/responsive'
import BaseText from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import { COLOR_WHITE, COLOR_BLACK } from 'lasagna/constants/colors'
import Modal from 'react-native-modal'
import Button from '../button'
import { COLOR_ACTION, COLOR_HIGHLIGHT_RED, COLOR_LIGHT_ICON } from '../../constants/layout'
import { useStore } from '../../helpers/store'

const Container = styled(Modal)`
  ${({ isDesktop }) =>
    isDesktop ? 'justify-content: center; align-items: center;' : 'justify-content: flex-end; margin: 0;'}
`

const Content = styled(View)`
  background: ${COLOR_WHITE};
  ${({ isDesktop }) => (isDesktop ? 'width: 60%;' : 'border-top-left-radius: 30px; border-top-right-radius: 30px;')};
  padding: 50px 30px;
  align-items: center;
`

const Close = styled(TouchableOpacity)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const Text = styled(BaseText)`
  color: ${COLOR_BLACK};
`

const Title = styled(Text)`
  font-size: 20px;
  padding-bottom: 16px;
`

const ActionButton = styled(Button)`
  width: 240px;
  margin-top: 24px;
`

const ActionText = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: 600;
  margin-left: 4px;
`

const Error = styled(Text)`
  margin-top: 12px;
  color: ${COLOR_HIGHLIGHT_RED};
`

const AcceptModal = ({ handleClose, acceptOffer }) => {
  const { isDesktop } = useResponsive()

  const {
    contracts: { error }
  } = useStore()

  return (
    <Container isVisible isDesktop={isDesktop}>
      <Content isDesktop={isDesktop} testID='OfferAcceptModal'>
        <Close onPress={handleClose}>
          <Icon name='close' color={COLOR_LIGHT_ICON} />
        </Close>
        <Title>{i18n.t('contracts.offer.confirm_accept.title')}</Title>
        <Text>{i18n.t('contracts.offer.confirm_accept.message')}</Text>
        <ActionButton
          onPress={acceptOffer}
          color={COLOR_ACTION}
          textColor={COLOR_BLACK}
          borderColor={COLOR_ACTION}
          borderWidth={1}
          isDesktop={isDesktop}
          testID='OfferAcceptButton'
        >
          <Icon name='check-circle-outline' color={COLOR_BLACK} size={20} set='MaterialCommunityIcons' />
          <ActionText>{i18n.t('contracts.offer.confirm_accept.accept')}</ActionText>
        </ActionButton>
        {error && <Error>{i18n.t('contact_form.error')}</Error>}
      </Content>
    </Container>
  )
}

AcceptModal.propTypes = {
  handleClose: PropTypes.func,
  acceptOffer: PropTypes.func
}

export default AcceptModal
