import ch from './country_config/ch'
import de from './country_config/de'
import fr from './country_config/fr'
import ie from './country_config/ie'
import it from './country_config/it'
import nl from './country_config/nl'
import sg from './country_config/sg'
import uk from './country_config/uk'

export default {
  ch,
  de,
  fr,
  ie,
  it,
  nl,
  sg,
  uk
}
