import gql from 'graphql-tag'

export async function fetchCustomers(client) {
  const query = gql`
    query facilities {
      facilityServices {
        facilities {
          nodes {
            id
            name
            serviceAddress {
              address
            }
          }
        }
      }
    }
  `

  return client.query({ query })
}
