import React from 'react'
import { View, Image, Platform } from 'react-native'
import styled from 'styled-components/native'

const Container = styled(View)`
  align-items: center;
  justify-content: center;
  margin: 20px 0 30px 0;
`

const IceCreamBoundary = styled(View)`
  width: 210px;
  align-items: center;
`

const IceCreamImage = styled(Image)`
  width: 210px;
  height: 210px;
  margin-bottom: 10px;
`

const IceCream = () => (
  <Container>
    <IceCreamBoundary>
      <IceCreamImage source={Platform.OS === 'web' ? require('./ice_cream.svg') : require('./ice_cream.png')} />
    </IceCreamBoundary>
  </Container>
)

export default IceCream
