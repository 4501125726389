import React, { Component } from 'react'
import { View, Text } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { COLOR_WHITE } from 'lasagna/constants/colors'
import { COLOR_BACKGROUND_DARK } from '../../constants/layout'
import Sentry from '../../helpers/sentry'
import { isDevMode, isStaging } from '../../helpers/dev'
import IceCream from './ice_cream'
import Buttons from './buttons'
import DetailedIceCream from './detailed_ice_cream'
import VersionNumber from '../version_number'
import { observer } from 'mobx-react'

// FIXME
const Logo = () => null

const Container = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR_BACKGROUND_DARK};
`

const Title = styled(Text)`
  padding: 0 16px;
  margin-bottom: 16px;
  font-size: 32px;
  text-align: center;
  color: ${COLOR_WHITE};
`

const Paragraph = styled(Text)`
  padding: 0 16px;
  margin-bottom: 16px;
  font-size: 21px;
  text-align: center;
  color: ${COLOR_WHITE};
`

class ErrorBoundary extends Component {
  state = {
    error: null,
    details: false
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    error: PropTypes.object
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.captureException(error, { extra: errorInfo })
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props

    if (prevProps.error !== error) {
      Sentry.captureException(error)
    }
  }

  handleOnPress = () => this.setState({ details: true })

  render() {
    const { children } = this.props
    const error = this.props.error || this.state.error
    const { details } = this.state
    const showErrorDetails = isDevMode() || isStaging()

    if (error) {
      return (
        <Container>
          <Logo />
          {showErrorDetails ? (
            <DetailedIceCream details={details} onPress={this.handleOnPress} error={error} />
          ) : (
            <IceCream />
          )}
          <Title>{i18n.t('ui.error.critical.title').toUpperCase()}</Title>
          <Paragraph>{i18n.t('ui.error.critical.line2')}</Paragraph>
          <Buttons clearStorage={showErrorDetails} />
          <VersionNumber />
        </Container>
      )
    }

    return children
  }
}

export default observer(ErrorBoundary)
