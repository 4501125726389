import React from 'react'
import PropTypes from 'prop-types'
import { View, TouchableOpacity } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { COLOR_WHITE, COLOR_LIGHT_ICON } from '../constants/layout'

const TouchableContainer = styled(TouchableOpacity)`
  border-radius: 8px;
  ${({ stretched, color, borderColor, borderWidth }) => `
    width: ${stretched ? '100%' : 'auto'};
    background-color: ${color};
    border-color: ${borderColor};
    border-width: ${borderWidth};
  `}
`

const ButtonTextContainer = styled(View)`
  min-height: ${({ thin }) => (thin ? '50px;' : '60px')}
  padding: ${({ thin }) => (thin ? '15px 20px;' : '20px')};
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

const ButtonText = styled(Text)`
  font-size: 16px;
  text-align: center;
  ${({ textColor, disabled }) => `
    color: ${textColor};
    font-weight: ${disabled ? 300 : 500};
  `};
`

const ButtonComponent = ({
  children,
  onPress,
  color = COLOR_WHITE,
  borderColor = COLOR_WHITE,
  borderWidth = 0,
  stretched = false,
  textColor = '#000000',
  disabled = false,
  style = {},
  thin = false,
  testID,
  ...rest
}) => (
  <TouchableContainer
    onPress={onPress}
    color={disabled ? COLOR_LIGHT_ICON : color}
    borderColor={disabled ? COLOR_LIGHT_ICON : borderColor}
    borderWidth={borderWidth}
    stretched={stretched}
    disabled={disabled}
    style={style}
    {...rest}
    testID={testID || 'Button'}
  >
    <ButtonTextContainer thin={thin}>
      {typeof children === 'string' ? (
        <ButtonText disabled={disabled} textColor={disabled ? COLOR_WHITE : textColor}>
          {children}
        </ButtonText>
      ) : (
        children
      )}
    </ButtonTextContainer>
  </TouchableContainer>
)

ButtonComponent.propTypes = {
  testID: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  color: PropTypes.string,
  stretched: PropTypes.bool,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  thin: PropTypes.bool
}

const Button = ({ ...props }) => <ButtonComponent {...props} />

Button.propTypes = {
  testID: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  color: PropTypes.string,
  stretched: PropTypes.bool,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  thin: PropTypes.bool
}

export default Button
