import React, { useState } from 'react'
import { View, TouchableOpacity, Text } from 'react-native'
import { observer } from 'mobx-react'
import styled from 'styled-components/native'
import PropTypes from 'lasagna/prop_types'
import Card from 'lasagna/components/layout/card'
import { COLOR_INPUT_LIGHT_BORDER, COLOR_BLACK } from 'lasagna/constants/colors'
import { COLOR_TEXT_DARK, TRANSPARENT, COLOR_ACTION } from '../../constants/layout'
import ServiceOverview from './service_overview'
import AreaServices from './area_services'
import Schedule from './schedule'
import ScheduleTabHeader from './schedule_tab_header'
import ScheduleModel from '../../models/schedule'

const OVERVIEW = 'OVERVIEW'
const SCHEDULE = 'SCHEDULE'
const AREA_SERVICES = 'AREA_SERVICES'

const TabsContainer = styled(View)`
  flex-direction: row;
  align-items: baseline;
  border: 0 solid ${COLOR_INPUT_LIGHT_BORDER};
  border-bottom-width: 1px;
  margin-top: 30px;
`
const Tab = styled(TouchableOpacity)`
  padding: 10px;
  ${props =>
    props.selected
      ? `
    background-color: ${COLOR_ACTION};
    border: 1px solid ${COLOR_ACTION};
  `
      : `
    background-color: ${TRANSPARENT};
    border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
  `}
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-width: 0;
  align-items: center;
  min-width: 120px;
`

const TabTitle = styled(Text)`
  color: ${props => (props.selected ? COLOR_BLACK : COLOR_TEXT_DARK)};
`

const ServiceDescription = styled(Card)`
  padding: 24px;
  margin-top: 24px;
`

const DesktopMaintenanceCleaningTabs = ({
  areas,
  schedule,
  customerId,
  description,
  showRecurrence,
  isTemporaryCleaningContract
}) => {
  const [tab, setTab] = useState(OVERVIEW)
  return (
    <>
      <TabsContainer>
        <Tab selected={tab === OVERVIEW} onPress={() => setTab(OVERVIEW)}>
          <TabTitle selected={tab === OVERVIEW}>{i18n.t('contracts.details.service_overview.title')}</TabTitle>
        </Tab>
        {(schedule || isTemporaryCleaningContract) && (
          <Tab selected={tab === SCHEDULE} onPress={() => setTab(SCHEDULE)}>
            <TabTitle selected={tab === SCHEDULE}>{i18n.t('contracts.details.schedule.title')}</TabTitle>
          </Tab>
        )}
        {areas && (
          <Tab selected={tab === AREA_SERVICES} onPress={() => setTab(AREA_SERVICES)}>
            <TabTitle selected={tab === AREA_SERVICES}>{i18n.t('contracts.details.tabs.service_details')}</TabTitle>
          </Tab>
        )}
      </TabsContainer>
      {tab === OVERVIEW && (
        <>
          {areas && <ServiceOverview areas={areas} showRecurrence={showRecurrence} />}
          {description && (
            <ServiceDescription>
              <Text>{description}</Text>
            </ServiceDescription>
          )}
        </>
      )}

      {tab === SCHEDULE && schedule && <Schedule schedule={schedule} customerId={customerId} />}
      {tab === SCHEDULE && isTemporaryCleaningContract && <ScheduleTabHeader customerId={customerId} />}

      {areas && tab === AREA_SERVICES && <AreaServices areas={areas} showRecurrence={showRecurrence} />}
    </>
  )
}

DesktopMaintenanceCleaningTabs.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      size: PropTypes.number,
      roomCount: PropTypes.number,
      name: PropTypes.string,
      floorType: PropTypes.string,
      recurrency: PropTypes.number
    })
  ),
  schedule: PropTypes.modelOf(ScheduleModel),
  customerId: PropTypes.string,
  description: PropTypes.string,
  showRecurrence: PropTypes.bool,
  isTemporaryCleaningContract: PropTypes.bool
}

export default observer(DesktopMaintenanceCleaningTabs)
