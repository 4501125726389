import { types, flow, getRoot } from 'mobx-state-tree'
import Customer from '../../models/customer'
import api from '../../api'

const ContractsStore = types
  .model('ContractsStore', {
    loading: types.optional(types.boolean, false),
    selectedCustomer: types.maybeNull(types.reference(Customer)),
    endCursor: types.maybeNull(types.string),
    hasNextPage: types.optional(types.boolean, false)
  })
  .actions(self => ({
    fetchFilteredActiveContracts: flow(function* () {
      self.loading = true

      const filters = {
        first: 10,
        after: self.endCursor,
        customerId: self.selectedCustomer ? parseInt(self.selectedCustomer.id) : null
      }

      const {
        data: {
          facilityServices: {
            customerContracts: {
              edges: activeContracts,
              pageInfo: { hasNextPage, endCursor }
            }
          }
        }
      } = yield api(self).fetchActiveContracts(filters)

      activeContracts.forEach(({ node }) => getRoot(self).contracts.put(node))
      self.endCursor = endCursor
      self.hasNextPage = hasNextPage

      self.loading = false
    }),
    setSelectedCustomer: id => {
      self.selectedCustomer = id
      self.endCursor = null
      self.hasNextPage = false
      self.fetchFilteredActiveContracts()
    }
  }))
  .views(self => ({
    get activeContractsBySelectedCustomer() {
      return getRoot(self).contracts.activeContracts.filter(({ customer }) => {
        return self.selectedCustomer ? self.selectedCustomer.id === customer.id : true
      })
    }
  }))

export default ContractsStore
