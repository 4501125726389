import { types } from 'mobx-state-tree'

const Task = types.model('Task', {
  id: types.identifier,
  name: types.string
})

const AreaWithTasks = types.model('AreaWithTasks', {
  id: types.identifier,
  name: types.string,
  size: types.maybeNull(types.number),
  floorType: types.maybeNull(types.string),
  roomCount: types.maybeNull(types.number),
  standardTasks: types.array(Task),
  additionalTasks: types.array(Task)
})

const AreaWithTasksServiceDetail = types.model('AreaWithTasksServiceDetail', {
  id: types.identifier,
  size: types.number,
  areas: types.array(AreaWithTasks),
  numberOfEmployees: types.maybeNull(types.number)
})

export default AreaWithTasksServiceDetail
