import gql from 'graphql-tag'

export async function fetchInvoices(client, { customerId, first, after }) {
  const query = gql`
    query customerInvoices($customerId: Int, $first: Int, $after: String) {
      facilityServices {
        customerInvoices(customerId: $customerId, first: $first, after: $after) {
          edges {
            node {
              id
              netAmount
              grossAmount
              balance
              month
              year
              downloadEventsXlsxPath
              downloadPdfPath
              cancelled
              cancellation
              legacy
              kind
              facilityAddress
              customerContract {
                serviceType
                serviceName
                facility {
                  id
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  `

  return client.query({ query, variables: { customerId, first, after } })
}
