import React, { useEffect } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import Text from 'lasagna/components/text'
import { observer } from 'mobx-react'
import { useStore } from '../helpers/store'
import ContentContainer from '../components/layout/content_container'
import { COLOR_TEXT_DARK } from '../constants/layout'
import ContactUsForm from '../components/contact_us/form'
import Spinner from '../components/spinner'
import styled from 'styled-components/native'

const Title = styled(Text)`
  color: ${COLOR_TEXT_DARK};
  font-size: 24px;
  font-weight: 500;
  margin-right: 6px;
`

const Header = styled(View)`
  flex-direction: row;
  align-items: baseline;
  padding: 15px 0;
`

const ContactUs = ({
  match: {
    params: { eventId }
  }
}) => {
  const {
    appointments: { fetchAppointmentById, get }
  } = useStore()

  const event = get(eventId)

  useEffect(() => {
    fetchAppointmentById(eventId)
  }, [])

  if (eventId && !event) return <Spinner />
  return (
    <ContentContainer testID='ContactUsPage'>
      <Header>
        <Title>{i18n.t('navigation.contact_us')}</Title>
      </Header>

      <ContactUsForm event={event} />
    </ContentContainer>
  )
}

ContactUs.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string
    })
  })
}

export default observer(ContactUs)
