import { getEnvironmentVariable } from '../../constants/environment'
import Sentry from './instance'

export const debug = (category, message, data) => {
  const event = {
    category,
    message,
    data,
    level: 'debug'
  }

  if (getEnvironmentVariable('EXPO_PUBLIC_DEBUG')) {
    if (
      data &&
      Object.keys(data).length !== 0 &&
      !(Object.keys(data).length === 1 && data.arguments && typeof data.arguments === 'undefined')
    ) {
      console.info(`${category}: ${message}`, data)
    } else {
      console.info(`${category}: ${message}`)
    }
  }
  return Sentry.addBreadcrumb(event)
}

export const captureException = (exception, extra = {}) =>
  Sentry.withScope(scope => {
    scope.setExtras(extra)
    Sentry.captureException(exception)
  })

export default Sentry
