import { Platform } from 'react-native'
import { getEnvironmentVariable } from '../constants/environment'

/* Actual URL helpers to be used in the app */

export const rootPath = () => urlToApp('/facility_services')
export const homePagePath = () => '/facility_services'
export const signInPagePath = () => urlToApp('/facility_services/sign_in')
export const contractOfferDetailsPath = (id, token) => `/facility_services/offer/${id}${token ? `/${token}` : ''}`
export const contractDetailsPath = id => `/facility_services/contract/${id}`
export const schedulePath = customerId => `/facility_services/schedule${customerId ? `/${customerId}` : ''}`
export const contactUsPath = () => '/facility_services/contact_us'
export const contactUsEventPath = eventId => `/facility_services/contact_us/${eventId}`
export const invoicesPath = () => '/facility_services/invoices'
export const accountDetailsPath = () => '/facility_services/account'
export const newBookingPath = (contractId, token) =>
  `/facility_services/new_booking${token ? `?contractId=${contractId}&token=${token}` : ''}`
export const newBookingSuccessPath = () => '/facility_services/new_booking/success'
export const newBookingFormPath = (service, contractId, token) =>
  `/facility_services/new_booking/${service}${token ? `?contractId=${contractId}&token=${token}` : ''}`
export const newBookingFunnelPath = (service, contractId, token) =>
  `/facility_services/new_booking/funnel/${service}${token ? `?contractId=${contractId}&token=${token}` : ''}`
export const automatedOfferRejectedPath = () => '/facility_services/offer_rejected'
export const contractsPath = () => '/facility_services/contracts'

export const oauthTokenPath = () => urlToWww('/api/v2/oauth/token')
export const oauthTokenRevokePath = () => urlToWww('/api/v2/oauth/revoke')
export const mobileAuthPath = authData => urlToWww(`/mobile_auth?auth_params=${authData}`)

export const signInPath = redirectTo => urlToWww(`/users/sign_in?redirect_to=${encodeURIComponent(redirectTo)}`)
export const signInWebPath = () => urlToWww('/api/v1/users/sign_in')
export const signOutPath = () => urlToWww('/api/v1/users/sign_out')
export const forgotPasswordPath = () => urlToWww('/users/password/new')
export const pdfPath = path => urlToWww(path)

/* Generic URL helpers to be used to build the above */

const parseHostnames = value => {
  try {
    return JSON.parse(value)
  } catch (e) {
    console.error(e)
    throw new Error('Missing environment variables, please make sure this instance is configured properly')
  }
}

const API_HOSTNAMES = parseHostnames(getEnvironmentVariable('EXPO_PUBLIC_API_HOSTNAMES'))
const APP_HOSTNAMES = parseHostnames(getEnvironmentVariable('EXPO_PUBLIC_APP_HOSTNAMES'))
const LOCALES_HOSTNAMES = parseHostnames(getEnvironmentVariable('EXPO_PUBLIC_LOCALES_HOSTNAMES'))

const getBaseUrl = (hostname = 'localhost') => {
  const protocol = Platform.OS === 'web' ? window.location.protocol : 'http:'
  if (hostname.startsWith('http')) {
    return hostname
  } else if (hostname.startsWith('//')) {
    return `${protocol}${hostname}`
  } else {
    return `${protocol}//${hostname}`
  }
}

export const urlToWww = (relative = '', country = global.country) => {
  return new URL(relative, getBaseUrl(API_HOSTNAMES[country])).toString()
}

export const urlToApp = (relative = '', country = global.country) => {
  return new URL(relative, getBaseUrl(APP_HOSTNAMES[country])).toString()
}

export const urlToLocales = (relative = '', country = global.country) => {
  return new URL(relative, getBaseUrl(LOCALES_HOSTNAMES[country])).toString()
}
