import { types } from 'mobx-state-tree'
import { DateTime } from 'luxon'

export const DateTimeType = types.custom({
  name: 'DateTime',
  fromSnapshot(value) {
    return DateTime.fromISO(value)
  },
  toSnapshot(value) {
    return value.toISO()
  },
  isTargetType(value) {
    return value instanceof DateTime
  },
  getValidationMessage(snapshot) {
    const date = DateTime.fromISO(snapshot)
    return date.invalidReason ? `${date.invalidReason} (${date.invalidExplanation})` : ''
  }
})
