import React, { useState } from 'react'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import { observer } from 'mobx-react'
import { TRANSPARENT } from '../../constants/layout'
import { PAST, FUTURE } from '../../constants/events'
import { COLOR_WHITE, COLOR_INPUT_LIGHT_BORDER, COLOR_BLACK } from 'lasagna/constants/colors'
import styled from 'styled-components/native'
import FutureAppointments from './future_appointments'
import PastAppointments from './past_appointments'
import FacilityFilter from '../facility_filter'
import { useStore } from '../../helpers/store'

const TabHeaders = styled(View)`
  flex-direction: row;
  align-items: baseline;
  border: 0 solid ${COLOR_INPUT_LIGHT_BORDER};
  border-bottom-width: 1px;
`

const Tab = styled(View)`
  padding: 10px 20px;
  ${props =>
    props.selected
      ? `
    background-color: ${COLOR_WHITE};
    border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
    border-bottom-width: 0;
  `
      : `
    background-color: ${TRANSPARENT};
  `}
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  align-items: center;
`

const TabTitle = styled(Text)`
  color: ${COLOR_BLACK};
`

const Tabs = () => {
  const [tab, setTab] = useState(FUTURE)

  const {
    appointments: { selectedCustomer, setSelectedCustomer },
    customers: { customers }
  } = useStore()

  return (
    <>
      {customers.length > 1 && (
        <FacilityFilter selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} />
      )}
      <TabHeaders testID='EventTabs'>
        <Tab selected={tab === FUTURE} onClick={() => setTab(FUTURE)} testID='FutureEventsTab'>
          <TabTitle>{i18n.t('schedule.future.title')}</TabTitle>
        </Tab>
        <Tab selected={tab === PAST} onClick={() => setTab(PAST)} testID='PastEventsTab'>
          <TabTitle>{i18n.t('schedule.past.title')}</TabTitle>
        </Tab>
      </TabHeaders>
      {tab === FUTURE && <FutureAppointments key={selectedCustomer?.id} />}
      {tab === PAST && <PastAppointments key={selectedCustomer?.id} />}
    </>
  )
}

export default observer(Tabs)
