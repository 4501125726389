import React from 'react'
import { TouchableOpacity, Platform, ScrollView } from 'react-native'
import styled from 'styled-components/native'
import PropTypes from 'prop-types'
import IceCream from './ice_cream'

const Error = styled(ScrollView)`
  margin: 15px 0 15px 0;
  max-width: 800px;
  max-height: 240px;
  margin-bottom: 15px;
  padding: 6px;
  background: #fff;
`

const ErrorMessage = styled.Text`
  font-weight: bold;
  font-family: ${Platform.OS === 'ios' ? 'Courier' : 'monospace'};
`

const ErrorBacktrace = styled.Text`
  font-family: ${Platform.OS === 'ios' ? 'Courier' : 'monospace'};
`

const DetailedIceCream = ({ error, details, onPress }) => (
  <>
    {details ? (
      <Error>
        <ErrorMessage>{error.message}</ErrorMessage>
        <ErrorBacktrace>{error.stack}</ErrorBacktrace>
      </Error>
    ) : (
      <TouchableOpacity onPress={onPress}>
        <IceCream />
      </TouchableOpacity>
    )}
  </>
)

DetailedIceCream.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    stack: PropTypes.string.isRequired
  }).isRequired,
  details: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired
}

export default DetailedIceCream
