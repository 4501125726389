import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components'
import Text from 'lasagna/components/text'
import PropTypes from 'prop-types'
import { useDropdownMenu, useDropdownToggle, Dropdown } from 'react-overlays'
import { COLOR_WHITE } from '../../constants/layout'
import { REASONS, EVENT_CONTACT_REASONS } from '../../constants/contact_us'
import { COLOR_INPUT_LIGHT_BORDER, COLOR_TEXT_PRIMARY } from 'lasagna/constants/colors'
import Chevron from '../chevron'
import { observer } from 'mobx-react'

const PickerItemContainer = styled('div')`
  border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
  border-radius: 5px;
  padding: 0 16px 16px;
  background: ${COLOR_WHITE};
  min-width: 250px;
`

const ToggleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
  border-radius: 5px;
  padding: 10px 16px;
  background: ${COLOR_WHITE};
  min-width: 250px;
`

const MenuContainer = styled('div')`
  position: absolute;
  display: ${props => (props.visible ? 'flex' : 'none')};
  background: ${COLOR_WHITE};
  border-radius: 5px;
  z-index: 1;
`

const OptionWrapper = styled('div')`
  margin-top: 16px;
`

const ChevronWrapper = observer(({ open }) => {
  return <Chevron open={open} color={COLOR_TEXT_PRIMARY} />
})

const Toggle = ({ reason }) => {
  const { show } = useDropdownMenu()
  const [props, { toggle }] = useDropdownToggle()
  return (
    <div id='reason-picker' onClick={toggle} {...props} data-testid='ContactReasonPicker'>
      <ToggleContainer>
        {reason && <Text>{i18n.t(`contact_form.reasons.${reason}`)}</Text>}
        {!reason && <Text>{i18n.t('contact_form.select_reason')}</Text>}
        <ChevronWrapper open={show} />
      </ToggleContainer>
    </div>
  )
}

const Menu = ({ onSelect, eventSpecific }) => {
  const { show, props } = useDropdownMenu({ flip: true })
  // eslint-disable-next-line no-unused-vars
  const [_props, { toggle }] = useDropdownToggle()

  const onOptionSelected = value => {
    onSelect(value)
    toggle()
  }

  const reasons = eventSpecific ? EVENT_CONTACT_REASONS : REASONS

  return (
    <MenuContainer {...props} visible={show}>
      <PickerItemContainer>
        {reasons.map(option => (
          <OptionWrapper key={option}>
            <TouchableOpacity onPress={() => onOptionSelected(option)} testID='ContactReason'>
              <Text>{i18n.t(`contact_form.reasons.${option}`)}</Text>
            </TouchableOpacity>
          </OptionWrapper>
        ))}
      </PickerItemContainer>
    </MenuContainer>
  )
}

const ReasonPicker = ({ reason, onSelect, eventSpecific }) => {
  return (
    <Dropdown>
      {({ props }) => (
        <View {...props}>
          <Toggle reason={reason} />
          <Menu onSelect={onSelect} eventSpecific={eventSpecific} />
        </View>
      )}
    </Dropdown>
  )
}

ReasonPicker.propTypes = {
  reason: PropTypes.string,
  onSelect: PropTypes.func,
  eventSpecific: PropTypes.bool
}

Menu.propTypes = {
  onSelect: PropTypes.func,
  eventSpecific: PropTypes.bool
}

Toggle.propTypes = {
  reason: PropTypes.string
}

export default observer(ReasonPicker)
