import React from 'react'
import PropTypes from 'prop-types'
import { View, Image, Platform } from 'react-native'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import { SERVICE_TYPES } from '../../constants/service_types'

const Container = styled(Card)`
  flex-direction: row;
  align-items: center;
  margin-bottom: 18px;
  padding: 18px 36px;
`

const Title = styled(Text)`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 12px;
`

const Details = styled(View)`
  flex-wrap: wrap;
  flex: 1;
`

const Icon = styled(Image)`
  height: 100px;
  width: 100px;
  margin-right: 50px;
`

const ServiceCard = ({ service }) => (
  <Container>
    {SERVICE_TYPES[service].icon && (
      <Icon
        resizeMode='contain'
        source={
          Platform.OS === 'web'
            ? require(`../../${SERVICE_TYPES[service].icon}.svg`)
            : require(`../../${SERVICE_TYPES[service].icon}.png`)
        }
      />
    )}
    <Details>
      <Title>{i18n.t(`service_types.${service}.title`)}</Title>
      <Text>{i18n.t(`service_types.${service}.description`)}</Text>
    </Details>
  </Container>
)

ServiceCard.propTypes = {
  service: PropTypes.string.isRequired
}

export default ServiceCard
