import React, { useState } from 'react'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import PropTypes from 'lasagna/prop_types'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import Row from '../../table/row'
import Cell from '../../table/cell'
import Chevron from '../../chevron'
import { COLOR_TABLE_BORDER, TRANSPARENT, COLOR_TEXT_SECONDARY } from '../../../constants/layout'
import { WEEKDAYS } from '../../../constants/contracts'

const Table = styled(View)`
  margin: 20px 0;
  border-top-color: ${COLOR_TABLE_BORDER};
  border-top-width: 1px;
  border-left-color: ${COLOR_TABLE_BORDER};
  border-left-width: 1px;
`

const CellHeader = styled(Text)`
  word-wrap: break-all;
  font-weight: 700;
`

const TaskName = styled(Text)`
  padding: 8px 4px;
  word-wrap: break-all;
`

const WeekdayContent = styled(View)`
  align-items: center;
  padding: 8px 0;
`

const AreaHeader = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`

const AreaTitle = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`

const AreaCard = styled(Card)`
  margin-top: 12px;
`

const AreaTasksTable = ({ title, tasks }) => (
  <Table>
    <Row>
      <Cell header large background={TRANSPARENT}>
        <CellHeader>{title}</CellHeader>
      </Cell>
      {WEEKDAYS.map(day => (
        <Cell key={day} small header background={TRANSPARENT}>
          <WeekdayContent>
            <Text>{i18n.t(`contracts.details.schedule.short_week_day.${day}`)}</Text>
          </WeekdayContent>
        </Cell>
      ))}
    </Row>
    {tasks.map(({ id, name, ...scheduleDays }) => (
      <Row key={id}>
        <Cell large>
          <TaskName>{name}</TaskName>
        </Cell>
        {WEEKDAYS.map(day => (
          <Cell key={day} small>
            <WeekdayContent>
              <Text>{scheduleDays[day] ? 'X' : ''}</Text>
            </WeekdayContent>
          </Cell>
        ))}
      </Row>
    ))}
  </Table>
)

AreaTasksTable.propTypes = {
  title: PropTypes.string,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      monday: PropTypes.bool,
      tuesday: PropTypes.bool,
      wednesday: PropTypes.bool,
      thursday: PropTypes.bool,
      friday: PropTypes.bool,
      saturday: PropTypes.bool,
      sunday: PropTypes.bool
    })
  )
}

const Area = ({ name, standardTasks, additionalTasks }) => {
  const [collapsed, setCollapsed] = useState(true)
  return (
    <AreaCard>
      <AreaHeader onPress={() => setCollapsed(!collapsed)}>
        <AreaTitle>{i18n.t(`contracts.details.area_services.${name}`, { defaultValue: name })} </AreaTitle>
        <Chevron open={!collapsed} color={COLOR_TEXT_SECONDARY} size={24} />
      </AreaHeader>
      {!collapsed && (
        <>
          {!!standardTasks.length && (
            <AreaTasksTable title={i18n.t('contracts.details.area_services.standard_services')} tasks={standardTasks} />
          )}
          {!!additionalTasks.length && (
            <AreaTasksTable
              title={i18n.t('contracts.details.area_services.additional_services')}
              tasks={additionalTasks}
            />
          )}
        </>
      )}
    </AreaCard>
  )
}

Area.propTypes = {
  name: PropTypes.string,
  standardTasks: PropTypes.array,
  additionalTasks: PropTypes.array
}

const ContractSchedule = ({ areas }) =>
  areas.map(({ name, standardTasks, additionalTasks }) => {
    if (!standardTasks.length && !additionalTasks.length) return null
    return <Area name={name} standardTasks={standardTasks} additionalTasks={additionalTasks} key={name} />
  })

ContractSchedule.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      standardTasks: PropTypes.array,
      additionalTasks: PropTypes.array
    })
  )
}

export default ContractSchedule
