import gql from 'graphql-tag'

export function requestFacilityServicesService(client, { service, customerContractId, notes, token, facilityId }) {
  const mutation = gql`
    mutation requestFacilityServicesService(
      $service: String!
      $customerContractId: ID
      $notes: String
      $token: String
      $facilityId: ID
    ) {
      requestFacilityServicesService(
        service: $service
        notes: $notes
        customerContractId: $customerContractId
        token: $token
        facilityId: $facilityId
      ) {
        success
        errors {
          code
          message
        }
      }
    }
  `

  return client.mutate({ mutation, variables: { service, customerContractId, notes, token, facilityId } })
}
