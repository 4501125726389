import { Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { isUnauthorizedError, refreshAccessToken } from '../auth'
import { createHeaders } from './create_headers'

const createSubscription = function () {
  let subscribers = []
  const wait = (success, error) => subscribers.push({ success, error })

  const success = token => {
    subscribers.map(({ success }) => success(token))
    subscribers = []
  }

  const failure = errMessage => {
    subscribers.map(({ error }) => error(errMessage))
    subscribers = []
  }

  return { wait, success, failure }
}

const tokenSubscription = createSubscription()

const retryWithNewToken = (apiUrl, operation, forward, observer, newAccessToken) => {
  const { headers } = operation.getContext()

  operation.setContext({
    uri: apiUrl,
    headers: createHeaders(headers, newAccessToken)
  })

  return forward(operation).subscribe(observer)
}

const createRefreshTokenLink = ({ getApiUrl, onRefreshFailure = () => null }) => {
  let isRefreshingToken = false

  return onError(({ networkError, operation, forward }) => {
    if (isUnauthorizedError(networkError)) {
      return new Observable(async observer => {
        const refreshToken = await AsyncStorage.getItem('refreshToken')
        const retry = retryWithNewToken.bind(this, getApiUrl(), operation, forward, observer)

        if (!refreshToken) {
          await onRefreshFailure(null)
          return observer.error(networkError)
        }

        try {
          if (!isRefreshingToken) {
            isRefreshingToken = true

            const { accessToken: newAccessToken, refreshToken: newRefreshToken } = await refreshAccessToken(
              refreshToken
            )

            await AsyncStorage.multiSet([
              ['accessToken', newAccessToken],
              ['refreshToken', newRefreshToken]
            ])

            tokenSubscription.success(newAccessToken)
            isRefreshingToken = false

            return retry(newAccessToken)
          } else {
            tokenSubscription.wait(
              token => retry(token),
              error => {
                observer.error(error)
                onRefreshFailure(error)
              }
            )
          }
        } catch (error) {
          await onRefreshFailure(error)
          tokenSubscription.failure(error)
          return observer.error(error)
        }
      })
    }
  })
}

export default createRefreshTokenLink
