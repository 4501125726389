import React from 'react'
import { View, Image, Platform, TouchableOpacity } from 'react-native'
import { DateTime } from 'luxon'
import PropTypes from 'lasagna/prop_types'
import Text from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import styled from 'styled-components/native'
import {
  COLOR_TEXT_DARK,
  COLOR_LIGHT_ICON,
  COLOR_HIGHLIGHT_RED,
  COLOR_ACTION,
  COLOR_BLACK
} from '../../constants/layout'
import NavigationCard from 'lasagna/components/layout/navigation_card'
import { useNavigation } from '../../helpers/navigation'
import { contractDetailsPath } from '../../helpers/urls'
import { SERVICE_TYPES } from '../../constants/service_types'
import ContractModel from '../../models/contracts'
import CustomerModel from '../../models/customer'
import Chevron from '../chevron'

const Title = styled(Text)`
  color: ${COLOR_TEXT_DARK};
  font-weight: 500;
  font-size: 18px;
  margin: 28px 0 16px;
`

const ContractText = styled(Text)`
  color: ${COLOR_TEXT_DARK};
  margin-top: 6px;
`

const ContractEndDate = styled(ContractText)`
  color: ${COLOR_HIGHLIGHT_RED};
`

const ContractCard = styled(NavigationCard)`
  margin-bottom: 8px;
`

const ServiceTypeContainer = styled(View)`
  align-items: flex-start;
  margin-bottom: 4px;
`

const ServiceType = styled(Text)`
  background-color: ${COLOR_ACTION};
  color: ${COLOR_BLACK};
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
`

const ServiceTypeIcon = styled(Image)`
  height: 13px;
  margin-right: 4px;
  ${({ type }) => (type === SERVICE_TYPES.carpet_cleaning.key ? 'width: 20px;' : 'width: 11px;')}
`

const Row = styled(View)`
  flex-direction: row;
`

const Address = styled(Row)`
  align-items: baseline;
`

const State = styled(Text)`
  padding: 3px 8px;
  border: 1px solid ${COLOR_BLACK};
  color: ${COLOR_BLACK};
  border-radius: 6px;
  font-size: 12px;
  margin: 0 0 4px 4px;
`

const AddressIcon = styled(Icon)`
  margin-right: 4px;
`

const ShowMoreContainer = styled(View)`
  align-items: center;
  margin-top: 12px;
`

const ShowMore = styled(TouchableOpacity)`
  flex-direction: row;
`

const ShowMoreText = styled(Text)`
  font-weight: bold;
  color: ${COLOR_BLACK};
  text-decoration: underline;
`

const ActiveContracts = ({ contracts, selectedCustomer, title, showMore, loadMore }) => {
  const navigate = useNavigation()

  const goToContract = id => {
    navigate(contractDetailsPath(id))
  }

  return (
    <View testID='ActiveContracts'>
      {title && <Title>{title}</Title>}
      {contracts.map(({ id, serviceType, customer, firstServiceAt, paused, pauseEndDate, showEndDate, endAt }) => (
        <ContractCard
          elevation={1}
          key={id}
          onPress={() => goToContract(id)}
          chevronColor={COLOR_LIGHT_ICON}
          chevronRight
          testID='ActiveContractCard'
        >
          <Row>
            {SERVICE_TYPES[serviceType] && (
              <ServiceTypeContainer>
                <ServiceType>
                  {SERVICE_TYPES[serviceType]?.iconBlack && (
                    <ServiceTypeIcon
                      source={
                        Platform.OS === 'web'
                          ? require(`../../${SERVICE_TYPES[serviceType].iconBlack}.svg`)
                          : require(`../../${SERVICE_TYPES[serviceType].iconBlack}.png`)
                      }
                      type={serviceType}
                    />
                  )}
                  {i18n.t(`service_types.${serviceType}.title`)}
                </ServiceType>
              </ServiceTypeContainer>
            )}
            {paused && <State>{i18n.t('contracts.details.state.paused')}</State>}
            {!paused && showEndDate && <State>{i18n.t('contracts.details.state.stopped')}</State>}
          </Row>
          <Address>
            <AddressIcon name='domain' set='MaterialCommunityIcons' color={COLOR_TEXT_DARK} size={20} />
            <ContractText>{customer.address}</ContractText>
          </Address>
          <ContractText>
            {i18n.t('contracts.start_date', {
              date: DateTime.fromISO(firstServiceAt).toLocaleString(DateTime.DATE_SHORT)
            })}
          </ContractText>
          {paused && (
            <ContractEndDate>
              {i18n.t('contracts.details.pause_end_date')}
              {pauseEndDate.toLocaleString(DateTime.DATE_SHORT)}
            </ContractEndDate>
          )}
          {!paused && showEndDate && (
            <ContractEndDate>
              {i18n.t('contracts.details.contract_end_date')}
              {endAt.toLocaleString(DateTime.DATE_SHORT)}
            </ContractEndDate>
          )}
        </ContractCard>
      ))}
      {showMore && (
        <ShowMoreContainer>
          <ShowMore onPress={loadMore}>
            <ShowMoreText>{i18n.t('contracts.show_more')}</ShowMoreText>
            <Chevron color={COLOR_BLACK} />
          </ShowMore>
        </ShowMoreContainer>
      )}
      {!contracts.length && (
        <Text>
          {selectedCustomer
            ? i18n.t('contracts.active.empty_selected', { name: selectedCustomer?.name })
            : i18n.t('contracts.active.empty_others')}
        </Text>
      )}
    </View>
  )
}

ActiveContracts.propTypes = {
  contracts: PropTypes.arrayOf(PropTypes.modelOf(ContractModel)),
  selectedCustomer: PropTypes.modelOf(CustomerModel),
  title: PropTypes.string,
  showMore: PropTypes.bool,
  loadMore: PropTypes.func
}

export default ActiveContracts
