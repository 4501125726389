import React, { useState } from 'react'
import { View, TouchableOpacity, Text } from 'react-native'
import { observer } from 'mobx-react'
import styled from 'styled-components/native'
import PropTypes from 'lasagna/prop_types'
import { COLOR_WHITE, COLOR_INPUT_LIGHT_BORDER } from 'lasagna/constants/colors'
import { COLOR_TEXT_DARK, TRANSPARENT, COLOR_BACKGROUND_DARK } from '../../../constants/layout'
import GeneralInfo from './general'
import Overview from './area_overview'
import ContractSchedule from './contract_schedule'
import OfferSchedule from './offer_schedule'
import ContractModel from '../../../models/contracts'

const GENERAL = 'GENERAL'
const OVERVIEW = 'OVERVIEW'
const SCHEDULE = 'SCHEDULE'

const TabsContainer = styled(View)`
  flex-direction: row;
  align-items: baseline;
  border: 0 solid ${COLOR_INPUT_LIGHT_BORDER};
  border-bottom-width: 1px;
`
const Tab = styled(TouchableOpacity)`
  flex: 1;
  padding: 10px;
  ${props =>
    props.selected
      ? `
    background-color: ${COLOR_WHITE};
    border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
    border-bottom-width: 0px;
  `
      : `
    background-color: ${TRANSPARENT};
  `}
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  align-items: center;
`

const TabTitle = styled(Text)`
  color: ${props => (props.selected ? COLOR_TEXT_DARK : COLOR_BACKGROUND_DARK)};
`

const ContractDetailsMobileView = ({ contract, isOffer }) => {
  const [tab, setTab] = useState(GENERAL)
  return (
    <>
      <TabsContainer>
        <Tab selected={tab === GENERAL} onPress={() => setTab(GENERAL)}>
          <TabTitle selected={tab === GENERAL}>{i18n.t('contracts.details.tabs.general')}</TabTitle>
        </Tab>
        <Tab selected={tab === OVERVIEW} onPress={() => setTab(OVERVIEW)}>
          <TabTitle selected={tab === OVERVIEW}>{i18n.t('contracts.details.tabs.overview')}</TabTitle>
        </Tab>
        <Tab selected={tab === SCHEDULE} onPress={() => setTab(SCHEDULE)}>
          <TabTitle selected={tab === SCHEDULE}>{i18n.t('contracts.details.tabs.schedule')}</TabTitle>
        </Tab>
      </TabsContainer>
      {tab === GENERAL && <GeneralInfo contract={contract} />}
      {tab === OVERVIEW && <Overview areas={contract.serviceDetail?.areas} />}
      {tab === SCHEDULE && !isOffer && <ContractSchedule areas={contract.serviceDetail?.areas} />}
      {tab === SCHEDULE && isOffer && <OfferSchedule areas={contract.serviceDetail?.areas} />}
    </>
  )
}

ContractDetailsMobileView.propTypes = {
  contract: PropTypes.modelOf(ContractModel),
  isOffer: PropTypes.bool
}

export default observer(ContractDetailsMobileView)
