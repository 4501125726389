import React from 'react'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import { useResponsive } from 'lasagna/components/responsive'
import PropTypes from 'lasagna/prop_types'
import { COLOR_TEXT_DARK, COLOR_HIGHLIGHT_RED, COLOR_ACTION, COLOR_BLACK } from '../../constants/layout'
import { SERVICE_TYPES } from '../../constants/service_types'
import Overview from './overview'
import GenericServiceDetails from './generic_service_details'
import MaintanenceCleaningServiceDetails from './maintenance_cleaning_service_details'
import MaintenanceCleaningMobileView from './mobile'

const Title = styled(Text)`
  font-size: 16px;
  color: ${COLOR_TEXT_DARK};
  font-weight: 600;
`

const Row = styled(View)`
  flex-direction: row;
`

const Header = styled(View)`
  ${props => props.isDesktop && 'flex-direction: row;'}
  margin: 30px 20px 12px 10px;
  align-items: center;
  justify-content: space-between;
`

const ServiceTypeContainer = styled(View)`
  margin-left: 6px;
`

const ServiceType = styled(Text)`
  background-color: ${COLOR_ACTION};
  color: ${COLOR_BLACK};
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
`

const Price = styled(Text)`
  color: ${COLOR_HIGHLIGHT_RED};
  margin-left: 4px;
  font-weight: 600;
`

const State = styled(Text)`
  padding: 3px 8px;
  border: 1px solid ${COLOR_BLACK};
  color: ${COLOR_BLACK};
  border-radius: 6px;
  font-size: 12px;
  margin: 0 0 4px 4px;
`

const ContractDetails = ({ contract, title, acceptOffer, isOffer }) => {
  const {
    customer,
    firstServiceAt,
    address,
    price,
    serviceDetail: { serviceDescription },
    duration,
    paused,
    pauseEndDate,
    endAt,
    showEndDate,
    serviceType,
    serviceName,
    additionalAgreements,
    isTemporaryCleaningContract,
    hasPerDeliveryPrice
  } = contract
  const { isDesktop } = useResponsive()

  if (!isDesktop && SERVICE_TYPES[serviceType]?.hasAreaBasedServiceDetails) {
    return (
      <MaintenanceCleaningMobileView contract={contract} title={title} acceptOffer={acceptOffer} isOffer={isOffer} />
    )
  }

  const showTabs = SERVICE_TYPES[serviceType]?.hasSchedule || SERVICE_TYPES[serviceType]?.hasAreaBasedServiceDetails

  return (
    <>
      <Header isDesktop={isDesktop}>
        <Row>
          <Title>{title}</Title>
          <ServiceTypeContainer>
            <ServiceType>{serviceName || i18n.t(`service_types.${serviceType}.title`)}</ServiceType>
          </ServiceTypeContainer>
          {paused && <State>{i18n.t('contracts.details.state.paused')}</State>}
          {!paused && showEndDate && <State>{i18n.t('contracts.details.state.stopped')}</State>}
        </Row>
        <Row>
          <Text>
            {hasPerDeliveryPrice ? i18n.t('contracts.details.price_per_delivery') : i18n.t('contracts.details.price')}
          </Text>
          <Price>{i18n.t('contracts.details.formatted_price', { price })}</Price>
        </Row>
      </Header>

      <Overview
        firstServiceAt={firstServiceAt}
        address={address}
        duration={duration}
        pauseEndDate={pauseEndDate}
        paused={paused}
        endAt={endAt}
        showEndDate={showEndDate}
        oneOff={SERVICE_TYPES[serviceType]?.oneOff}
        customer={customer}
        isTemporaryCleaningContract={isTemporaryCleaningContract}
      />

      {showTabs && (
        <MaintanenceCleaningServiceDetails contract={contract} isOffer={isOffer} acceptOffer={acceptOffer} />
      )}
      {!showTabs && <GenericServiceDetails description={serviceDescription} agreements={additionalAgreements} />}
    </>
  )
}

ContractDetails.propTypes = {
  contract: PropTypes.shape({
    customer: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    }),
    serviceType: PropTypes.string,
    serviceName: PropTypes.string,
    firstServiceAt: PropTypes.string,
    address: PropTypes.string,
    price: PropTypes.number,
    serviceDetail: PropTypes.shape({
      size: PropTypes.number,
      recurrency: PropTypes.number,
      areas: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          size: PropTypes.number,
          roomCount: PropTypes.number,
          name: PropTypes.string,
          floorType: PropTypes.string,
          recurrency: PropTypes.number
        })
      ),
      serviceDescription: PropTypes.string
    }),
    schedule: PropTypes.object,
    duration: PropTypes.number,
    accepted: PropTypes.bool,
    rejected: PropTypes.bool,
    downloadPdfPath: PropTypes.string,
    paused: PropTypes.bool,
    pauseEndDate: PropTypes.luxon,
    endAt: PropTypes.luxon,
    showEndDate: PropTypes.bool,
    additionalAgreements: PropTypes.string,
    isTemporaryCleaningContract: PropTypes.bool,
    hasPerDeliveryPrice: PropTypes.bool
  }).isRequired,
  title: PropTypes.string,
  acceptOffer: PropTypes.func,
  isOffer: PropTypes.bool
}

export default observer(ContractDetails)
