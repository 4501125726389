import gql from 'graphql-tag'

export function submitContactUsForm(client, { reason, body, customerId, eventId }) {
  const mutation = gql`
    mutation submitFacilityServicesCustomerContactForm(
      $reason: String!
      $body: String!
      $customerId: ID
      $eventId: ID
    ) {
      submitFacilityServicesCustomerContactForm(
        reason: $reason
        body: $body
        customerId: $customerId
        eventId: $eventId
      ) {
        success
        errors {
          code
          message
        }
      }
    }
  `

  return client.mutate({ mutation, variables: { reason, body, customerId, eventId } })
}
