import { types, flow } from 'mobx-state-tree'
import { DateTime } from 'luxon'
import Appointment from '../models/appointment'
import CalendarUiEvent from '../models/calendar_ui_event'
import Customer from '../models/customer'
import api from '../api'
import { ASCENDING, DESCENDING, DOT_CANCELLED_EVENT_COLOR, DOT_EVENT_COLOR } from '../constants/events'
import { flatMap, groupBy } from 'lodash'

const AppointmentsStore = types
  .model('AppointmentsStore', {
    collection: types.map(Appointment),
    calendarUiEvents: types.map(CalendarUiEvent),
    loading: types.optional(types.boolean, false),
    futureCursor: types.maybeNull(types.string),
    pastCursor: types.maybeNull(types.string),
    hasMoreFutureEvents: types.optional(types.boolean, false),
    hasMorePastEvents: types.optional(types.boolean, false),
    currentMonth: types.optional(types.number, DateTime.local().month),
    selectedDate: types.optional(types.string, DateTime.local().toISODate()),
    selectedCustomer: types.maybeNull(types.reference(Customer)),
    showRequestBannerType: types.maybeNull(types.string)
  })
  .views(self => ({
    get(id) {
      return self.collection.get(id)
    },

    get appointments() {
      return Array.from(self.collection.values())
    },

    get appointmentsByCustomer() {
      return self.appointments.filter(({ customerId }) => {
        return self.selectedCustomer ? self.selectedCustomer.id === customerId : true
      })
    },

    get currentWeekAppointments() {
      const today = DateTime.local()
      return self.appointmentsByCustomer.filter(
        ({ startTimeObj }) => startTimeObj >= today && self.isCurrentWeek(startTimeObj)
      )
    },

    get futureAppointments() {
      const today = DateTime.local()
      return self.appointmentsByCustomer.filter(
        ({ startTimeObj }) => startTimeObj >= today && !self.isCurrentWeek(startTimeObj)
      )
    },

    get pastAppointments() {
      const today = DateTime.local()
      return self.appointmentsByCustomer.filter(({ startTimeObj }) => startTimeObj < today)
    },

    get appointmentsByMonth() {
      return self.appointmentsByCustomer.filter(({ month }) => month === self.currentMonth)
    },

    get formattedSelectedDate() {
      return DateTime.fromISO(self.selectedDate).toFormat('EEEE dd MMM yyyy')
    },

    get selectedDateAppointments() {
      return self.appointmentsByCustomer.filter(({ startTimeObj }) => startTimeObj.toISODate() === self.selectedDate)
    }
  }))
  .actions(self => ({
    fetchAppointments: flow(function* (params) {
      self.loading = true

      const {
        data: {
          facilityServices: {
            events: {
              edges,
              pageInfo: { endCursor, hasNextPage }
            }
          }
        }
      } = yield api(self).fetchEvents({
        ...params
      })

      return { endCursor, hasNextPage, edges }
    }),
    fetchAppointmentById: flow(function* (id) {
      const {
        data: {
          facilityServices: {
            events: { edges }
          }
        }
      } = yield api(self).fetchEvents({ id: parseInt(id) })
      const appointments = edges.map(event => self.put(event.node))

      return appointments
    }),
    fetchFutureAppointments: flow(function* (reset) {
      self.loading = true

      const { edges, endCursor, hasNextPage } = yield self.fetchAppointments({
        afterDateTime: DateTime.local().toISO(),
        order: ASCENDING,
        after: reset ? null : self.futureCursor,
        customerId: self.selectedCustomer ? parseInt(self.selectedCustomer.id) : null
      })

      self.hasMoreFutureEvents = hasNextPage
      self.futureCursor = endCursor
      const appointments = edges.map(event => self.put(event.node))

      self.loading = false

      return appointments
    }),
    fetchPastAppointments: flow(function* (reset) {
      self.loading = true

      const { edges, endCursor, hasNextPage } = yield self.fetchAppointments({
        beforeDateTime: DateTime.local().toISO(),
        order: DESCENDING,
        after: reset ? null : self.pastCursor,
        customerId: self.selectedCustomer ? parseInt(self.selectedCustomer.id) : null
      })

      self.hasMorePastEvents = hasNextPage
      self.pastCursor = endCursor
      const appointments = edges.map(event => self.put(event.node))

      self.loading = false

      return appointments
    }),
    fetchAppointmentsForMonth: flow(function* (year = DateTime.local().year, month = DateTime.local().month) {
      self.loading = true
      self.currentMonth = month

      const { edges } = yield self.fetchAppointments({
        afterDateTime: DateTime.local(year, month).startOf('month').toISO(),
        beforeDateTime: DateTime.local(year, month).endOf('month').toISO(),
        order: ASCENDING,
        first: null
      })

      const appointments = edges.map(event => self.put(event.node))

      self.setCalendarEvents()

      self.loading = false

      return appointments
    }),
    selectDate: day => {
      self.selectedDate = day
    },
    setSelectedCustomer: id => {
      self.selectedCustomer = id
      self.setCalendarEvents()
      self.futureCursor = null
      self.pastCursor = null
      self.hasMoreFutureEvents = false
      self.hasMorePastEvents = false
    },
    setCalendarEvents: () => {
      self.calendarUiEvents.clear()

      flatMap(groupBy(self.appointmentsByCustomer, 'startDate'), (items, startDate) => {
        // The number of dots per date on the calendar corresponding to no. of events should be limited to 3 max.

        const dotsEventsCancelled = items.filter(item => item.cancelled).map(() => DOT_CANCELLED_EVENT_COLOR)
        const dotsEvents = items.filter(item => !item.cancelled).map(() => DOT_EVENT_COLOR)
        const dotColors =
          dotsEventsCancelled.length < 1
            ? dotsEvents.slice(0, 3)
            : [...dotsEvents.slice(0, 2), ...dotsEventsCancelled.slice(0, 3)]

        const calendarEvent = {
          date: startDate,
          dots: dotColors.slice(0, 3).map(color => ({
            color: color
          }))
        }

        self.calendarUiEvents.put(calendarEvent)
      })
    },
    isCurrentWeek: eventDate => {
      const today = DateTime.local()
      return eventDate.hasSame(today, 'week') && eventDate.hasSame(today, 'year')
    },
    reschedule: flow(function* (eventId, reason, startTimes, notes) {
      try {
        const {
          data: {
            requestFacilityServicesEventRescheduling: { success, errors }
          }
        } = yield api(self).requestReschedule({ eventId, reason, startTimes, notes })

        return { success, error: !!errors }
      } catch (e) {
        return { success: false, error: true }
      }
    }),
    cancel: flow(function* (eventId, reason, notes) {
      try {
        const {
          data: {
            requestFacilityServicesEventCancellation: { success }
          }
        } = yield api(self).requestCancellation({ eventId, reason, notes })
        self.collection.delete(eventId)
        self.setCalendarEvents()
        return success
      } catch (e) {
        return false
      }
    }),
    setShowRequestBanner: type => {
      self.showRequestBannerType = type
    },
    put(data) {
      const {
        facility: {
          id: customerId,
          serviceAddress: { address: serviceAddress }
        },
        customerContract: { serviceType, serviceName },
        ...rest
      } = data
      return self.collection.put({
        ...rest,
        customerId,
        serviceType,
        serviceName,
        serviceAddress
      })
    }
  }))

export default AppointmentsStore
