import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import Text from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import PropTypes from 'lasagna/prop_types'
import { DateTime } from 'luxon'
import Card from 'lasagna/components/layout/card'
import { useResponsive } from 'lasagna/components/responsive'
import { COLOR_TEXT_DARK, COLOR_HIGHLIGHT_RED, COLOR_BLACK } from '../../constants/layout'
import { useStore } from '../../helpers/store'
import { useNavigation } from '../../helpers/navigation'
import { schedulePath } from '../../helpers/urls'

const DetailsCard = styled(Card)`
  padding: 24px;
  margin: 0 4px;
  ${props => (props.isDesktop ? 'flex: 1;' : 'margin-bottom: 8px;')}
`

const Overview = styled(View)`
  ${props => props.isDesktop && 'flex-direction: row;'}
  padding-bottom: 12px;
`

const CompanyName = styled(Text)`
  font-size: 16px;
  color: ${COLOR_TEXT_DARK};
  font-weight: 700;
`

const LinkText = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: 600;
  text-decoration: underline;
  margin-right: 4px;
`

const Link = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
`

const LinkIcon = styled(View)`
  border: 2px solid ${COLOR_BLACK};
  border-radius: 100%;
`

const ContractEndDate = styled(Text)`
  margin-top: 4px;
  color: ${COLOR_HIGHLIGHT_RED};
`

const ContractOverview = ({
  firstServiceAt,
  address,
  duration,
  paused,
  pauseEndDate,
  endAt,
  showEndDate,
  isTemporaryCleaningContract,
  customer: { id: customerId, name: companyName, facilityType, facilityTypeNote }
}) => {
  const navigate = useNavigation()
  const {
    session: { currentUser }
  } = useStore()

  const dateKey = duration || isTemporaryCleaningContract ? 'contracts.start_date' : 'contracts.date'
  const { isDesktop } = useResponsive()

  return (
    <Overview isDesktop={isDesktop}>
      <DetailsCard isDesktop={isDesktop}>
        <CompanyName>{companyName}</CompanyName>
        <Text>{duration ? i18n.t('contracts.details.period', { duration }) : i18n.t('contracts.details.one_off')}</Text>
        {facilityType && (
          <Text>
            {i18n.t('contracts.details.facility_type')}: {i18n.t(`contracts.details.facility_types.${facilityType}`)}
            {facilityTypeNote && ` - ${facilityTypeNote}`}
          </Text>
        )}
      </DetailsCard>
      <DetailsCard isDesktop={isDesktop}>
        <Text>{i18n.t(dateKey, { date: DateTime.fromISO(firstServiceAt).toLocaleString(DateTime.DATE_SHORT) })}</Text>
        {!paused && !showEndDate && isTemporaryCleaningContract && (
          <Text>
            {i18n.t('contracts.details.contract_end_date')}
            {endAt.toLocaleString(DateTime.DATE_SHORT)}
          </Text>
        )}
        {!duration && currentUser && (
          <Link
            onPress={() => {
              navigate(schedulePath(customerId))
            }}
          >
            <LinkText>{i18n.t('navigation.schedule')}</LinkText>
            <LinkIcon>
              <Icon name='arrow-forward' color={COLOR_BLACK} size={18} />
            </LinkIcon>
          </Link>
        )}
        {paused && (
          <ContractEndDate>
            {i18n.t('contracts.details.pause_end_date')}
            {pauseEndDate.toLocaleString(DateTime.DATE_SHORT)}
          </ContractEndDate>
        )}
        {!paused && showEndDate && (
          <ContractEndDate>
            {i18n.t('contracts.details.contract_end_date')}
            {endAt.toLocaleString(DateTime.DATE_SHORT)}
          </ContractEndDate>
        )}
      </DetailsCard>
      <DetailsCard isDesktop={isDesktop}>
        <Text>
          {i18n.t('contracts.details.service_address')} {address}
        </Text>
      </DetailsCard>
    </Overview>
  )
}

ContractOverview.propTypes = {
  firstServiceAt: PropTypes.string,
  address: PropTypes.string,
  duration: PropTypes.number,
  paused: PropTypes.bool,
  pauseEndDate: PropTypes.luxon,
  showEndDate: PropTypes.bool,
  endAt: PropTypes.luxon,
  customer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    facilityType: PropTypes.string,
    facilityTypeNote: PropTypes.string
  }),
  isTemporaryCleaningContract: PropTypes.bool
}

export default observer(ContractOverview)
