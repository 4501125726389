import React, { useState } from 'react'
import { TextInput, View, Platform, TouchableOpacity } from 'react-native'
import Text from 'lasagna/components/text'
import { useResponsive } from 'lasagna/components/responsive'
import styled from 'styled-components/native'
import PropTypes from 'prop-types'
import { COLOR_BLACK, COLOR_WHITE, COLOR_BORDER_INPUT, COLOR_ACTION } from '../../constants/layout'
import Button from '../button'
import { withStore } from '../../helpers/store'
import { withNavigation } from '../../helpers/navigation'
import { rootPath, forgotPasswordPath } from '../../helpers/urls'

const StyledTextInput = styled(TextInput)`
  margin: 0px;
  background: ${COLOR_WHITE};
  height: 52px;
  padding: 15px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid ${COLOR_BORDER_INPUT};
  margin-top: 20px;
`

const SignInForm = styled(({ ...props }) => <View {...props} />)`
  background: ${COLOR_WHITE};
  padding: 0 30px 50px;
  border: 1px solid ${COLOR_BORDER_INPUT};
  ${({ isDesktop }) =>
    isDesktop
      ? `
      flex: 2;
      max-width: 375px;
    `
      : 'margin: 0 -20px;'}
`

const ErrorMessage = styled(({ ...props }) => <Text {...props} />)`
  height: 30px;
  text-align: center;
  padding-top: 10px;
  ${({ visible }) => (visible ? '' : 'opacity: 0')}
`

const ForgotPasswordText = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: 600;
`

const ForgotPassword = styled(TouchableOpacity)`
  align-items: center;
  margin: 24px 0 40px;
`

const SignIn = ({
  store: {
    session: { signIn }
  },
  navigate
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const updateEmail = email => {
    setEmail(email)
    setError(false)
  }

  const updatePassword = password => {
    setPassword(password)
    setError(false)
  }

  const handleSignIn = async () => {
    setLoading(true)
    const result = await signIn(email, password)
    if (result) {
      navigate(Platform.OS === 'web' ? rootPath() : 'App')
    } else {
      setLoading(false)
      setError(true)
    }
  }

  const handleForgotPassword = () => {
    navigate(forgotPasswordPath(), {}, false)
  }

  const { isDesktop } = useResponsive()

  return (
    <SignInForm isDesktop={isDesktop}>
      <ErrorMessage visible={error}>{i18n.t('sign_in.error')}</ErrorMessage>
      <StyledTextInput
        autoFocus
        textContentType='username'
        autoComplete='username'
        keyboardType='email-address'
        autoCapitalize='none'
        placeholder={i18n.t('sign_in.email')}
        value={email}
        onChangeText={updateEmail}
      />
      <StyledTextInput
        textContentType='password'
        autoComplete='password'
        secureTextEntry
        placeholder={i18n.t('sign_in.password')}
        value={password}
        onChangeText={updatePassword}
      />
      <ForgotPassword onPress={handleForgotPassword}>
        <ForgotPasswordText>{i18n.t('sign_in.forgot_password')}</ForgotPasswordText>
      </ForgotPassword>
      <Button color={COLOR_ACTION} textColor={COLOR_BLACK} onPress={handleSignIn} disabled={email === '' || loading}>
        {i18n.t('sign_in.button')}
      </Button>
    </SignInForm>
  )
}

SignIn.propTypes = {
  store: PropTypes.shape({
    session: PropTypes.shape({
      signIn: PropTypes.func.isRequired
    })
  }),
  navigate: PropTypes.func.isRequired
}

export default withNavigation(withStore(SignIn))
