import React, { useEffect } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { observer } from 'mobx-react'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { useResponsive } from 'lasagna/components/responsive'
import InvoiceList from './list'
import StackedInvoices from './stacked'
import FacilityFilter from '../facility_filter'
import { useStore } from '../../helpers/store'
import { COLOR_TEXT_DARK, COLOR_BLACK } from '../../constants/layout'

const NoInvoices = styled(Text)`
  font-size: 18px;
  color: ${COLOR_TEXT_DARK};
`

const Container = styled(View)`
  ${({ isDesktop }) => isDesktop && 'flex-direction: row;'};
`

const ShowMore = styled(View)`
  align-items: center;
  margin: 12px 0;
`
const ShowMoreText = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: bold;
  text-decoration: underline;
`
const Content = styled(View)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
`
const Invoices = () => {
  const {
    invoices: { fetchInvoices, invoices, loading, hasNextPage, selectedCustomer, setSelectedCustomer }
  } = useStore()

  useEffect(() => {
    fetchInvoices()
  }, [])

  const { isDesktop } = useResponsive()

  return (
    <Container isDesktop={isDesktop}>
      <FacilityFilter selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} />
      <Content>
        {invoices.length === 0 && !loading && <NoInvoices>{i18n.t('invoices.empty')}</NoInvoices>}
        {isDesktop && <InvoiceList invoices={invoices} />}
        {!isDesktop && <StackedInvoices invoices={invoices} />}
        {hasNextPage && (
          <ShowMore>
            <TouchableOpacity onPress={fetchInvoices}>
              <ShowMoreText>{i18n.t('invoices.show_more')}</ShowMoreText>
            </TouchableOpacity>
          </ShowMore>
        )}
      </Content>
    </Container>
  )
}

export default observer(Invoices)
