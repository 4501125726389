import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import Row from '../../table/row'
import Cell from '../../table/cell'
import Chevron from '../../chevron'
import { COLOR_TABLE_BORDER, TRANSPARENT, COLOR_TEXT_SECONDARY } from '../../../constants/layout'
import { RECURRENCY } from '../../../constants/contracts'

const Table = styled(View)`
  margin: 20px 0;
  border-top-color: ${COLOR_TABLE_BORDER};
  border-top-width: 1px;
  border-left-color: ${COLOR_TABLE_BORDER};
  border-left-width: 1px;
`

const CellContent = styled(View)`
  align-items: center;
  padding: 8px 0;
`

const TaskName = styled(Text)`
  padding: 8px 4px;
  word-wrap: break-all;
`

const AreaHeader = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`

const AreaTitle = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`

const AreaCard = styled(Card)`
  margin-top: 12px;
`

const AreaTasksTable = ({ title, tasks }) => (
  <Table>
    <Row>
      <Cell header large background={TRANSPARENT}>
        {title}
      </Cell>
      <Cell header background={TRANSPARENT}>
        {i18n.t('contracts.details.recurrency.title')}
      </Cell>
    </Row>
    {tasks.map(({ id, name, recurrency }) => (
      <Row key={id}>
        <Cell large>
          <TaskName>{name}</TaskName>
        </Cell>
        <Cell>
          <CellContent>
            <Text>
              {recurrency >= RECURRENCY.weekly && i18n.t('contracts.details.recurrency.week', { recurrency })}
              {recurrency === RECURRENCY.monthly && i18n.t('contracts.details.recurrency.month')}
              {recurrency === RECURRENCY.fortnightly && i18n.t('contracts.details.recurrency.fortnight')}
            </Text>
          </CellContent>
        </Cell>
      </Row>
    ))}
  </Table>
)

AreaTasksTable.propTypes = {
  title: PropTypes.string,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      recurrency: PropTypes.number,
      name: PropTypes.string
    })
  )
}

const Area = ({ name, standardTasks, additionalTasks }) => {
  const [collapsed, setCollapsed] = useState(true)
  return (
    <AreaCard>
      <AreaHeader onPress={() => setCollapsed(!collapsed)}>
        <AreaTitle>{i18n.t(`contracts.details.area_services.${name}`, { defaultValue: name })}</AreaTitle>
        <Chevron open={!collapsed} color={COLOR_TEXT_SECONDARY} size={24} />
      </AreaHeader>
      {!collapsed && (
        <>
          {!!standardTasks.length && (
            <AreaTasksTable title={i18n.t('contracts.details.area_services.standard_services')} tasks={standardTasks} />
          )}
          {!!additionalTasks.length && (
            <AreaTasksTable
              title={i18n.t('contracts.details.area_services.additional_services')}
              tasks={additionalTasks}
            />
          )}
        </>
      )}
    </AreaCard>
  )
}

Area.propTypes = {
  name: PropTypes.string,
  standardTasks: PropTypes.array,
  additionalTasks: PropTypes.array
}

const OfferSchedule = ({ areas }) =>
  areas.map(({ name, standardTasks, additionalTasks }) => {
    if (!standardTasks.length && !additionalTasks.length) return null
    return <Area name={name} standardTasks={standardTasks} additionalTasks={additionalTasks} key={name} />
  })

OfferSchedule.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      standardTasks: PropTypes.array,
      additionalTasks: PropTypes.array
    })
  )
}

export default OfferSchedule
