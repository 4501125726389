import { oauthTokenPath, oauthTokenRevokePath } from '../helpers/urls'
import Sentry, { debug } from '../helpers/sentry'
import { createHeaders } from './utils/create_headers'

/**
 * Internal constant for authorization errors.
 * @private
 * @type {string}
 */
export const UNAUTHORIZED = 'UNAUTHORIZED'

/**
 * Checks if the error comes from being not authorized (either via access token API or coming from Apollo client)
 * @private
 * @param e error
 * @returns {boolean}
 */
export const isUnauthorizedError = e => {
  if (!e) {
    return false
  }

  // Apollo nests actual errors inside their own
  const error = e.networkError || e

  return (error.name === 'ServerError' && error.statusCode === 401) || error.message === UNAUTHORIZED
}

/**
 * Internal helper method
 * @private
 * @param body
 * @returns {Promise<{data: any, status: number}>}
 */
const request = async body => {
  const response = await fetch(oauthTokenPath(), {
    body: JSON.stringify(body),
    cache: 'no-cache',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return {
    status: response.status,
    data: await response.json()
  }
}

/**
 * Request access token from the API.
 * This is only necessary for native and never used on web (web uses cookie-based authentication)
 * @param username
 * @param password
 * @returns {Promise<{accessToken: string, refreshToken: (string)}>}
 */
export async function getAccessToken(username, password) {
  debug('auth', 'Requesting access token', { username })
  const response = await request({
    grant_type: 'password',
    account_type: 'customer_contact',
    username,
    password
  })

  if (response.data.access_token) {
    debug('auth', 'Access token success')

    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token
    }
  } else {
    debug('auth', 'Access token failure', { response: response.data })

    const error = new Error(UNAUTHORIZED)
    error.response = response
    throw error
  }
}

/**
 * Request refresh token from the API.
 * This is only necessary for native and never used on web (web uses cookie-based authentication)
 * @param refreshToken
 * @returns {Promise<{accessToken: string, refreshToken: (string)}>}
 */
export async function refreshAccessToken(refreshToken) {
  // sending the refresh token to Sentry may seem unsafe but there are only two cases in which it will happen:
  // either it was already used successfully in which case it has already been revoked or
  // it did not work at all which means it's useless as an attack vector anyway
  debug('auth', 'Refreshing access token', { refreshToken })

  const response = await request({
    grant_type: 'refresh_token',
    refresh_token: refreshToken
  })

  if (response.data.access_token) {
    debug('auth', 'Refresh token success')

    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token
    }
  } else {
    debug('auth', 'Refresh token failure', { response: response.data })
    Sentry.captureMessage('Customer was unable to refresh access token')

    const error = new Error(UNAUTHORIZED)
    error.response = response
    throw error
  }
}

/**
 * Revoke access token.
 * This is only necessary for native and never used on web (web uses cookie-based authentication)
 * @param accessToken
 * @returns {Promise<boolean>}
 */
export async function revokeAccessToken(accessToken) {
  debug('auth', 'Revoking access token')

  const response = await fetch(oauthTokenRevokePath(), {
    cache: 'no-cache',
    method: 'POST',
    headers: createHeaders(
      {
        'Content-Type': 'application/json'
      },
      accessToken
    )
  })

  return response.status === 200
}
