import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { View, TouchableOpacity } from 'react-native'
import { useResponsive } from 'lasagna/components/responsive'
import BaseText from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import { COLOR_WHITE, COLOR_BLACK } from 'lasagna/constants/colors'
import Modal from 'react-native-modal'
import { COLOR_LIGHT_ICON } from '../../constants/layout'
import ServiceCard from '../new_booking/service_card'
import { useNavigation } from '../../helpers/navigation'
import { newBookingPath } from '../../helpers/urls'

const Container = styled(Modal)`
  ${({ isDesktop }) =>
    isDesktop ? 'justify-content: center; align-items: center;' : 'justify-content: flex-end; margin: 0;'}
`

const Content = styled(View)`
  background: ${COLOR_WHITE};
  ${({ isDesktop }) => (isDesktop ? 'width: 60%;' : 'border-top-left-radius: 30px; border-top-right-radius: 30px;')};
  padding: 50px 30px;
  align-items: center;
`

const Close = styled(TouchableOpacity)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const Text = styled(BaseText)`
  color: ${COLOR_BLACK};
`

const Title = styled(Text)`
  font-size: 20px;
  padding-bottom: 4px;
`

const Cards = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  gap: 1%;
  margin-top: 16px;
`

const Link = styled(TouchableOpacity)`
  margin-top: 24px;
`

const LinkText = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: 600;
  text-decoration: underline;
`

const VerticalsModal = ({ handleClose, id, token }) => {
  const { isDesktop } = useResponsive()
  const navigate = useNavigation()

  return (
    <Container isVisible isDesktop={isDesktop}>
      <Content isDesktop={isDesktop} testID='OfferAcceptModal'>
        <Close onPress={handleClose}>
          <Icon name='close' color={COLOR_LIGHT_ICON} />
        </Close>
        <Title>{i18n.t('navigation.new_booking')}</Title>
        <Text>{i18n.t('new_booking.latest')}</Text>
        <Cards>
          <ServiceCard serviceType='water_dispenser' highlighted contractId={id} token={token} />
          <ServiceCard serviceType='fruit_basket_delivery' highlighted contractId={id} token={token} />
        </Cards>
        <Link onPress={() => navigate(newBookingPath(id, token))}>
          <LinkText>{i18n.t('new_booking.browse')}</LinkText>
        </Link>
      </Content>
    </Container>
  )
}

VerticalsModal.propTypes = {
  handleClose: PropTypes.func,
  id: PropTypes.string,
  token: PropTypes.string
}

export default VerticalsModal
