import React from 'react'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import { View, TouchableOpacity } from 'react-native'
import { COLOR_BLACK } from 'lasagna/constants/colors'
import openExternal from '../../helpers/navigation/open_external'

const Container = styled(View)`
  flex-direction: row;
  margin-top: 12px;
`

const Link = styled(Text)`
  color: ${COLOR_BLACK};
  text-decoration: underline;
`

const Terms = () => (
  <Container>
    <Text>{i18n.t('contracts.details.terms.disclaimer')}</Text>
    <TouchableOpacity onPress={() => openExternal(i18n.t('contracts.details.terms.url'))}>
      <Link>{i18n.t('contracts.details.terms.link')}</Link>
    </TouchableOpacity>
  </Container>
)

export default Terms
