import { types } from 'mobx-state-tree'

const RecurringTask = types.model('RecurringTask', {
  id: types.identifier,
  name: types.string,
  recurrency: types.maybeNull(types.number),
  monday: types.boolean,
  tuesday: types.boolean,
  wednesday: types.boolean,
  thursday: types.boolean,
  friday: types.boolean,
  saturday: types.boolean,
  sunday: types.boolean
})

const AreaWithRecurringTasks = types.model('AreaWithRecurringTasks', {
  id: types.identifier,
  name: types.string,
  recurrency: types.maybeNull(types.number),
  size: types.maybeNull(types.number),
  floorType: types.maybeNull(types.string),
  roomCount: types.maybeNull(types.number),
  standardTasks: types.array(RecurringTask),
  additionalTasks: types.array(RecurringTask)
})

const AreaWithRecurringTasksServiceDetail = types.model('AreaWithRecurringTaskServiceDetail', {
  id: types.identifier,
  size: types.number,
  recurrency: types.number,
  areas: types.array(AreaWithRecurringTasks),
  numberOfEmployees: types.maybeNull(types.number),
  vacuumCleanerIncluded: types.boolean,
  facilityHasPets: types.boolean,
  facilityHasPetsNotes: types.maybeNull(types.string)
})

export default AreaWithRecurringTasksServiceDetail
