import React, { useState } from 'react'
import PropTypes from 'lasagna/prop_types'
import { View, TouchableOpacity, TextInput } from 'react-native'
import styled from 'styled-components/native'
import Card from 'lasagna/components/layout/card'
import BaseText from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import { useResponsive } from 'lasagna/components/responsive'
import { COLOR_BORDER, COLOR_BLACK } from 'lasagna/constants'
import { useStore } from '../helpers/store'
import { COLOR_ACTION } from '../constants/layout'
import CustomerModel from '../models/customer'

const Text = styled(BaseText)`
  color: ${COLOR_BLACK};
`

const Container = styled(Card)`
  ${({ isMobile }) =>
    isMobile
      ? `
    max-height: 200px;
    margin-bottom: 16px;
  `
      : `
    max-height: 525px;
    max-width: 240px;
    margin-right: 16px;
  `}
  overflow: scroll;
  padding: 0;
`

const Search = styled(TextInput)`
  padding: 8px 12px;
  border: 1px solid ${COLOR_BORDER};
  border-radius: 5px;
  margin: 8px 4px;
`

const ShowAll = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: 0 4px;
  ${({ selected }) => selected && `background: ${COLOR_ACTION}`}
`

const CustomerList = styled(View)`
  overflow: scroll;
`

const Customer = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: 0 4px;
  border-top-color: ${COLOR_BORDER};
  border-top-width: 1px;
  border-top-style: solid;
  ${({ selected }) => selected && `background: ${COLOR_ACTION}`}
`

const FacilityFilter = ({ selectedCustomer, setSelectedCustomer }) => {
  const [customerFilter, setCustomerFilter] = useState('')

  const {
    customers: { customers }
  } = useStore()

  // The facility filter is unnecessary if there is only one facility
  if (!customers || customers.length <= 1) return null

  const filteredCustomers = customers.filter(
    ({ address }) => address.toLowerCase().indexOf(customerFilter.toLowerCase()) > -1
  )

  const { isMobile } = useResponsive()

  return (
    <Container isMobile={isMobile}>
      <Search onChangeText={setCustomerFilter} placeholder={i18n.t('customers.search')} />
      <ShowAll
        selected={!selectedCustomer}
        onPress={() => {
          setCustomerFilter('')
          setSelectedCustomer(null)
        }}
      >
        <Text>{i18n.t('schedule.filter.show_all')}</Text>
        {!selectedCustomer && <Icon name='arrow-right' set='MaterialCommunityIcons' color={COLOR_BLACK} size={18} />}
      </ShowAll>
      <CustomerList>
        {filteredCustomers.map(({ id, address }) => (
          <Customer key={id} onPress={() => setSelectedCustomer(id)} selected={id === selectedCustomer?.id}>
            <Text>{address}</Text>
            {id === selectedCustomer?.id && (
              <Icon name='arrow-right' set='MaterialCommunityIcons' color={COLOR_BLACK} size={18} />
            )}
          </Customer>
        ))}
      </CustomerList>
    </Container>
  )
}

FacilityFilter.propTypes = {
  selectedCustomer: PropTypes.modelOf(CustomerModel),
  setSelectedCustomer: PropTypes.func.isRequired
}

export default FacilityFilter
