import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import Card from 'lasagna/components/layout/card'
import Text from 'lasagna/components/text'
import { COLOR_ACTION_LIGHT } from '../../constants/layout'
import { useStore } from '../../helpers/store'

const Container = styled(Card)`
  margin-top: 15px;
  flex-direction: row;
  padding: 30px;
  align-items: center;
`

const Name = styled(View)`
  padding: 4px 12px;
  background: ${COLOR_ACTION_LIGHT};
  border-radius: 12px;
  margin-left: 12px;
`

const AccountManager = () => {
  const {
    account: {
      account: { accountManager }
    }
  } = useStore()
  return (
    <Container>
      <Text>{i18n.t('account_manager.to')}</Text>
      <Name>
        <Text>{accountManager.name}</Text>
      </Name>
    </Container>
  )
}

export default AccountManager
