import React from 'react'
import { View, Image, Platform } from 'react-native'
import PropTypes from 'lasagna/prop_types'
import styled from 'styled-components/native'
import { DateTime } from 'luxon'
import Text from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import Card from 'lasagna/components/layout/card'
import { COLOR_TEXT_PRIMARY } from 'lasagna/constants/colors'
import { useStore } from '../../helpers/store'
import { COLOR_BACKGROUND_DARK } from '../../constants/layout'
import { SERVICE_TYPES } from '../../constants/service_types'
import AppointmentModel from '../../models/appointment'

const EventTitle = styled(Text)`
  font-size: 16px;
  margin-bottom: 15px;
`

const EventCard = styled(Card)`
  padding: 24px 28px 16px;
`

const Detail = styled(View)`
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
`

const DetailText = styled(Text)`
  margin-left: 8px;
`

const ProviderNames = styled(DetailText)`
  color: ${COLOR_BACKGROUND_DARK};
`

const ServiceTypeIcon = styled(Image)`
  height: 13px;
  margin-right: 4px;
  ${({ type }) => (type === SERVICE_TYPES.carpet_cleaning.key ? 'width: 20px;' : 'width: 11px;')}
`

const Event = ({ event: { serviceType, serviceName, startTime, customerId, provider } }) => {
  const {
    customers: { get }
  } = useStore()

  const customer = get(customerId)

  return (
    <>
      <EventTitle>{i18n.t('contact_form.event_title')}</EventTitle>
      <EventCard>
        <Detail>
          {SERVICE_TYPES[serviceType]?.iconBlack && (
            <ServiceTypeIcon
              source={
                Platform.OS === 'web'
                  ? require(`../../${SERVICE_TYPES[serviceType].iconBlack}.svg`)
                  : require(`../../${SERVICE_TYPES[serviceType].iconBlack}.png`)
              }
              type={serviceType}
            />
          )}
          <DetailText>{serviceName || i18n.t(`service_types.${serviceType}.title`)}</DetailText>
        </Detail>
        {customer && (
          <Detail>
            <Icon name='domain' set='MaterialCommunityIcons' color={COLOR_TEXT_PRIMARY} size={16} />
            <DetailText>{customer.address}</DetailText>
          </Detail>
        )}
        <Detail>
          <Icon name='calendar-month' set='MaterialCommunityIcons' color={COLOR_TEXT_PRIMARY} size={16} />
          <DetailText>{DateTime.fromISO(startTime).toFormat('EEEE dd MMM yyyy')}</DetailText>
        </Detail>
        <Detail>
          <Icon name='clock-outline' set='MaterialCommunityIcons' color={COLOR_TEXT_PRIMARY} size={16} />
          <DetailText>{DateTime.fromISO(startTime).toLocaleString(DateTime.TIME_SIMPLE)}</DetailText>
        </Detail>

        {provider && (
          <Detail>
            <Icon name='person-outline' color={COLOR_TEXT_PRIMARY} size={16} />
            <DetailText>{i18n.t('schedule.selected_date.providers')}:</DetailText>
            <ProviderNames>{provider.fullName}</ProviderNames>
          </Detail>
        )}
      </EventCard>
    </>
  )
}

Event.propTypes = {
  event: PropTypes.modelOf(AppointmentModel)
}

export default Event
