import React from 'react'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import ContentContainer from '../components/layout/content_container'
import ServiceCard from '../components/new_booking/service_card'
import { USER, PUBLIC } from '../constants/access'
import useQueryParams from '../helpers/hooks/use_query_params'
import SuggestNewServiceCard from '../components/new_booking/suggest_new_service_card'

const Title = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
`

const Header = styled(View)`
  margin-top: 32px;
`

const SectionTitle = styled(Text)`
  font-weight: bold;
  margin: 32px 0 24px;
`

const LatestServices = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`

const ServicesList = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4%;
`

const NewBooking = () => {
  const query = useQueryParams()
  const contractId = query.get('contractId')
  const token = query.get('token')

  const latestServices = ['water_dispenser', 'fruit_basket_delivery']
  const services = [
    'deep_cleaning',
    'window_cleaning',
    'coffee_machine',
    'fridge_cleaning',
    'janitorial_services',
    'end_of_construction_cleaning'
  ]

  return (
    <ContentContainer access={contractId && token ? PUBLIC : USER}>
      <Header>
        <Title>{i18n.t('navigation.new_booking')}</Title>
      </Header>
      <SectionTitle>{i18n.t('new_booking.latest')}</SectionTitle>
      <LatestServices>
        {latestServices.map(serviceType => (
          <ServiceCard highlighted serviceType={serviceType} key={serviceType} contractId={contractId} token={token} />
        ))}
      </LatestServices>
      <SectionTitle>{i18n.t('new_booking.all_services')}</SectionTitle>
      <ServicesList>
        {services.map(serviceType => (
          <ServiceCard key={serviceType} serviceType={serviceType} contractId={contractId} token={token} />
        ))}
      </ServicesList>
      <SuggestNewServiceCard contractId={contractId} token={token} />
    </ContentContainer>
  )
}

export default NewBooking
