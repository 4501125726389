import { Platform } from 'react-native'
import gql from 'graphql-tag'
import { signInWebPath, signOutPath } from '../helpers/urls'

export async function fetchCurrentUser(client) {
  const query = gql`
    query fetchCurrentUser {
      currentUser {
        id
        firstname
        lastname
        email
        mobile
        role
      }
    }
  `

  return client.query({ query })
}

export const updateUserContactDetails = async (client, { firstname, lastname, phone, email }) => {
  const mutation = gql`
    mutation updateFacilityServicesCustomerContactDetails(
      $firstname: String!
      $lastname: String!
      $phone: String!
      $email: String!
    ) {
      updateFacilityServicesCustomerContactDetails(
        firstName: $firstname
        lastName: $lastname
        phone: $phone
        email: $email
      ) {
        success
        errors {
          code
          message
        }
      }
    }
  `

  return client.mutate({
    mutation,
    variables: {
      firstname,
      lastname,
      phone,
      email
    }
  })
}

export function changeUserLocale(client, language) {
  const mutation = gql`
    mutation changeUserLocale($language: String!) {
      changeUserLocale(language: $language) {
        success
        error
      }
    }
  `

  return client.mutate({ mutation, variables: { language } })
}

export function saveDevice(client, { token }) {
  const mutation = gql`
    mutation($token: String!) {
      addExpoDevice(token: $token) {
        success
      }
    }
  `

  return client.mutate({ mutation, variables: { token } })
}

export const logoutCurrentUser = async _client => {
  try {
    await fetch(signOutPath(), {
      method: 'DELETE',
      credentials: 'include'
    })
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export const signInWeb = async (_client, email, password) => {
  if (Platform.OS !== 'web') {
    return
  }
  try {
    const response = await fetch(signInWebPath(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user: { email, password, type: 'customer_contact' } })
    })

    return response.status === 200
  } catch (error) {
    console.error(error)
    return false
  }
}

export const saveAppFeedback = (client, score, reason, consent) => {
  const mutation = gql`
    mutation createCustomerAppRating($score: Int!, $reason: String, $consent: Boolean) {
      createCustomerAppRating(score: $score, reason: $reason, consent: $consent) {
        success
        errors {
          score
          reason
          consent
        }
      }
    }
  `

  return client.mutate({ mutation, variables: { score, reason, consent } })
}

export const createCustomer = (client, variables) => {
  const mutation = gql`
    mutation createCustomer(
      $email: String!
      $firstName: String!
      $lastName: String!
      $password: String!
      $mobile: String!
      $terms: Boolean!
    ) {
      createCustomer(
        email: $email
        firstname: $firstName
        lastname: $lastName
        password: $password
        mobile: $mobile
        terms: $terms
      ) {
        success
        errors {
          code
          message
        }
      }
    }
  `

  return client.mutate({ mutation, variables })
}
