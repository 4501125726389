import React from 'react'
import { observer } from 'mobx-react'
import { View, TouchableOpacity } from 'react-native'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import Icon from 'lasagna/components/icon'
import { useResponsive } from 'lasagna/components/responsive'
import styled from 'styled-components/native'
import { COLOR_TEXT_DARK, COLOR_HIGHLIGHT_YELLOW } from '../../constants/layout'
import { useStore } from '../../helpers/store'
import { useNavigation } from '../../helpers/navigation'
import { contactUsPath } from '../../helpers/urls'
import AccountCard from './account_card'
import UserCard from './user_card'

const Note = styled(Card)`
  padding: 20px;
  background-color: ${COLOR_HIGHLIGHT_YELLOW};
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
`

const NoteText = styled(Text)`
  color: ${COLOR_TEXT_DARK}
  margin-left: 12px;
`

const DetailsContainer = styled(View)`
  ${({ isDesktop }) => isDesktop && 'flex-direction: row;'}
  justify-content: space-between;
`

const Link = styled(Text)`
  color: ${COLOR_TEXT_DARK};
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: ${COLOR_TEXT_DARK};
`

const UserDetails = () => {
  const navigate = useNavigation()
  const goToContactPage = () => navigate(contactUsPath())
  const { isDesktop } = useResponsive()
  const {
    account: { account }
  } = useStore()
  return (
    <View>
      <Note elevation={0}>
        <Icon name='alert-outline' set='MaterialCommunityIcons' size={40} color={COLOR_TEXT_DARK} />
        <NoteText>
          {i18n.t('account_details.change_details')}
          <TouchableOpacity onPress={goToContactPage}>
            <Link>{i18n.t('account_details.contact_form')}</Link>
          </TouchableOpacity>
        </NoteText>
      </Note>
      <DetailsContainer isDesktop={isDesktop}>
        {account && <AccountCard />}
        <UserCard />
      </DetailsContainer>
    </View>
  )
}

export default observer(UserDetails)
