import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import PropTypes from 'lasagna/prop_types'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import { COLOR_TABLE_BORDER, COLOR_TABLE_HEADER_BACKGROUND } from '../../constants/layout'

const CellContainer = styled(({ ...props }) => <TouchableOpacity {...props} />)`
  ${({ small, large }) => !small && !large && 'flex: 2;'}
  ${({ small }) => small && 'flex: 1;'}
  ${({ large }) => large && 'flex: 3;'}
  justify-content: flex-start;
  border-right-width: 1px;
  border-right-color: ${COLOR_TABLE_BORDER};
  ${({ background }) => (background ? `background: ${background}` : '')}
  ${({ header, background }) =>
    header &&
    `
    background: ${background || COLOR_TABLE_HEADER_BACKGROUND}
  `}
`

const CellTextContainer = styled(View)`
  padding: 10px 15px;
  flex-wrap: wrap;
  flex-shrink: 1;
`

const CellText = styled(({ ...props }) => <Text {...props} />)`
  ${({ header }) =>
    header &&
    `
    font-weight: 700;
  `}
`

const Cell = ({ header = false, small, large, children, background }) => (
  <CellContainer header={header} small={small} large={large} background={background}>
    {typeof children === 'string' || typeof children === 'number' ? (
      <CellTextContainer>
        <CellText header={header}>{children}</CellText>
      </CellTextContainer>
    ) : (
      children
    )}
  </CellContainer>
)

Cell.propTypes = {
  header: PropTypes.bool,
  small: PropTypes.bool,
  children: PropTypes.node,
  background: PropTypes.string,
  large: PropTypes.bool
}

export default Cell
