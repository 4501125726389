import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import Text from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import styled from 'styled-components/native'
import { useStore } from '../helpers/store'
import { useNavigation } from '../helpers/navigation'
import { COLOR_BLACK, COLOR_ACTION_LIGHT } from '../constants/layout'
import { contactUsPath } from '../helpers/urls'

const Title = styled(Text)`
  font-size: 20px;
  color: ${COLOR_BLACK};
  margin-bottom: 16px;
`

const Row = styled(View)`
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
`

const ContactRow = styled(Row)`
  justify-content: space-between;
  max-width: 300px;
`

const IconContainer = styled(View)`
  background: ${COLOR_ACTION_LIGHT};
  border-radius: 100%;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`

const Name = styled(Text)`
  font-size: 16px;
  color: ${COLOR_BLACK};
`

const Detail = styled(Text)`
  font-weight: bold;
  color: ${COLOR_BLACK};
`

const Mail = styled(Detail)`
  text-decoration: underline;
`

const ContactLink = styled(TouchableOpacity)`
  flex-direction: row;
`

const MailIcon = styled(Icon)`
  margin-right: 4px;
`

const AccountManager = () => {
  const navigate = useNavigation()
  const {
    account: {
      account: { accountManager }
    }
  } = useStore()
  return (
    <View>
      <Title>{i18n.t('account_manager.title')}</Title>
      <Row>
        <IconContainer>
          <Icon name='account-outline' set='MaterialCommunityIcons' size={18} />
        </IconContainer>
        <Name>{accountManager.name}</Name>
      </Row>
      <Text>{i18n.t('account_manager.info')}</Text>
      <ContactRow>
        <Detail>{accountManager.phone}</Detail>
        <Text>|</Text>
        <ContactLink onPress={() => navigate(contactUsPath())}>
          <MailIcon name='email-outline' set='MaterialCommunityIcons' size={18} />
          <Mail>{i18n.t('account_manager.contact')}</Mail>
        </ContactLink>
      </ContactRow>
    </View>
  )
}

export default AccountManager
