export default {
  country: 'de',
  timeAmPm: false,
  timeFormat: 'HH:mm',
  timeZone: 'Europe/Berlin',
  locales: ['de', 'de_en'],
  localesIcons: [
    {
      key: 'de',
      icon: 'assets/images/locale/de'
    },
    {
      key: 'de_en',
      icon: 'assets/images/locale/en'
    }
  ]
}
