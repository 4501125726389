import React, { useEffect } from 'react'
import { View, TouchableOpacity } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import AppointmentCard from './card'
import { useStore } from '../../helpers/store'
import Spinner from '../spinner'
import { COLOR_BLACK } from '../../constants/layout'

const Empty = styled(Text)`
  margin-top: 12px;
`

const ShowMore = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: 600;
  font-size: 18px;
  text-decoration: underline;
`

const ButtonContainer = styled(View)`
  align-items: center;
  margin: 16px 0 32px;
`

const PastAppointments = () => {
  const {
    appointments: { fetchPastAppointments, pastAppointments, hasMorePastEvents, loading }
  } = useStore()

  useEffect(() => {
    fetchPastAppointments(true)
  }, [])

  if (loading && !pastAppointments.length) return <Spinner />

  return (
    <View>
      {pastAppointments.map(appointment => (
        <AppointmentCard key={appointment.id} appointment={appointment} />
      ))}
      {!pastAppointments.length && !loading && <Empty>{i18n.t('schedule.past.empty')}</Empty>}

      {hasMorePastEvents && (
        <ButtonContainer>
          <TouchableOpacity onPress={() => fetchPastAppointments(false)}>
            <ShowMore>{i18n.t('schedule.show_more')}</ShowMore>
          </TouchableOpacity>
        </ButtonContainer>
      )}
    </View>
  )
}

export default observer(PastAppointments)
