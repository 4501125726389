import React from 'react'
import { TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import { COLOR_BLACK, COLOR_ACTION } from '../../../constants/layout'
import { newBookingPath } from '../../../helpers/urls'
import { useNavigation } from '../../../helpers/navigation'
import { useStore } from '../../../helpers/store'

const Button = styled(TouchableOpacity)`
  border-radius: 5px;
  background-color: ${COLOR_ACTION};
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 15px;
`

const ButtonText = styled(Text)`
  color: ${COLOR_BLACK};
`

const NewBookingButton = () => {
  const {
    session: { closeSidebar }
  } = useStore()
  const navigate = useNavigation()

  const redirect = () => {
    closeSidebar()
    navigate(newBookingPath())
  }

  return (
    <Button onPress={redirect}>
      <ButtonText>{i18n.t('navigation.new_booking')}</ButtonText>
    </Button>
  )
}

export default NewBookingButton
