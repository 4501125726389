export const MAINTENANCE_CLEANING = 'maintenance_cleaning'
export const TEMPORARY_CLEANING = 'temporary_cleaning'

export const GENERIC_SERVICE = 'generic_service'

export const MONTHLY_PRICING = 'monthly'
export const PER_DELIVERY_PRICING = 'per_delivery'
export const TOTAL_PRICING = 'total'

export const FRUIT_BASKET_DELIVERY = 'fruit_basket_delivery'

/* Adding new keys to the below list will also need additional changes:
  1. phrase app translation for service_types.{new_type}.title and service_types.{new_type}.description
  2. asset files for blue and white icons in src/assets/images/service_types
*/

/*
  CONFIG fields -
  key: Used for fetching service types specific translations
  iconBlue/iconBlack: Path used to find icon images without extension
  requestable: Can a new contract of this type be requested on the new bookings page?
  oneOff: is this a one time thing?
  hasAreaBasedServiceDetails: Service details have a list of areas and corresponding tasks ======> Check if serviceDetail has areas
  showRecurrence: Shows recurrence info like xtime per week or one time event
  hasSchedule: for types potentially with a week based schedule
*/

export const SERVICE_TYPES = {
  maintenance_cleaning: {
    key: MAINTENANCE_CLEANING,
    iconBlue: 'assets/images/service_types/blue/maintenance_cleaning',
    icon: 'assets/images/service_types/main/maintenance_cleaning',
    iconBlack: 'assets/images/service_types/black/maintenance_cleaning',
    oneOff: false,
    showRecurrence: true,
    hasAreaBasedServiceDetails: true,
    hasSchedule: true,
    pricingType: MONTHLY_PRICING,
    vat: 19
  },
  temporary_cleaning: {
    key: TEMPORARY_CLEANING,
    iconBlue: 'assets/images/service_types/blue/maintenance_cleaning',
    iconBlack: 'assets/images/service_types/black/maintenance_cleaning',
    oneOff: false,
    hasEndDate: true,
    showRecurrence: false,
    hasAreaBasedServiceDetails: true,
    hasSchedule: true,
    pricingType: MONTHLY_PRICING,
    vat: 19
  },
  disinfection_cleaning: {
    key: 'disinfection_cleaning',
    iconBlue: 'assets/images/service_types/blue/disinfection_cleaning',
    iconBlack: 'assets/images/service_types/black/disinfection_cleaning',
    oneOff: true,
    showRecurrence: true,
    hasAreaBasedServiceDetails: false,
    hasSchedule: false,
    pricingType: TOTAL_PRICING,
    vat: 19
  },
  window_cleaning: {
    key: 'window_cleaning',
    iconBlue: 'assets/images/service_types/blue/window_cleaning',
    iconBlack: 'assets/images/service_types/black/window_cleaning',
    icon: 'assets/images/service_types/main/window_cleaning',
    oneOff: true,
    showRecurrence: true,
    hasAreaBasedServiceDetails: false,
    hasSchedule: false,
    pricingType: TOTAL_PRICING,
    vat: 19
  },
  deep_cleaning: {
    key: 'deep_cleaning',
    iconBlue: 'assets/images/service_types/blue/deep_cleaning',
    iconBlack: 'assets/images/service_types/black/deep_cleaning',
    icon: 'assets/images/service_types/main/deep_cleaning',
    oneOff: true,
    showRecurrence: true,
    hasAreaBasedServiceDetails: false,
    hasSchedule: false,
    pricingType: TOTAL_PRICING,
    vat: 19
  },
  carpet_cleaning: {
    key: 'carpet_cleaning',
    iconBlue: 'assets/images/service_types/blue/carpet_cleaning',
    iconBlack: 'assets/images/service_types/black/carpet_cleaning',
    oneOff: true,
    showRecurrence: true,
    hasAreaBasedServiceDetails: false,
    hasSchedule: false,
    pricingType: TOTAL_PRICING,
    vat: 19
  },
  extra_one_off_maintenance_cleaning: {
    key: 'extra_one_off_maintenance_cleaning',
    iconBlue: 'assets/images/service_types/blue/maintenance_cleaning',
    iconBlack: 'assets/images/service_types/black/maintenance_cleaning',
    oneOff: true,
    showRecurrence: true,
    hasAreaBasedServiceDetails: false,
    hasSchedule: false,
    pricingType: TOTAL_PRICING,
    vat: 19
  },
  temporary_maintenance_staffing: {
    key: 'temporary_maintenance_staffing',
    iconBlue: 'assets/images/service_types/blue/maintenance_cleaning',
    iconBlack: 'assets/images/service_types/black/maintenance_cleaning',
    oneOff: true,
    showRecurrence: true,
    hasAreaBasedServiceDetails: false,
    hasSchedule: false,
    pricingType: TOTAL_PRICING,
    vat: 19
  },
  temporary_replacement_for_maintenance_cleaning: {
    key: 'temporary_replacement_for_maintenance_cleaning',
    iconBlue: 'assets/images/service_types/blue/maintenance_cleaning',
    iconBlack: 'assets/images/service_types/black/maintenance_cleaning',
    oneOff: false,
    hasEndDate: true,
    showRecurrence: true,
    hasAreaBasedServiceDetails: true,
    hasSchedule: true,
    pricingType: MONTHLY_PRICING,
    vat: 19
  },
  legacy_temporary_replacement_for_maintenance_cleaning: {
    key: 'legacy_temporary_replacement_for_maintenance_cleaning',
    iconBlue: 'assets/images/service_types/blue/maintenance_cleaning',
    iconBlack: 'assets/images/service_types/black/maintenance_cleaning',
    oneOff: true,
    showRecurrence: true,
    hasAreaBasedServiceDetails: false,
    hasSchedule: false,
    pricingType: TOTAL_PRICING,
    vat: 19
  },
  intensive_cleaning: {
    key: 'intensive_cleaning',
    iconBlue: 'assets/images/service_types/blue/deep_cleaning',
    iconBlack: 'assets/images/service_types/black/deep_cleaning',
    icon: 'assets/images/service_types/main/deep_cleaning',
    oneOff: true,
    showRecurrence: true,
    hasAreaBasedServiceDetails: false,
    hasSchedule: false,
    pricingType: TOTAL_PRICING,
    vat: 19
  },
  end_of_construction_cleaning: {
    key: 'end_of_construction_cleaning',
    iconBlue: 'assets/images/service_types/blue/deep_cleaning',
    iconBlack: 'assets/images/service_types/black/deep_cleaning',
    icon: 'assets/images/service_types/main/end_of_construction_cleaning',
    oneOff: true,
    showRecurrence: true,
    hasAreaBasedServiceDetails: false,
    hasSchedule: false,
    pricingType: TOTAL_PRICING,
    vat: 19
  },
  coffee_machine: {
    key: 'coffee_machine',
    iconBlue: 'assets/images/service_types/blue/coffee_machine',
    iconBlack: 'assets/images/service_types/black/coffee_machine',
    icon: 'assets/images/service_types/main/coffee_machine',
    oneOff: false,
    hasAreaBasedServiceDetails: false,
    hasSchedule: false,
    pricingType: MONTHLY_PRICING,
    vat: 19
  },
  water_dispenser: {
    key: 'water_dispenser',
    iconBlue: 'assets/images/service_types/blue/water_dispenser',
    iconBlack: 'assets/images/service_types/black/water_dispenser',
    icon: 'assets/images/service_types/main/water_dispenser',
    oneOff: false,
    hasAreaBasedServiceDetails: false,
    hasSchedule: false,
    pricingType: MONTHLY_PRICING,
    vat: 19
  },
  janitorial_services: {
    key: 'janitorial_services',
    iconBlack: 'assets/images/service_types/black/janitorial_services',
    icon: 'assets/images/service_types/main/janitorial_services',
    oneOff: true,
    showRecurrence: true,
    hasAreaBasedServiceDetails: false,
    hasSchedule: false,
    pricingType: TOTAL_PRICING,
    vat: 19
  },
  fridge_cleaning: {
    key: 'fridge_cleaning',
    iconBlack: 'assets/images/service_types/black/fridge_cleaning',
    icon: 'assets/images/service_types/main/fridge_cleaning',
    oneOff: false,
    showRecurrence: true,
    hasAreaBasedServiceDetails: false,
    hasSchedule: true,
    pricingType: MONTHLY_PRICING,
    vat: 19
  },
  fruit_basket_delivery: {
    key: FRUIT_BASKET_DELIVERY,
    icon: 'assets/images/service_types/main/fruit_basket_delivery',
    oneOff: false,
    showRecurrence: true,
    hasAreaBasedServiceDetails: false,
    hasSchedule: true,
    pricingType: PER_DELIVERY_PRICING,
    vat: 7,
    hasBookingFunnel: true,
    funnelConfig: {
      recurrencePerWeek: [1, 2, 3, 4, 5],
      packageOptions: [
        { type: 'basic', employeeCount: '10-20' },
        { type: 'basic_heavy', employeeCount: '>20' },
        { type: 'premium', employeeCount: '<10' },
        { type: 'premium_heavy', employeeCount: '10-20' },
        { type: 'bio_premium', employeeCount: '10-20' },
        { type: 'bio_premium_heavy', employeeCount: '>20' }
      ]
    }
  },
  generic_service: {
    key: GENERIC_SERVICE
  }
}
