import gql from 'graphql-tag'

const customerContractFragment = gql`
  fragment ContractDetails on FacilityServicesCustomerContractType {
    id
    facility {
      id
      name
      serviceAddress {
        address
      }
      facilityType
      facilityTypeNote
    }
    serviceType
    serviceName
    firstServiceAt
    address {
      address
    }
    downloadPdfPath
    accepted
    rejected
    duration
    probationWeeks
    state
    pause {
      endAt
    }
    endAt
    showEndDate
    schedule {
      weeks {
        frequency
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
    totalEvents
    totalEventsByDuration {
      count
      duration
    }
    serviceDetail {
      ... on FacilityServicesOneOffServiceServiceDetailType {
        serviceDescription
      }
      ... on FacilityServicesFixedFeeRecurringServiceDetailType {
        serviceDescription
      }
      ... on FacilityServicesFixedFeeRecurringWithEventsServiceDetailType {
        recurrency
        serviceDescription
      }
      ... on FacilityServicesTemporaryCleaningServiceDetailType {
        id
        size
        numberOfEmployees
        areas {
          id
          size
          roomCount
          name
          floorType
          standardTasks {
            id
            name
          }
          additionalTasks {
            id
            name
          }
        }
      }
      ... on FacilityServicesMaintenanceCleaningServiceDetailType {
        id
        size
        recurrency
        numberOfEmployees
        vacuumCleanerIncluded
        facilityHasPets
        facilityHasPetsNotes
        areas {
          id
          size
          roomCount
          name
          recurrency
          floorType
          standardTasks {
            id
            name
            recurrency
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
          }
          additionalTasks {
            id
            name
            recurrency
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
          }
        }
      }
    }
    customerMonthlyPrice
    fromAutomatedLead
    additionalAgreements
  }
`

export async function fetchActiveContracts(client, filters) {
  const query = gql`
    query customerContracts($customerId: Int, $first: Int, $after: String) {
      facilityServices {
        customerContracts(filter: active, customerId: $customerId, after: $after, first: $first) {
          edges {
            node {
              ...ContractDetails
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    }
    ${customerContractFragment}
  `

  return client.query({ query, variables: { ...filters } })
}

export async function fetchContractsForCustomerContact(client, customerId) {
  const query = gql`
    query customerContractsForCustomer($customerId: Int) {
      facilityServices {
        offers: customerContracts(filter: offer) {
          nodes {
            ...ContractDetails
          }
        }

        activeContracts: customerContracts(filter: active, customerId: $customerId) {
          nodes {
            ...ContractDetails
          }
        }
      }
    }

    ${customerContractFragment}
  `

  return client.query({ query, variables: { customerId: Number(customerId) } })
}

export async function fetchOffersForCustomerContact(client) {
  const query = gql`
    query customerContractsForCustomer {
      facilityServices {
        offers: customerContracts(filter: offer) {
          nodes {
            ...ContractDetails
          }
        }
      }
    }

    ${customerContractFragment}
  `

  return client.query({ query, variables: {} })
}

export async function fetchCustomerContractById(client, { id, token }) {
  const query = gql`
    query fetchCustomerContractById($id: Int, $token: String) {
      facilityServices {
        customerContracts(id: $id, token: $token) {
          nodes {
            ...ContractDetails
          }
        }
      }
    }
    ${customerContractFragment}
  `

  return client.query({ query, variables: { id, token } })
}

export function acceptContractOffer(client, { id, token }) {
  const mutation = gql`
    mutation acceptFacilityServicesCustomerContract($id: Int, $token: String) {
      acceptFacilityServicesCustomerContract(id: $id, token: $token) {
        success
        errors {
          code
          message
        }
      }
    }
  `

  return client.mutate({ mutation, variables: { id, token } })
}

export function rejectContractOffer(client, { id, token, notes, reason }) {
  const mutation = gql`
    mutation rejectFacilityServicesCustomerContract($id: Int, $token: String, $notes: String, $reason: String) {
      rejectFacilityServicesCustomerContract(id: $id, token: $token, notes: $notes, reason: $reason) {
        success
        errors {
          code
          message
        }
      }
    }
  `

  return client.mutate({ mutation, variables: { id, token, notes, reason } })
}

export async function fetchActiveContractTypes(client) {
  const query = gql`
    query activeContractTypes {
      facilityServices {
        customerContracts(filter: active) {
          nodes {
            serviceType
          }
        }
      }
    }
  `

  return client.query({ query })
}
