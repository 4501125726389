import React from 'react'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import PropTypes from 'lasagna/prop_types'
import { TouchableOpacity, View } from 'react-native'
import { useDropdownMenu, useDropdownToggle, Dropdown } from 'react-overlays'
import { COLOR_WHITE } from '../../constants/layout'
import { COLOR_INPUT_LIGHT_BORDER, COLOR_TEXT_PRIMARY } from 'lasagna/constants/colors'
import { useResponsive } from 'lasagna/components/responsive'
import Chevron from '../chevron'
import { observer } from 'mobx-react'
import FacilityPickerMenu from './facility_picker_menu'
import CustomerModel from '../../models/customer'

const ToggleContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
  border-radius: 5px;
  padding: 10px 16px;
  background: ${COLOR_WHITE};
  min-width: ${({ isDesktop }) => (isDesktop ? '400px;' : '284px;')};
`

const ChevronWrapper = observer(({ open }) => {
  return <Chevron open={open} color={COLOR_TEXT_PRIMARY} />
})

const Toggle = ({ customer }) => {
  const { show } = useDropdownMenu()
  const [props, { toggle }] = useDropdownToggle()
  const { isDesktop } = useResponsive()

  return (
    <TouchableOpacity id='facility-picker' onPress={toggle} {...props} testID='ContactFormFacilityPicker'>
      <ToggleContainer isDesktop={isDesktop}>
        {customer && <Text>{customer.address}</Text>}
        {!customer && <Text>{i18n.t('contact_form.facility.placeholder')}</Text>}
        <ChevronWrapper open={show} />
      </ToggleContainer>
    </TouchableOpacity>
  )
}

const FacilityPicker = ({ customer, onSelect }) => {
  return (
    <Dropdown>
      {({ props }) => (
        <View {...props}>
          <Toggle customer={customer} />
          <FacilityPickerMenu onSelect={onSelect} selectedCustomer={customer} />
        </View>
      )}
    </Dropdown>
  )
}

FacilityPicker.propTypes = {
  customer: PropTypes.modelOf(CustomerModel),
  onSelect: PropTypes.func
}

Toggle.propTypes = {
  customer: PropTypes.modelOf(CustomerModel)
}

export default observer(FacilityPicker)
