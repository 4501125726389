import React, { createContext, useContext } from 'react'
import AppStore from '../stores/app'

/**
 * React context with AppStore instance
 */
export const AppStoreContext = createContext(AppStore.create())

/**
 * React hook to use the store in functional components
 */
export const useStore = () => useContext(AppStoreContext)

/**
 * React higher order component to pass store in props, can be used for class-based components
 *
 * @example
 *   const CurrentUserName = ({ store: { session: { currentUser } }) => (
 *     <Text>{currentUser.name}</Text>
 *   )
 *   export default withStore(CurrentUserName)
 *
 */
export const withStore = (Component) => (props) => {
  const store = useStore()
  return <Component {...props} store={store} />
}
