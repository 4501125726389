import AsyncStorage from '@react-native-async-storage/async-storage'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import createRefreshTokenLink from './refresh_token_link'
import { networkStatusNotifierLink } from './network_status_notifier'
import { createHeaders } from './create_headers'

const createApolloClient = ({ getApiUrl, onTokenRefreshFailure }) => {
  const authLink = setContext(async (_, { headers }) => {
    const token = await AsyncStorage.getItem('accessToken')
    return {
      uri: getApiUrl(),
      headers: createHeaders(headers, token)
    }
  })

  const batchHttpLink = new BatchHttpLink({
    credentials: 'include',
    batchMax: 4 // limit the maximum amount of batched queries to prevent max complexity error
  })

  const refreshTokenLink = createRefreshTokenLink({ getApiUrl, onRefreshFailure: onTokenRefreshFailure })

  const link = ApolloLink.from([networkStatusNotifierLink, authLink, refreshTokenLink, batchHttpLink])

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      }
    }
  })
}

export default createApolloClient
