import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import { COLOR_BLACK, COLOR_NOTIFICATION_BANNER } from '../../constants/layout'

const NotificationBanner = styled(View)`
  background: ${COLOR_NOTIFICATION_BANNER};
  padding: 16px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

const Notification = styled(Text)`
  color: ${COLOR_BLACK};
  margin-left: 8px;
`

const RequestSuccessBanner = ({ type }) => {
  if (!type) return null

  return (
    <NotificationBanner testID='NotificationBanner'>
      <Icon name='check-outline' set='MaterialCommunityIcons' size={24} />
      <Notification>{i18n.t(`schedule.request_success_banner.${type}`)}</Notification>
    </NotificationBanner>
  )
}

RequestSuccessBanner.propTypes = {
  type: PropTypes.string
}

export default RequestSuccessBanner
