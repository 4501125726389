import 'react-app-polyfill/ie11'
import React, { useState } from 'react'
import { Platform, SafeAreaView } from 'react-native'
import AppLoading from 'expo-app-loading'
import initializeFonts from 'lasagna/fonts'
import { ResponsiveProvider } from 'lasagna/components/responsive'
import { AppStoreContext } from './src/helpers/store'
import { ApplicationRouter, Switch, Route, Redirect } from './src/components/router'
import Sentry from './src/helpers/sentry'
import ErrorBoundary from './src/components/error_boundary'
import Updates from './src/components/updates'
import AppStore from './src/stores/app'
import Index from './src/pages/index'
import SignIn from './src/pages/sign_in'
import ContractDetails from './src/pages/contract_details'
import Schedule from './src/pages/schedule'
import ContactUs from './src/pages/contact_us'
import Invoices from './src/pages/invoices'
import AccountDetails from './src/pages/account_details'
import NewBooking from './src/pages/new_booking'
import NewBookingForm from './src/pages/new_booking_form'
import NewBookingFunnel from './src/pages/new_booking_funnel'
import NewBookingSuccess from './src/pages/new_booking_success'
import Offer from './src/pages/offer'
import AutomatedOfferRejected from './src/pages/automated_offer_rejected'
import Contracts from './src/pages/contracts'
import { SENTRY_NOT_ALLOWED_EVENTS_WITH_DATA } from './src/constants/sentry'
import { isDevMode } from './src/helpers/dev'
import { getEnvironmentVariable } from './src/constants/environment'

initializeFonts()

if (!isDevMode()) {
  Sentry.init({
    dsn: 'https://388f81964a5a4bc7873ef28c063a81d4@o59295.ingest.sentry.io/5742604',
    debug: getEnvironmentVariable('EXPO_PUBLIC_ENVIRONMENT') !== 'production',
    environment: getEnvironmentVariable('EXPO_PUBLIC_ENVIRONMENT'),
    beforeBreadcrumb: event => {
      if (SENTRY_NOT_ALLOWED_EVENTS_WITH_DATA.includes(event.message)) {
        delete event.data
      }
      return event
    }
  })
}

export default function App() {
  const [store] = useState(AppStore.create())
  const [ready, setReady] = useState(false)

  const initialize = async () => {
    await store.session.initialize()
    if (Platform.OS === 'web') {
      document.getElementById('splashscreen').style.opacity = '0'
    }
  }

  /* eslint-disable-next-line react/jsx-handler-names */
  if (!ready) return <AppLoading startAsync={initialize} onFinish={() => setReady(true)} onError={console.error} />

  return (
    <ResponsiveProvider>
      <AppStoreContext.Provider value={store}>
        <SafeAreaView style={{ flex: 1 }}>
          <ErrorBoundary>
            <Updates>
              <ApplicationRouter>
                <Switch>
                  <Route path='/facility_services' exact component={Index} />
                  <Route path='/facility_services/sign_in' exact component={SignIn} />
                  <Route path='/facility_services/contract_offer/:id/:token?' exact component={Offer} />
                  <Route path='/facility_services/contract/:id' component={ContractDetails} />
                  <Route path='/facility_services/schedule/:customerId?' component={Schedule} />
                  <Route path='/facility_services/contact_us/:eventId?' exact component={ContactUs} />
                  <Route path='/facility_services/invoices' component={Invoices} />
                  <Route path='/facility_services/account' component={AccountDetails} />
                  <Route path='/facility_services/new_booking' exact component={NewBooking} />
                  <Route path='/facility_services/new_booking/success' exact component={NewBookingSuccess} />
                  <Route path='/facility_services/new_booking/funnel/:service' component={NewBookingFunnel} />
                  <Route path='/facility_services/new_booking/:service' component={NewBookingForm} />
                  <Route path='/facility_services/onboarding/:id/:token?' component={Offer} />
                  <Route path='/facility_services/onboarding_offer/:id/:token?' exact component={Offer} />
                  <Route path='/facility_services/offer/:id/:token?' exact component={Offer} />
                  <Route path='/facility_services/offer_rejected' exact component={AutomatedOfferRejected} />
                  <Route path='/facility_services/contracts' component={Contracts} />
                  <Route path='/' exact render={() => <Redirect to='/facility_services' />} />
                </Switch>
              </ApplicationRouter>
            </Updates>
          </ErrorBoundary>
        </SafeAreaView>
      </AppStoreContext.Provider>
    </ResponsiveProvider>
  )
}
