import React from 'react'
import { TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import Text from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import styled from 'styled-components/native'
import ContentContainer from '../components/layout/content_container'
import { USER, PUBLIC } from '../constants/access'
import Funnel from '../components/new_booking/funnel'
import useQueryParams from '../helpers/hooks/use_query_params'
import { useNavigation } from '../helpers/navigation'
import { newBookingPath } from '../helpers/urls'

const Back = styled(TouchableOpacity)`
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
`

const BackText = styled(Text)`
  font-weight: bold;
  text-decoration: underline;
`

const NewBookingFunnel = ({
  match: {
    params: { service }
  }
}) => {
  const query = useQueryParams()
  const contractId = query.get('contractId')
  const token = query.get('token')

  const navigate = useNavigation()

  return (
    <ContentContainer access={contractId && token ? PUBLIC : USER}>
      <Back onPress={() => navigate(newBookingPath(contractId, token))}>
        <Icon name='arrow-left' set='MaterialCommunityIcons' size={16} />
        <BackText>{i18n.t('new_booking.browse')}</BackText>
      </Back>
      <Funnel service={service} />
    </ContentContainer>
  )
}

NewBookingFunnel.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      service: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

export default NewBookingFunnel
