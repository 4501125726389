import React, { useState } from 'react'
import PropTypes from 'lasagna/prop_types'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import { useResponsive } from 'lasagna/components/responsive'
import BaseText from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import { COLOR_BLACK } from 'lasagna/constants/colors'
import Chevron from '../chevron'
import { getRecurrence } from '../../helpers/contracts'
import ContractModel from '../../models/contracts'

const Container = styled(Card)`
  padding: 30px;
  margin: 16px 0;
`

const Text = styled(BaseText)`
  color: ${COLOR_BLACK};
`

const Title = styled(Text)`
  font-size: 20px;
  font-weight: 600;
`

const Toggle = styled(TouchableOpacity)`
  flex-direction: row;
  margin-top: 12px;
`

const ToggleText = styled(Text)`
  text-decoration: underline;
  font-weight: 600;
  font-size: 18px;
`

const Details = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ collapsed }) =>
    collapsed &&
    `
    overflow: hidden;
    height: 60px;
  `}
`

const Area = styled(View)`
  width: ${({ isDesktop }) => (isDesktop ? '48%;' : '100%')};
`

const AreaName = styled(Text)`
  font-weight: 600;
  margin: 12px 0;
`

const Row = styled(View)`
  flex-direction: row;
  margin-bottom: 10px;
`

const Task = styled(Text)`
  flex: 2;
`

const Recurrence = styled(BaseText)`
  flex: 1;
  margin-left: 12px;
`

const ServiceDetails = ({
  contract: {
    serviceDetail: { areas }
  }
}) => {
  const [open, setOpen] = useState(false)
  const { isDesktop } = useResponsive()
  return (
    <Container>
      <Title>{i18n.t('contracts.details.tabs.service_details')}</Title>
      <Details collapsed={!open}>
        {areas.map(({ id, name, standardTasks, additionalTasks }) => (
          <Area key={id} isDesktop={isDesktop}>
            <AreaName>{name}</AreaName>
            {standardTasks.map(({ id, name, recurrency }) => (
              <Row key={id}>
                <Task>-{name}</Task>
                {recurrency && <Recurrence>{getRecurrence(recurrency)}</Recurrence>}
              </Row>
            ))}
            {additionalTasks.map(({ id, name, recurrency }) => (
              <Row key={id}>
                <Task>-{name}</Task>
                {recurrency && <Recurrence>{getRecurrence(recurrency)}</Recurrence>}
              </Row>
            ))}
          </Area>
        ))}
      </Details>
      <Toggle onPress={() => setOpen(!open)}>
        <ToggleText>{open ? i18n.t('contract.details.view_less') : i18n.t('contract.details.view_more')}</ToggleText>
        <Chevron open={open} size={20} color={COLOR_BLACK} />
      </Toggle>
    </Container>
  )
}

ServiceDetails.propTypes = {
  contract: PropTypes.modelOf(ContractModel)
}

export default ServiceDetails
