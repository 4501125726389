import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { View, TouchableOpacity } from 'react-native'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import Icon from 'lasagna/components/icon'
import { useResponsive } from 'lasagna/components/responsive'
import styled from 'styled-components/native'
import { COLOR_TEXT_DARK, COLOR_HIGHLIGHT_YELLOW, COLOR_BLACK } from '../../constants/layout'
import { useStore } from '../../helpers/store'
import EditUserForm from './edit_user_form'

const DetailsCard = styled(Card)`
  padding: 28px 28px 18px;
`

const UsersContainer = styled(View)`
  ${({ isDesktop }) => isDesktop && 'width: 42%;'}
`

const Value = styled(Text)`
  color: ${COLOR_TEXT_DARK};
  margin-bottom: 12px;
`

const Label = styled(Value)`
  font-weight: 500;
  width: 70px;
  margin-right: 12px;
`

const Row = styled(View)`
  flex-direction: row;
`

const Header = styled(Row)`
  align-items: center;
  margin-bottom: 12px;
`

const Title = styled(Text)`
  color: ${COLOR_TEXT_DARK};
  font-weight: 400;
  font-size: 24px;
  font-weight: 600;
  margin: 40px 0 20px;
`

const IconContainer = styled(View)`
  background-color: ${COLOR_HIGHLIGHT_YELLOW};
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 12px;
`

const Name = styled(Text)`
  font-size: 20px;
  margin-bottom: 4px;
`

const Role = styled(Text)`
  font-weight: 600;
`

const HeaderDetails = styled(View)`
  flex-shrink: 1;
`

const EditAction = styled(TouchableOpacity)`
  flex-direction: row;
  border: 0px solid ${COLOR_BLACK};
  border-bottom-width: 2px;
  margin-top: 25px;
`

const EditActionText = styled(Text)`
  color: ${COLOR_BLACK};
  margin-right: 5px;
  font-weight: 600;
`

const UserCard = () => {
  const {
    session: {
      currentUser: { fullname, email, mobile, role }
    }
  } = useStore()
  const { isDesktop } = useResponsive()
  const [edit, setEdit] = useState(false)

  return (
    <UsersContainer isDesktop={isDesktop}>
      <Title>{i18n.t('account_details.section.user.title')}</Title>
      <DetailsCard>
        {edit ? (
          <EditUserForm onCancel={() => setEdit(false)} />
        ) : (
          <>
            <Header>
              <IconContainer>
                <Icon name='account-outline' size={32} set='MaterialCommunityIcons' color={COLOR_TEXT_DARK} />
              </IconContainer>
              <HeaderDetails>
                <Name>{fullname}</Name>
                <Role>{role}</Role>
              </HeaderDetails>
            </Header>
            <Row>
              <Label>{i18n.t('account_details.phone')}</Label>
              <Value>{mobile}</Value>
            </Row>
            <Row>
              <Label>{i18n.t('account_details.email')}</Label>
              <Value>{email}</Value>
            </Row>
            <Row style={{ justifyContent: 'flex-end' }}>
              <EditAction onPress={() => setEdit(true)}>
                <EditActionText>{i18n.t('account_details.actions.edit_button')}</EditActionText>
                <Icon name='edit' size={16} color={COLOR_BLACK} />
              </EditAction>
            </Row>
          </>
        )}
      </DetailsCard>
    </UsersContainer>
  )
}

export default observer(UserCard)
