import React from 'react'
import PropTypes from 'prop-types'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import Card from 'lasagna/components/layout/card'
import { COLOR_BACKGROUND_DARK, COLOR_WHITE } from '../../../constants/layout'

const Container = styled(View)`
  margin-bottom: 32px;
  width: 30%;
  align-items: center;
`

const Title = styled(Text)`
  font-size: 16px;
  font-weight: bold;
`

const TitleCard = styled(Card)`
  padding: 0;
  margin-bottom: 12px;
  width: 100%;
  border: 1px solid ${({ selected }) => (selected ? COLOR_BACKGROUND_DARK : COLOR_WHITE)};
`

const CardContent = styled(TouchableOpacity)`
  padding: 32px 12px;
  align-items: center;
  justify-content: center;
`

const Description = styled(Text)`
  font-weight: bold;
  text-align: center;
  margin: 8px 0;
`

const SelectedIcon = styled(Icon)`
  position: absolute;
  bottom: -12px;
  align-self: center;
  z-index: 1;
  background: ${COLOR_WHITE};
  border-radius: 100%;
`

const Package = ({ servicePackage: { type, employeeCount }, selectPackage, selected, serviceType }) => (
  <Container>
    <TitleCard selected={selected}>
      {selected && (
        <SelectedIcon name='check-circle' set='MaterialCommunityIcons' size={24} color={COLOR_BACKGROUND_DARK} />
      )}
      <CardContent onPress={selectPackage}>
        <Title>{i18n.t(`service_types.${serviceType}.types.${type}.title`)}</Title>
      </CardContent>
    </TitleCard>
    <Description>{i18n.t(`service_types.${serviceType}.types.${type}.description`)}</Description>
    <Text>{i18n.t('new_booking.funnel.employee_count', { count: employeeCount })}</Text>
  </Container>
)

Package.propTypes = {
  servicePackage: PropTypes.shape({
    type: PropTypes.string.isRequired,
    employeeCount: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired
  }),
  selected: PropTypes.bool,
  selectPackage: PropTypes.func,
  serviceType: PropTypes.string
}

export default Package
