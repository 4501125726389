import { getRoot } from 'mobx-state-tree'
import isPlainObject from 'lodash/isPlainObject'
import { debug } from '../helpers/sentry'
import * as sessionsApis from './session'
import * as contractsApis from './contracts'
import * as customersApis from './customers'
import * as eventsApis from './events'
import * as contactFormApis from './contact_form'
import * as invoicesApi from './invoices'
import * as accountApi from './account'
import * as verticalsApi from './verticals'

/**
 * This is a list of all available APIs merged together into one object.
 * Whenever you need to add a new file in src/api, don't forget to import it and put it here.
 */
const allApis = {
  ...sessionsApis,
  ...contractsApis,
  ...customersApis,
  ...eventsApis,
  ...contactFormApis,
  ...invoicesApi,
  ...accountApi,
  ...verticalsApi
}

/**
 * Helper function to normalize arguments passed to API call to be nicely formatted in Sentry
 * @param args
 * @returns {{arguments: *}}
 */
const sentryBreadcrumbData = args => {
  if (args.length === 0) {
    return
  }

  if (args.length === 1 && isPlainObject(args[0])) {
    return { arguments: args[0] }
  }

  return { arguments: args }
}

/**
 * An API composer - a function that takes Apollo client and creates a hash that contains all API functions with
 * the client being bound as the first parameter of the function.
 * It also includes a helpful debug() call that is being pushed to logs and sentry
 * @param {object} client Apollo client instance
 * @returns {object}
 */
export const apiComposer = client =>
  Object.entries(allApis).reduce((obj, [name, fn]) => {
    if (typeof fn === 'function') {
      obj[name] = (...args) => {
        debug('api', name, sentryBreadcrumbData(args))
        return fn(client, ...args)
      }
    }
    return obj
  }, {})

/**
 * Helper function to fetch composed APIs from SessionStore
 * @param self MST node (self from MST actions)
 * @returns {object}
 */
const api = self => {
  const { session } = getRoot(self)
  return session.apis
}

export default api
