import gql from 'graphql-tag'

export async function fetchAccount(client) {
  const query = gql`
    query facilityServiceAccount {
      facilityServices {
        account {
          id
          name
          address
          phone
          email
          managingDirector
          accountManager {
            name
            phone
          }
        }
      }
    }
  `

  return client.query({ query })
}
