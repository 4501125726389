import Constants from 'expo-constants'

export function getConfigVariable(name) {
  return (
    Constants.manifest2?.extra?.expoClient?.extra?.[name] || Constants.expoConfig?.extra?.[name] || process.env[name]
  )
}

export function getEnvironmentVariable(id) {
  const environmentVariables = {
    EXPO_PUBLIC_ANALYTICS: getConfigVariable('EXPO_PUBLIC_ANALYTICS'),
    EXPO_PUBLIC_API_HOSTNAMES: getConfigVariable('EXPO_PUBLIC_API_HOSTNAMES'),
    EXPO_PUBLIC_APP_HOSTNAMES: getConfigVariable('EXPO_PUBLIC_APP_HOSTNAMES'),
    EXPO_PUBLIC_LOCALES_HOSTNAMES: getConfigVariable('EXPO_PUBLIC_LOCALES_HOSTNAMES'),
    EXPO_PUBLIC_COUNTRY: getConfigVariable('EXPO_PUBLIC_COUNTRY'),
    EXPO_PUBLIC_API_AUTH: getConfigVariable('EXPO_PUBLIC_API_AUTH'),
    EXPO_PUBLIC_DEBUG: getConfigVariable('EXPO_PUBLIC_DEBUG')
  }

  return environmentVariables[id]
}
