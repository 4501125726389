import { RECURRENCY } from '../constants/contracts'

export const getRecurrence = recurrency => {
  if (recurrency === null) return i18n.t('contracts.details.service_overview.not_specified')
  if (recurrency === RECURRENCY.never || recurrency >= RECURRENCY.weekly) {
    return i18n.t('contracts.details.recurrency.week', { recurrency })
  }
  if (recurrency === RECURRENCY.monthly) return i18n.t('contracts.details.recurrency.month')
  if (recurrency === RECURRENCY.fortnightly) return i18n.t('contracts.details.recurrency.fortnight')
}
