import { types } from 'mobx-state-tree'
import { DateTime } from 'luxon'
import { COLLECTIVE, INDIVIDUAL, MATERIAL } from '../constants/invoice_types'

const Invoice = types
  .model('Invoice', {
    id: types.identifier,
    netAmount: types.number,
    balance: types.number,
    grossAmount: types.number,
    month: types.number,
    year: types.number,
    downloadEventsXlsxPath: types.maybeNull(types.string),
    downloadPdfPath: types.string,
    customerId: types.maybeNull(types.string),
    cancelled: types.boolean,
    cancellation: types.boolean,
    facilityAddress: types.maybeNull(types.string),
    legacy: types.boolean,
    kind: types.string,
    serviceType: types.maybeNull(types.string),
    serviceName: types.maybeNull(types.string)
  })
  .views(self => ({
    get invoiceDate() {
      const shortDate = DateTime.local(self.year, self.month)
      return `${shortDate.monthLong}, ${shortDate.year}`
    },
    get isCollective() {
      return self.kind === COLLECTIVE
    },
    get isIndividual() {
      return self.kind === INDIVIDUAL
    },
    get isMaterial() {
      return self.kind === MATERIAL
    }
  }))

export default Invoice
