import React from 'react'
import PropTypes from 'lasagna/prop_types'
import { View, Text } from 'react-native'
import Icon from 'lasagna/components/icon'
import styled from 'styled-components/native'
import { COLOR_HIGHLIGHT_GREEN, COLOR_LIGHT_ICON, COLOR_TEXT_PRIMARY, COLOR_BLACK } from '../../constants/layout'
import { useResponsive } from 'lasagna/components/responsive'

const BalanceAmount = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: bold;
  font-size: 14px;
  margin-left: 4px;
`

const Open = styled(Text)`
  color: ${COLOR_BLACK};
  font-size: 14px;
  margin-left: 14px;
`

const Container = styled(View)`
  flex-grow: 1;
  align-items: flex-start;
  ${props =>
    props.isDesktop
      ? ''
      : `
    width: 100%;
    border-top-width: 1px;
    border-top-color: ${COLOR_LIGHT_ICON};
    padding-top:12px;
  `}
`

const Label = styled(View)`
  align-items: center;
  border-radius: 15px;
  padding: 7px 28px;
`

const Paid = styled(View)`
  flex-direction: row;
  align-items: center;
`

const CancellationLabel = styled(Label)`
  background-color: ${COLOR_TEXT_PRIMARY};
`

const PaidText = styled(Text)`
  margin-left: 4px;
`

const Pending = styled(View)`
  flex-direction: row;
`

const InvoiceStatus = ({ balance, cancelled, cancellation }) => {
  const { isDesktop } = useResponsive()
  return (
    <Container isDesktop={isDesktop}>
      {cancelled && (
        <CancellationLabel>
          <PaidText>{i18n.t('invoices.cancelled')}</PaidText>
        </CancellationLabel>
      )}
      {cancellation && (
        <CancellationLabel>
          <PaidText>{i18n.t('invoices.cancellation')}</PaidText>
        </CancellationLabel>
      )}
      {balance === 0 && !cancellation && !cancelled && (
        <Paid testID='PaidStatus'>
          <Icon name='check-circle-outline' set='MaterialCommunityIcons' size={22} color={COLOR_HIGHLIGHT_GREEN} />
          <PaidText>{i18n.t('invoices.paid')}</PaidText>
        </Paid>
      )}
      {balance !== 0 && !cancellation && !cancelled && (
        <Pending testID='PendingStatus'>
          <BalanceAmount>{i18n.t('invoices.amount', { netAmount: balance })}</BalanceAmount>
          <Open>{i18n.t('invoices.status.open')}</Open>
        </Pending>
      )}
    </Container>
  )
}

InvoiceStatus.propTypes = {
  balance: PropTypes.number,
  cancelled: PropTypes.bool,
  cancellation: PropTypes.bool
}

export default InvoiceStatus
