import React from 'react'
import { observer } from 'mobx-react'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import { useResponsive } from 'lasagna/components/responsive'
import styled from 'styled-components/native'
import { COLOR_TEXT_DARK } from '../../constants/layout'
import { useStore } from '../../helpers/store'

const DetailsCard = styled(Card)`
  padding: 28px 28px 18px;
`

const AccountContainer = styled(View)`
  ${({ isDesktop }) => isDesktop && 'width: 55%;'}
`

const Value = styled(Text)`
  color: ${COLOR_TEXT_DARK};
  margin-bottom: 12px;
  flex-shrink: 1;
  overflow-wrap: anywhere;
`

const Label = styled(Text)`
  color: ${COLOR_TEXT_DARK};
  margin-bottom: 12px;
  font-weight: 500;
  flex: 0 0 125px;
  margin-right: 12px;
`

const Detail = styled(View)`
  flex-direction: row;
`

const Title = styled(Text)`
  color: ${COLOR_TEXT_DARK};
  font-weight: 400;
  font-size: 24px;
  font-weight: 600;
  margin: 40px 0 20px;
`

const AccountCard = () => {
  const {
    account: {
      account: { name, address, phone, email, managingDirector }
    }
  } = useStore()
  const { isDesktop } = useResponsive()

  return (
    <AccountContainer isDesktop={isDesktop}>
      <Title>{i18n.t('account_details.section.account.title')}</Title>
      <DetailsCard>
        <Detail>
          <Label>{i18n.t('account_details.section.account.name')}</Label>
          <Value>{name}</Value>
        </Detail>
        <Detail>
          <Label>{i18n.t('account_details.section.account.address')}</Label>
          <Value>{address}</Value>
        </Detail>
        <Detail>
          <Label>{i18n.t('account_details.section.account.phone')}</Label>
          <Value>{phone}</Value>
        </Detail>
        <Detail>
          <Label>{i18n.t('account_details.section.account.email')}</Label>
          <Value>{email}</Value>
        </Detail>
        <Detail>
          <Label>{i18n.t('account_details.section.account.managing_director')}</Label>
          <Value>{managingDirector}</Value>
        </Detail>
      </DetailsCard>
    </AccountContainer>
  )
}

export default observer(AccountCard)
