import React, { useEffect } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import { useStore } from '../../helpers/store'
import ActiveContracts from './active'
import Spinner from '../spinner'
import FacilityFilter from '../facility_filter'
import { useResponsive } from 'lasagna/components/responsive'

const Container = styled(View)`
  width: 100%;
  ${props => (props.isDesktop ? 'flex-direction: row;' : 'align-items: center;')}
`

const ListContainer = styled(View)`
  flex-grow: 1;
`

const Index = () => {
  const {
    pages: {
      contracts: {
        loading,
        activeContractsBySelectedCustomer: contracts,
        selectedCustomer,
        setSelectedCustomer,
        fetchFilteredActiveContracts,
        hasNextPage
      }
    }
  } = useStore()

  useEffect(() => {
    fetchFilteredActiveContracts()
  }, [])

  const { isDesktop } = useResponsive()

  return (
    <Container isDesktop={isDesktop}>
      <FacilityFilter selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} />
      <ListContainer>
        {(contracts?.length || !loading) && (
          <ActiveContracts
            contracts={contracts}
            selectedCustomer={selectedCustomer}
            showMore={hasNextPage && !loading}
            loadMore={fetchFilteredActiveContracts}
          />
        )}
        {loading && <Spinner />}
      </ListContainer>
    </Container>
  )
}

export default observer(Index)
