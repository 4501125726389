import React from 'react'
import PropTypes from 'lasagna/prop_types'
import styled from 'styled-components/native'
import { View } from 'react-native'
import { COLOR_TABLE_BORDER } from '../../constants/layout'

const RowContainer = styled(View)`
  flex-direction: row;
  border-bottom-color: ${COLOR_TABLE_BORDER};
  border-bottom-width: 1px;
`

const Row = ({ children }) => <RowContainer children={children} />

Row.propTypes = {
  children: PropTypes.node
}

export default Row
