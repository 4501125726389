import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import { COLOR_WHITE, COLOR_BLACK, COLOR_DARK_BORDER } from 'lasagna/constants/colors'

const Container = styled(View)`
  background: ${COLOR_WHITE};
  width: 100%;
  padding: 16px 20px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
`

const StepMarker = styled(View)`
  ${({ active }) =>
    active
      ? `
    background: ${COLOR_BLACK};
    border: 1px solid ${COLOR_BLACK};
  `
      : `
    background: ${COLOR_WHITE};
    border: 1px solid ${COLOR_DARK_BORDER}
  `}

  height: 25px;
  width: 25px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`

const StepNumber = styled(Text)`
  color: ${({ active }) => (active ? `${COLOR_WHITE}` : `${COLOR_DARK_BORDER}`)};
  line-height: 100%;
`

const Step = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 2;
`

const Separator = styled(View)`
  height: 1;
  border-radius: 1;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-bottom-color: ${COLOR_DARK_BORDER};
  margin: 10px;
  width: 30px;
  flex: 1;
`

const StepName = styled(Text)`
  color: ${({ active }) => (active ? `${COLOR_BLACK};` : `${COLOR_DARK_BORDER}`)};
  flex-wrap: wrap;
  flex-shrink: 1;
`

const StatusBar = () => {
  return (
    <Container>
      <Step>
        <StepMarker active>
          <StepNumber active>1</StepNumber>
        </StepMarker>
        <StepName active>{i18n.t('contracts.offer.steps.one')}</StepName>
      </Step>
      <Separator />
      <Step>
        <StepMarker>
          <StepNumber>2</StepNumber>
        </StepMarker>
        <StepName>{i18n.t('contracts.offer.steps.two')}</StepName>
      </Step>
      <Separator />
      <Step>
        <StepMarker>
          <StepNumber>3</StepNumber>
        </StepMarker>
        <StepName>{i18n.t('contracts.offer.steps.three')}</StepName>
      </Step>
    </Container>
  )
}

export default StatusBar
