import { types, flow } from 'mobx-state-tree'
import Account from '../models/account'
import api from '../api'

const AccountStore = types
  .model('AccountStore', {
    account: types.maybeNull(Account),
    loading: false
  })
  .actions(self => ({
    fetchAccount: flow(function* () {
      self.loading = true
      const {
        data: {
          facilityServices: { account }
        }
      } = yield api(self).fetchAccount()

      self.account = account
      self.loading = false
    })
  }))

export default AccountStore
