import React from 'react'
import ContentContainer from '../components/layout/content_container'
import Acknowledgment from '../components/contact_us/acknowledgment'
import { PUBLIC } from '../constants/access'

const NewBookingSuccess = () => (
  <ContentContainer access={PUBLIC}>
    <Acknowledgment />
  </ContentContainer>
)

export default NewBookingSuccess
