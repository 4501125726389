import React from 'react'
import { View, Image, Platform, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import Icon from 'lasagna/components/icon'
import { COLOR_TEXT_PRIMARY, COLOR_BLACK } from 'lasagna/constants/colors'
import { useResponsive } from 'lasagna/components/responsive'
import { useNavigation } from '../../helpers/navigation'
import { schedulePath } from '../../helpers/urls'

const Container = styled(Card)`
  padding: 30px;
  flex-direction: row;
`

const InfoIcon = styled(Image)`
  height: 40px;
  width: 40px;
  margin-right: 20px;
`

const Message = styled(Text)`
  margin-bottom: 12px;
  color: ${COLOR_TEXT_PRIMARY};
`

const Content = styled(View)`
  flex-shrink: 1;
`

const Cta = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
`

const CtaText = styled(Text)`
  text-decoration: underline;
  color: ${COLOR_BLACK};
  font-weight: 600;
  margin-right: 6px;
`

const ReportEvent = () => {
  const navigate = useNavigation()
  const goToSchedule = () => navigate(schedulePath())
  const { isDesktop } = useResponsive()

  return (
    <Container isDesktop={isDesktop} testID='ContactUsReportEventBanner'>
      <InfoIcon
        source={
          Platform.OS === 'web'
            ? require('../../assets/images/question.svg')
            : require('../../assets/images/question.png')
        }
      />
      <Content>
        <Message>{i18n.t('contact_form.report_event.message')}</Message>
        <Cta onPress={goToSchedule}>
          <CtaText>{i18n.t('contact_form.report_event.cta')}</CtaText>
          <Icon name='arrow-right-circle-outline' set='MaterialCommunityIcons' color={COLOR_BLACK} size={24} />
        </Cta>
      </Content>
    </Container>
  )
}

export default ReportEvent
