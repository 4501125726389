import React from 'react'
import { TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import Icon from 'lasagna/components/icon'
import { COLOR_BLACK } from '../../../constants/layout'
import { useStore } from '../../../helpers/store'

const Button = styled(TouchableOpacity)`
  width: 34px;
  height: 32px;
  border-width: 1px;
  border-color: ${COLOR_BLACK};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
`

const SidebarButton = () => {
  const {
    session: { toggleSidebar }
  } = useStore()

  return (
    <Button onPress={toggleSidebar}>
      <Icon name='menu' color={COLOR_BLACK} size={24} />
    </Button>
  )
}

export default SidebarButton
