import { Dimensions } from 'react-native'
import { useEffect, useState } from 'react'

// This has been backported from react-native-web 0.12 and should be removed once we upgrade to latest RNW
// @see https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/useWindowDimensions/index.js

export default function useWindowDimensions() {
  const [dims, setDims] = useState(() => Dimensions.get('window'))
  useEffect(() => {
    function handleChange({ window }) {
      // $FlowFixMe
      setDims(window)
    }
    Dimensions.addEventListener('change', handleChange)
    // We might have missed an update between calling `get` in render and
    // `addEventListener` in this handler, so we set it here. If there was
    // no change, React will filter out this update as a no-op.
    setDims(Dimensions.get('window'))
    return () => {
      Dimensions.removeEventListener('change', handleChange)
    }
  }, [])
  return dims
}
