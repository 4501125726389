import React from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { useDropdownMenu, useDropdownToggle } from 'react-overlays'
import Dropdown from 'react-overlays/Dropdown'
import Icon from 'lasagna/components/icon'
import { COLOR_BLACK } from 'lasagna/constants'
import AccountDropdownContent from './account_dropdown/content'

const DropdownContentContainer = styled('div')`
  position: absolute;
  z-index: 1000;
  display: ${props => (props.visible ? 'flex' : 'none')};
`

const DropdownButtonContainer = styled('div')`
  padding: 20px;
`

const DropdownContent = observer(() => {
  const { show, props } = useDropdownMenu({ flip: true })
  // eslint-disable-next-line no-unused-vars
  const [_props, { toggle }] = useDropdownToggle()

  return (
    <DropdownContentContainer {...props} visible={show}>
      <AccountDropdownContent />
    </DropdownContentContainer>
  )
})

const DropdownButton = observer(() => {
  const [props, { toggle }] = useDropdownToggle()

  return (
    <DropdownButtonContainer {...props} onClick={toggle}>
      <Icon name='account-circle' color={COLOR_BLACK} />
    </DropdownButtonContainer>
  )
})

const AccountDropdown = () => (
  <Dropdown alignEnd>
    {({ props }) => (
      <View {...props}>
        <DropdownButton />
        <DropdownContent role='menu' />
      </View>
    )}
  </Dropdown>
)

export default observer(AccountDropdown)
