import { types } from 'mobx-state-tree'

const Provider = types
  .model('Provider', {
    id: types.identifier,
    firstName: types.string,
    lastName: types.string
  })
  .views(self => ({
    get fullName() {
      return `${self.firstName} ${self.lastName}`
    }
  }))

export default Provider
