import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components/native'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import { useStore } from '../helpers/store'
import ContentContainer from '../components/layout/content_container'
import ContractsList from '../components/contracts'
import { COLOR_BLACK } from '../constants/layout'

const Title = styled(Text)`
  color: ${COLOR_BLACK};
  font-size: 24px;
  font-weight: 600;
  margin-right: 6px;
`

const Header = styled(View)`
  padding: 15px 0 30px;
`

const AccountName = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  color: ${COLOR_BLACK};
  margin-top: 8px;
`

const Contracts = () => {
  const {
    account: { account }
  } = useStore()

  return (
    <ContentContainer>
      <Header>
        <Title>{i18n.t('navigation.contracts')}</Title>
        <AccountName>{account?.name}</AccountName>
      </Header>
      <ContractsList />
    </ContentContainer>
  )
}

export default observer(Contracts)
