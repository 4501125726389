import { types } from 'mobx-state-tree'

const AnalyticsStore = types.model('AnalyticsStore', {}).actions(() => ({
  autoTrack(eventAction, eventLabel, event = 'autoTrack', eventCategory = 'H2FS Customer App') {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: event,
        eventCategory: eventCategory,
        eventAction,
        eventLabel
      })
    }
  }
}))

export default AnalyticsStore
