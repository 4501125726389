import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import Card from 'lasagna/components/layout/card'
import Text from 'lasagna/components/text'
import Row from '../table/row'
import Cell from '../table/cell'
import Chevron from '../chevron'
import { COLOR_TABLE_BORDER, COLOR_WHITE, COLOR_TEXT_SECONDARY } from '../../constants/layout'
import { WEEKDAYS, RECURRENCY } from '../../constants/contracts'

const Table = styled(View)`
  margin: 20px 12px;
  border-top-color: ${COLOR_TABLE_BORDER};
  border-top-width: 1px;
  border-left-color: ${COLOR_TABLE_BORDER};
  border-left-width: 1px;
`

const Recurrence = styled(Text)`
  padding: 10px 15px;
`

const AreaCard = styled(Card)`
  margin-top: 20px;
`

const Header = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
`

const AreaTitle = styled(Text)`
  font-weight: 600;
  font-size: 16px;
`

const AreaTasksTable = ({ title, tasks, showRecurrence }) => (
  <Table>
    <Row>
      <Cell header large>
        {title}
      </Cell>
      {showRecurrence && (
        <>
          <Cell header background={COLOR_WHITE}>
            {i18n.t('contracts.details.recurrency.title')}
          </Cell>
          {WEEKDAYS.map(day => (
            <Cell key={day} small header background={COLOR_WHITE}>
              {i18n.t(`contracts.details.schedule.${day}`)}
            </Cell>
          ))}
        </>
      )}
    </Row>
    {tasks.map(({ id, name, recurrency, ...scheduleDays }) => (
      <Row key={id}>
        <Cell large>{name}</Cell>
        {showRecurrence && (
          <>
            <Cell>
              <Recurrence>
                {recurrency === null && i18n.t('contracts.details.service_overview.not_specified')}
                {(recurrency === RECURRENCY.never || recurrency >= RECURRENCY.weekly) &&
                  i18n.t('contracts.details.recurrency.week', { recurrency })}
                {recurrency === RECURRENCY.monthly && i18n.t('contracts.details.recurrency.month')}
                {recurrency === RECURRENCY.fortnightly && i18n.t('contracts.details.recurrency.fortnight')}
              </Recurrence>
            </Cell>
            {WEEKDAYS.map(day => (
              <Cell key={day} small>
                {scheduleDays[day] ? 'X' : ''}
              </Cell>
            ))}
          </>
        )}
      </Row>
    ))}
  </Table>
)

AreaTasksTable.propTypes = {
  title: PropTypes.string,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      recurrency: PropTypes.number,
      monday: PropTypes.bool,
      tuesday: PropTypes.bool,
      wednesday: PropTypes.bool,
      thursday: PropTypes.bool,
      friday: PropTypes.bool,
      saturday: PropTypes.bool,
      sunday: PropTypes.bool
    })
  ),
  showRecurrence: PropTypes.bool
}

const Area = ({ title, standardTasks, additionalTasks, showRecurrence }) => {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <AreaCard>
      <Header onPress={() => setCollapsed(!collapsed)}>
        <AreaTitle>{i18n.t(`contracts.details.area_services.${title}`, { defaultValue: title })}</AreaTitle>
        <Chevron open={!collapsed} color={COLOR_TEXT_SECONDARY} size={24} />
      </Header>
      {!collapsed && (
        <>
          {!!standardTasks.length && (
            <AreaTasksTable
              title={i18n.t('contracts.details.area_services.standard_services')}
              tasks={standardTasks}
              showRecurrence={showRecurrence}
            />
          )}
          {!!additionalTasks.length && (
            <AreaTasksTable
              title={i18n.t('contracts.details.area_services.additional_services')}
              tasks={additionalTasks}
              showRecurrence={showRecurrence}
            />
          )}
        </>
      )}
    </AreaCard>
  )
}

Area.propTypes = {
  title: PropTypes.string,
  standardTasks: PropTypes.array,
  additionalTasks: PropTypes.array,
  showRecurrence: PropTypes.bool
}

const AreaServices = ({ areas, showRecurrence }) =>
  areas.map(({ name: title, standardTasks, additionalTasks }) => (
    <>
      {(!!standardTasks.length || !!additionalTasks.length) && (
        <Area
          title={title}
          standardTasks={standardTasks}
          additionalTasks={additionalTasks}
          showRecurrence={showRecurrence}
        />
      )}
    </>
  ))

AreaServices.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      standardTasks: PropTypes.array,
      additionalTasks: PropTypes.array
    })
  ),
  showRecurrence: PropTypes.bool
}

export default AreaServices
