import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import styled from 'styled-components/native'
import Card from 'lasagna/components/layout/card'
import Text from 'lasagna/components/text'
import Row from '../table/row'
import Cell from '../table/cell'
import { COLOR_TABLE_BORDER } from '../../constants/layout'
import { RECURRENCY } from '../../constants/contracts'

const Container = styled(Card)`
  margin: 40px 0 20px;
`

const Table = styled(View)`
  margin: 20px 12px;
  border-top-color: ${COLOR_TABLE_BORDER};
  border-top-width: 1px;
  border-left-color: ${COLOR_TABLE_BORDER};
  border-left-width: 1px;
`

const Recurrence = styled(Text)`
  padding: 10px 15px;
`

const ServiceOverview = ({ areas, showRecurrence }) => {
  const getValue = value => {
    if (value === null || value === '') return '-'
    return value
  }

  return (
    <Container>
      <Table>
        <Row>
          <Cell header>{i18n.t('contracts.details.service_overview.room_type')}</Cell>
          {showRecurrence && <Cell header>{i18n.t('contracts.details.recurrency.title')}</Cell>}
          <Cell header>{i18n.t('contracts.details.service_overview.room_size')}</Cell>
          <Cell header>{i18n.t('contracts.details.service_overview.room_count')}</Cell>
          <Cell header>{i18n.t('contracts.details.service_overview.floor_type')}</Cell>
        </Row>
        {areas.map(({ id, size, roomCount, name, recurrency, floorType }) => (
          <Row key={id}>
            <Cell>{name}</Cell>
            {showRecurrence && (
              <Cell>
                <Recurrence>
                  {recurrency === null && i18n.t('contracts.details.service_overview.not_specified')}
                  {(recurrency === RECURRENCY.never || recurrency >= RECURRENCY.weekly) &&
                    i18n.t('contracts.details.recurrency.week', { recurrency })}
                  {recurrency === RECURRENCY.monthly && i18n.t('contracts.details.recurrency.month')}
                  {recurrency === RECURRENCY.fortnightly && i18n.t('contracts.details.recurrency.fortnight')}
                </Recurrence>
              </Cell>
            )}
            <Cell>{getValue(size)}</Cell>
            <Cell>{getValue(roomCount)}</Cell>
            <Cell>{getValue(floorType)}</Cell>
          </Row>
        ))}
      </Table>
    </Container>
  )
}

ServiceOverview.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      size: PropTypes.number,
      roomCount: PropTypes.number,
      name: PropTypes.string,
      floorType: PropTypes.string,
      recurrency: PropTypes.number,
      showRecurrence: PropTypes.bool
    })
  ),
  showRecurrence: PropTypes.bool
}

export default ServiceOverview
