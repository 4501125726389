import React from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import Button from 'lasagna/components/button'
import { COLOR_WHITE } from 'lasagna/constants/colors'
import { COLOR_BACKGROUND_DARK } from '../../constants/layout'

const ReloadButton = styled(Button)`
  border-radius: 40px;
  width: 90%;
  margin: 15px auto;
  padding: 5px;
  border: 1px solid ${COLOR_WHITE};
`

const clearStorageAndRestart = () => {
  AsyncStorage.clear()
  window.location.reload()
}

const Buttons = ({ clearStorage }) => (
  <>
    <ReloadButton onPress={() => window.location.reload()} color={COLOR_BACKGROUND_DARK} textColor={COLOR_WHITE}>
      {i18n.t('ui.error.critical.restart')}
    </ReloadButton>

    {clearStorage && (
      <Button onPress={clearStorageAndRestart} color={COLOR_BACKGROUND_DARK} textColor={COLOR_WHITE}>
        Clear storage and restart
      </Button>
    )}
  </>
)

Buttons.propTypes = {
  clearStorage: PropTypes.bool.isRequired
}

export default Buttons
