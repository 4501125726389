import { View } from 'react-native'
import styled from 'styled-components/native'
import { COLOR_WHITE } from '../../../../constants/layout'

const SidebarContainer = styled(View)`
  background: ${COLOR_WHITE};
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`

export default SidebarContainer
