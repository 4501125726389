import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { View, TouchableOpacity, TextInput, Platform } from 'react-native'
import { useResponsive } from 'lasagna/components/responsive'
import BaseText from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import { COLOR_WHITE, COLOR_BLACK, COLOR_INPUT_LIGHT_BORDER } from 'lasagna/constants/colors'
import Modal from 'react-native-modal'
import ReasonPicker from '../picker'
import Button from '../button'
import { COLOR_ACTION, COLOR_HIGHLIGHT_RED, COLOR_LIGHT_ICON } from '../../constants/layout'
import { REJECTION_REASONS, OTHER_REASONS, CHANGE_REQUEST } from '../../constants/contract_rejection'
import { useNavigation } from '../../helpers/navigation'
import { automatedOfferRejectedPath } from '../../helpers/urls'
import { useStore } from '../../helpers/store'

const Container = styled(Modal)`
  ${({ isDesktop }) =>
    isDesktop ? 'justify-content: center; align-items: center;' : 'justify-content: flex-end; margin: 0;'}
`

const Content = styled(View)`
  background: ${COLOR_WHITE};
  ${({ isDesktop }) => (isDesktop ? 'width: 60%;' : 'border-top-left-radius: 30px; border-top-right-radius: 30px;')};
  padding: 50px 30px;
`

const Close = styled(TouchableOpacity)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const Text = styled(BaseText)`
  color: ${COLOR_BLACK};
  position: ${Platform.OS === 'web' ? 'static' : 'relative'};
`

const Title = styled(Text)`
  font-size: 20px;
  padding-bottom: 16px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLOR_LIGHT_ICON};
`

const PickerContainer = styled(View)`
  max-width: 350px;
  margin: 20px 0;
`

const Notes = styled(TextInput)`
  padding: 10px 16px;
  border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
  width: 100%;
  border-radius: 5px;
  margin-top: 12px;
  &:focus {
    box-shadow: none;
  }
`

const ActionButton = styled(Button)`
  width: 240px;
`

const ActionText = styled(Text)`
  color: ${({ disabled }) => (disabled ? COLOR_WHITE : COLOR_BLACK)};
  font-weight: 600;
  margin-left: 4px;
`

const Form = styled(View)`
  padding: 30px 0;
`

const Error = styled(Text)`
  margin-top: 12px;
  color: ${COLOR_HIGHLIGHT_RED};
`

const Required = styled(Text)`
  color: ${COLOR_HIGHLIGHT_RED};
`

const RejectModal = ({ handleClose, id, token }) => {
  const [reason, setReason] = useState()
  const [notes, setNotes] = useState('')
  const [showError, setShowError] = useState()
  const { isDesktop } = useResponsive()

  const {
    contracts: { rejectContractOffer },
    analytics: { autoTrack }
  } = useStore()

  const navigate = useNavigation()

  const rejectContract = async (reason, notes) => {
    const success = await rejectContractOffer(parseInt(id), token, reason, notes)
    if (success) {
      autoTrack('customer rejected', 'automated contracts')
      navigate(automatedOfferRejectedPath())
    } else {
      setShowError(true)
    }
  }

  const REASON_OPTIONS = REJECTION_REASONS.map(reason => ({
    key: reason,
    label: i18n.t(`contracts.offer.rejection.reason.${reason}`)
  }))

  const notesRequired = reason === OTHER_REASONS || reason === CHANGE_REQUEST

  const formValid = () => {
    if (!reason) return false
    if (notesRequired) return notes !== ''
    return true
  }

  return (
    <Container isVisible isDesktop={isDesktop}>
      <Content isDesktop={isDesktop} testID='OfferRejectModal'>
        <Close onPress={handleClose}>
          <Icon name='close' color={COLOR_LIGHT_ICON} />
        </Close>
        <Title>{i18n.t('contracts.offer.rejection.title')}</Title>
        <Form>
          <Text>
            {i18n.t('contracts.offer.rejection.pick_reason')} <Required>*</Required>
          </Text>
          <PickerContainer>
            <ReasonPicker
              selected={reason && i18n.t(`contracts.offer.rejection.reason.${reason}`)}
              onSelect={reason => setReason(reason)}
              options={REASON_OPTIONS}
              placeholder={i18n.t('contact_form.select_reason')}
            />
          </PickerContainer>
          <Text>
            {i18n.t('contact_form.message')} {notesRequired && <Required>*</Required>}
          </Text>
          <Notes value={notes} onChangeText={setNotes} multiline numberOfLines={8} />
        </Form>
        <ActionButton
          onPress={() => rejectContract(reason, notes)}
          color={COLOR_ACTION}
          textColor={COLOR_BLACK}
          borderColor={COLOR_ACTION}
          borderWidth={1}
          isDesktop={isDesktop}
          disabled={!formValid()}
          testID='OfferRejectButton'
        >
          <ActionText disabled={!formValid()}>{i18n.t('contracts.offer.reject_offer')}</ActionText>
        </ActionButton>
        {showError && <Error>{i18n.t('contact_form.error')}</Error>}
      </Content>
    </Container>
  )
}

RejectModal.propTypes = {
  handleClose: PropTypes.func,
  id: PropTypes.string,
  token: PropTypes.string
}

export default RejectModal
