import React from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react'
import styled from 'styled-components/native'
import PropTypes from 'lasagna/prop_types'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import { RECURRENCY } from '../../../constants/contracts'

const Container = styled(View)`
  padding-top: 12px;
`

const DetailCard = styled(Card)`
  margin-bottom: 8px;
`

const Row = styled(View)`
  flex-direction: row;
  margin-bottom: 8px;
`

const Label = styled(Text)`
  font-weight: 700;
  min-width: 120px;
  width: 120px;
`

const Value = styled(Text)`
  padding-left: 4px;
`

const AreaOverview = ({ areas }) => {
  return (
    <Container>
      {areas.map(({ id, size, roomCount, name, recurrency, floorType }) => (
        <DetailCard key={id}>
          <Row>
            <Label>{i18n.t('contracts.details.service_overview.room_type')}</Label>
            <Value>{name}</Value>
          </Row>
          <Row>
            <Label>{i18n.t('contracts.details.recurrency.title')}</Label>
            <Value>
              {recurrency >= RECURRENCY.weekly && i18n.t('contracts.details.recurrency.week', { recurrency })}
              {recurrency === RECURRENCY.monthly && i18n.t('contracts.details.recurrency.month')}
              {recurrency === RECURRENCY.fortnightly && i18n.t('contracts.details.recurrency.fortnight')}
            </Value>
          </Row>
          <Row>
            <Label>{i18n.t('contracts.details.service_overview.room_size')}</Label>
            <Value>{size}</Value>
          </Row>
          <Row>
            <Label>{i18n.t('contracts.details.service_overview.room_count')}</Label>
            <Value>{roomCount}</Value>
          </Row>
          <Row>
            <Label>{i18n.t('contracts.details.service_overview.floor_type')}</Label>
            <Value>{floorType}</Value>
          </Row>
        </DetailCard>
      ))}
    </Container>
  )
}

AreaOverview.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      size: PropTypes.number,
      roomCount: PropTypes.number,
      name: PropTypes.string,
      floorType: PropTypes.string,
      recurrency: PropTypes.number
    })
  )
}

export default observer(AreaOverview)
