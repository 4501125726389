import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import ContentContainer from '../components/layout/content_container'
import UserDetails from '../components/user_details'
import Spinner from '../components/spinner'
import { useStore } from '../helpers/store'

const AccountDetails = () => {
  const {
    account: { fetchAccount, loading }
  } = useStore()

  useEffect(() => {
    fetchAccount()
  }, [])
  return (
    <ContentContainer>
      {loading && <Spinner />}
      {!loading && <UserDetails />}
    </ContentContainer>
  )
}

export default observer(AccountDetails)
