import { types } from 'mobx-state-tree'
import SessionStore from './session'
import AnalyticsStore from './analytics'
import UpdatesStore from './updates'
import ContractsStore from './contracts'
import CustomersStore from './customers'
import AppointmentsStore from './appointments'
import ContactFormStore from './contact_form'
import InvoicesStore from './invoices'
import AccountStore from './account'
import ContractsPageStore from './pages/contracts'
import DashboardPageStore from './pages/dashboard'
import VerticalsStore from './verticals'
/**
 * Helper method to initialize a store that is empty by default
 */
const store = StoreType => types.optional(StoreType, () => StoreType.create({}))

/**
 * The main store of the application, contains all other stores
 */
const AppStore = types.model('AppStore', {
  session: store(SessionStore),
  analytics: store(AnalyticsStore),
  updates: store(UpdatesStore),
  contracts: store(ContractsStore),
  customers: store(CustomersStore),
  appointments: store(AppointmentsStore),
  contactForm: store(ContactFormStore),
  invoices: store(InvoicesStore),
  account: store(AccountStore),
  verticals: store(VerticalsStore),
  pages: store(
    types.model({
      contracts: store(ContractsPageStore),
      dashboard: store(DashboardPageStore)
    })
  )
})

export default AppStore
