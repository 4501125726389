import React from 'react'
import styled from 'styled-components/native'
import PropTypes from 'prop-types'
import { useResponsive } from 'lasagna/components/responsive'
import { COLOR_ACTION, COLOR_BLACK } from '../../constants/layout'
import Button from '../button'
import openExternal from '../../helpers/navigation/open_external'
import { pdfPath } from '../../helpers/urls'

const ContractButton = styled(Button)`
  margin: 6px;
  padding: 0 30px;
  ${props =>
    props.isDesktop
      ? `min-width: 320px;
    max-width: 400px;`
      : 'width: 100%;'}
`

const DownloadButton = ({ path }) => {
  if (!path) return null

  const { isDesktop } = useResponsive()
  const downloadContract = () => openExternal(pdfPath(path), true)

  return (
    <ContractButton
      textColor={COLOR_BLACK}
      onPress={downloadContract}
      color={COLOR_ACTION}
      borderColor={COLOR_ACTION}
      borderWidth={1}
      isDesktop={isDesktop}
    >
      {i18n.t('contracts.offer.download')}
    </ContractButton>
  )
}

DownloadButton.propTypes = {
  path: PropTypes.string
}

export default DownloadButton
