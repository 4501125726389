import { types } from 'mobx-state-tree'

const Customer = types.model('Customer', {
  id: types.identifier,
  name: types.string,
  address: types.maybeNull(types.string),
  facilityType: types.maybeNull(types.string),
  facilityTypeNote: types.maybeNull(types.string)
})

export default Customer
