import gql from 'graphql-tag'
import { ASCENDING } from '../constants/events'

export async function fetchEvents(
  client,
  { id, customerId, beforeDateTime, afterDateTime, after, order = ASCENDING, first = 10 }
) {
  const query = gql`
    query events(
      $id: Int
      $customerId: Int
      $beforeDateTime: DateTime
      $afterDateTime: DateTime
      $after: String
      $order: OrderDirection
      $first: Int
    ) {
      facilityServices {
        events(
          id: $id
          customerId: $customerId
          beforeDateTime: $beforeDateTime
          afterDateTime: $afterDateTime
          first: $first
          after: $after
          order: $order
        ) {
          edges {
            node {
              id
              facility {
                id
                serviceAddress {
                  address
                }
              }
              customerContract {
                serviceType
                serviceName
              }
              startTime
              cancelled
              extra
              confirmed
              provider {
                id
                firstName
                lastName
              }
              canRequestReschedule
              reschedulingRequestPending
              isBankHoliday
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  `

  return client.query({ query, variables: { id, customerId, beforeDateTime, afterDateTime, after, order, first } })
}

export function requestReschedule(client, { eventId, notes, startTimes, reason }) {
  const mutation = gql`
    mutation requestFacilityServicesEventRescheduling(
      $eventId: ID!
      $notes: String
      $startTimes: [DateTime!]!
      $reason: String!
    ) {
      requestFacilityServicesEventRescheduling(
        eventId: $eventId
        notes: $notes
        startTimes: $startTimes
        reason: $reason
      ) {
        success
        errors {
          code
          message
        }
      }
    }
  `
  return client.mutate({ mutation, variables: { eventId, notes, startTimes, reason } })
}

export function requestCancellation(client, { eventId, reason, notes }) {
  const mutation = gql`
    mutation requestFacilityServicesEventCancellation($eventId: ID!, $reason: String, $notes: String) {
      requestFacilityServicesEventCancellation(eventId: $eventId, reason: $reason, notes: $notes) {
        success
      }
    }
  `
  return client.mutate({ mutation, variables: { eventId, reason, notes } })
}
