import { types } from 'mobx-state-tree'

const Dot = types.model('Dot', {
  color: types.string
})

const CalendarUiEvent = types.model('CalendarUiEvent', {
  date: types.identifier,
  dots: types.array(Dot)
})

export default CalendarUiEvent
