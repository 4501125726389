import { types } from 'mobx-state-tree'

const Week = types.model('Week', {
  frequency: types.enumeration(['once', 'week', 'fortnight', 'monthly']),
  monday: types.boolean,
  tuesday: types.boolean,
  wednesday: types.boolean,
  thursday: types.boolean,
  friday: types.boolean,
  saturday: types.boolean,
  sunday: types.boolean
})

const Schedule = types.model('Schedule', {
  weeks: types.array(Week)
})

export default Schedule
