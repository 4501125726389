import React from 'react'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import { useResponsive } from 'lasagna/components/responsive'
import styled from 'styled-components/native'
import { COLOR_BLACK } from '../../constants/layout'

const Description = styled(Text)`
  color: ${COLOR_BLACK};
  ${({ isDesktop }) =>
    isDesktop
      ? `
      font-size: 18px;
      line-height: 24px;
    `
      : `
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 20px;
    `}
`

const Title = styled(({ ...props }) => <Text {...props} />)`
  color: ${COLOR_BLACK};
  ${({ isDesktop }) =>
    isDesktop
      ? `
      font-size: 38px;
      line-height: 44px;
      margin: 0 0 20px;
    `
      : `
      font-size: 20px;
      line-height: 24px;
      margin: 20px 0 10px;
    `}
`

const Info = styled(({ ...props }) => <View {...props} />)`
  ${({ isDesktop }) =>
    isDesktop &&
    `
      flex: 3;
      padding: 50px;
      max-width: 600px;
    `}
`

const SignInInfo = () => {
  const { isDesktop } = useResponsive()

  return (
    <Info isDesktop={isDesktop}>
      <Title isDesktop={isDesktop}>{i18n.t('sign_in.welcome')}</Title>
      <Description isDesktop={isDesktop}>{i18n.t('sign_in.message')}</Description>
    </Info>
  )
}

export default SignInInfo
