import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import { useNavigation } from '../../helpers/navigation'
import { COLOR_BLACK } from '../../constants/layout'
import { homePagePath } from '../../helpers/urls'

const BackContainer = styled(View)`
  align-items: flex-start;
`

const Back = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
`

const BackText = styled(Text)`
  color: ${COLOR_BLACK};
  text-decoration: underline;
`

const OverviewLink = () => {
  const navigate = useNavigation()

  return (
    <BackContainer>
      <Back
        onPress={() => {
          navigate(homePagePath())
        }}
      >
        <Icon set='MaterialCommunityIcons' name='arrow-left' size={18} />
        <BackText>{i18n.t('contracts.offer.confirmation.back')}</BackText>
      </Back>
    </BackContainer>
  )
}

export default OverviewLink
