import { types, flow } from 'mobx-state-tree'
import Invoice from '../models/invoice'
import Customer from '../models/customer'
import api from '../api'

const InvoicesStore = types
  .model('InvoicesStore', {
    collection: types.map(Invoice),
    loading: types.optional(types.boolean, false),
    endCursor: types.maybeNull(types.string),
    hasNextPage: types.optional(types.boolean, false),
    selectedCustomer: types.maybeNull(types.reference(Customer))
  })
  .views(self => ({
    get invoicesArray() {
      return Array.from(self.collection.values())
    },
    get invoices() {
      return self.invoicesArray
    }
  }))
  .actions(self => ({
    fetchInvoices: flow(function* () {
      self.loading = true

      const {
        data: {
          facilityServices: {
            customerInvoices: {
              edges,
              pageInfo: { hasNextPage, endCursor }
            }
          }
        }
      } = yield api(self).fetchInvoices({
        customerId: self.selectedCustomer ? parseInt(self.selectedCustomer.id) : null,
        first: 10,
        after: self.endCursor
      })

      const invoices = edges.map(invoice => self.put(invoice.node))

      self.endCursor = endCursor
      self.hasNextPage = hasNextPage
      self.loading = false

      return invoices
    }),
    setSelectedCustomer: id => {
      self.selectedCustomer = id
      self.endCursor = null
      self.hasNextPage = false
      self.collection.clear()
      self.fetchInvoices()
    },
    put(data) {
      const { netAmount, grossAmount, balance, customerContract, ...rest } = data
      return self.collection.put({
        netAmount: netAmount / 100,
        grossAmount: grossAmount / 100,
        balance: balance / 100,
        customerId: customerContract?.facility?.id,
        serviceType: customerContract?.serviceType,
        serviceName: customerContract?.serviceName,
        ...rest
      })
    }
  }))

export default InvoicesStore
