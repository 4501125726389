import React, { useEffect } from 'react'
import { View, TouchableOpacity } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import { useStore } from '../../helpers/store'
import { useNavigation } from '../../helpers/navigation'
import ContentContainer from '../layout/content_container'
import ContractOffers from '../contracts/offers'
import ActiveContracts from '../contracts/active'
import InvoiceList from '../invoices/list'
import StackedInvoices from '../invoices/stacked'
import Spinner from '../spinner'
import AccountManager from '../account_manager'
import NewBooking from './new_booking'
import { COLOR_BLACK, COLOR_LIGHT_ICON } from '../../constants/layout'
import { useResponsive } from 'lasagna/components/responsive'
import { contractsPath, invoicesPath, newBookingPath } from '../../helpers/urls'

const Title = styled(Text)`
  font-size: 20px;
  color: ${COLOR_BLACK};
  text-align: center;
`

const Link = styled(Text)`
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  font-size: 16px;
  color: ${COLOR_BLACK};
  text-decoration: underline;
  font-weight: 600;
`

const Header = styled(View)`
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
`

const Separator = styled(View)`
  margin: 24px 0;
  border-top-width: 1px;
  border-top-color: ${COLOR_LIGHT_ICON};
`

const Index = () => {
  const {
    pages: {
      dashboard: { latestActiveContracts, latestInvoice, fetch, loading, activeContractsCount }
    },
    account: { account }
  } = useStore()

  useEffect(() => {
    fetch()
  }, [])

  const { isDesktop } = useResponsive()

  const navigate = useNavigation()

  return (
    <ContentContainer testID='Dashboard'>
      {loading && <Spinner />}
      {!loading && (
        <>
          <Header>
            <Title>{i18n.t('dashboard.new_booking.title')}</Title>
            {isDesktop && (
              <TouchableOpacity onPress={() => navigate(newBookingPath())}>
                <Link marginLeft={30}>{i18n.t('new_booking.browse')}</Link>
              </TouchableOpacity>
            )}
          </Header>
          <NewBooking />
          {!isDesktop && (
            <TouchableOpacity onPress={() => navigate(newBookingPath())}>
              <Link>{i18n.t('new_booking.browse')}</Link>
            </TouchableOpacity>
          )}
          <Separator />
          {account?.accountManager && (
            <>
              <AccountManager />
              <Separator />
            </>
          )}
          <ContractOffers />
          <Header>
            <Title>{i18n.t('invoices.latest')}</Title>
            <TouchableOpacity onPress={() => navigate(invoicesPath())}>
              <Link>{i18n.t('invoices.view_all')}</Link>
            </TouchableOpacity>
          </Header>
          {latestInvoice && !!latestInvoice.length && isDesktop && <InvoiceList invoices={latestInvoice} />}
          {latestInvoice && !!latestInvoice.length && !isDesktop && <StackedInvoices invoices={latestInvoice} />}
          {!latestInvoice.length && <Text>{i18n.t('invoices.empty')}</Text>}
          <Separator />
          <Header>
            <Title>{i18n.t('navigation.contracts')}</Title>
            <TouchableOpacity onPress={() => navigate(contractsPath())}>
              <Link>
                {i18n.t('contracts.view_all')}({activeContractsCount})
              </Link>
            </TouchableOpacity>
          </Header>
          <ActiveContracts contracts={latestActiveContracts} />
        </>
      )}
    </ContentContainer>
  )
}

export default observer(Index)
