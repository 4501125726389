import { types, flow, getRoot } from 'mobx-state-tree'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import CurrentUser from '../models/current_user'
import api, { apiComposer } from '../api'
import createApolloClient from '../api/utils/create_apollo_client'
import { getAccessToken } from '../api/auth'
import countryConfig from '../constants/country_config'
import { urlToWww } from '../helpers/urls'
import Sentry from '../helpers/sentry'
import i18nStore from './session/i18n'
import { getEnvironmentVariable } from '../constants/environment'

const SessionStore = types
  .model('SessionStore', {
    i18n: types.optional(i18nStore, () => ({})),
    currentUser: types.optional(types.union(CurrentUser, types.null, types.undefined), undefined),
    country: types.optional(types.string, getEnvironmentVariable('EXPO_PUBLIC_COUNTRY')),
    sidebar: types.optional(types.boolean, false),
    locale: types.maybe(types.string)
  })
  .volatile(self => {
    const apolloClient = createApolloClient({
      getApiUrl: () => self.apiUrl
    })

    return {
      apolloClient,
      apis: apiComposer(apolloClient)
    }
  })
  .views(self => ({
    get countryConfig() {
      return countryConfig[self.country] || {}
    },
    get apiUrl() {
      return urlToWww('/api/v2/rr', self.country)
    },
    get isSignedIn() {
      return !!self.currentUser
    }
  }))
  .actions(self => ({
    initialize: flow(function* () {
      window.i18n = self.i18n
      window.I18n = window.i18n // lasagna compatibility
      window.country = self.country

      const currentLocale = (yield AsyncStorage.getItem('locale')) || self.countryConfig.locales[0]
      yield self.i18n.update(self.country, self.countryConfig.locales, currentLocale)
      self.locale = currentLocale

      const { fetchCurrentUser } = api(self)
      const {
        customers: { fetchCustomers }
      } = getRoot(self)
      const {
        account: { fetchAccount }
      } = getRoot(self)

      const [
        {
          data: { currentUser }
        }
      ] = yield Promise.all([fetchCurrentUser(), fetchCustomers(), fetchAccount()])

      if (currentUser) {
        self.currentUser = { ...currentUser, id: String(currentUser.id) }
        Sentry.setUser({ id: currentUser.id })
      } else {
        self.currentUser = null
      }

      if (Platform.OS !== 'web') {
        getRoot(self).updates.checkAndFetchUpdate()
      }
    }),
    signIn: flow(function* (email, password) {
      try {
        let success
        if (Platform.OS === 'web') {
          success = yield self.apis.signInWeb(email, password)
        } else {
          const { accessToken, refreshToken } = yield getAccessToken(email, password)
          yield AsyncStorage.multiSet([
            ['accessToken', accessToken],
            ['refreshToken', refreshToken]
          ])
          success = true
        }

        if (success) {
          const { data } = yield self.apis.fetchCurrentUser()
          self.setCurrentUser(data.currentUser)

          yield self.afterSignIn()
        }

        return success
      } catch (error) {
        if (window.console) {
          console.log(error)
        }
        self.clear()
        return false
      }
    }),
    signOut: flow(function* () {
      if (Platform.OS === 'web') {
        yield api(self).logoutCurrentUser()
      } else {
        yield AsyncStorage.clear()
      }
      self.clear()
    }),
    setCurrentUser: data => {
      if (data) {
        const { id, ...rest } = data
        const user = {
          ...rest,
          id: String(id)
        }
        self.currentUser = user
      }
    },
    clear: () => {
      self.currentUser = undefined
    },
    afterSignIn: flow(function* () {
      const { currentUser } = self
      if (currentUser) {
        Sentry.setUser({ id: currentUser.id })
        getRoot(self).analytics.autoTrack('Customer Logs In', 'to app')
      }
    }),
    openSidebar: () => (self.sidebar = true),
    closeSidebar: () => (self.sidebar = false),
    toggleSidebar: () => (self.sidebar = !self.sidebar),
    updateLocale: locale => {
      self.i18n.setLocale(locale)
      self.locale = locale
    },
    updateUserContactDetails: flow(function* (firstname, lastname, phone, email) {
      try {
        const {
          data: {
            updateFacilityServicesCustomerContactDetails: { errors, success }
          }
        } = yield api(self).updateUserContactDetails({ firstname, lastname, phone, email })
        if (success) {
          const {
            data: { currentUser }
          } = yield api(self).fetchCurrentUser()
          self.setCurrentUser(currentUser)
        }

        return {
          errors: errors?.reduce((acc, error) => {
            if (error.code === '001') {
              acc.emailAlreadyExist = true
            } else if (error.code === '002') {
              acc.invalidEmail = true
            } else {
              acc.unknownError = true
            }

            return acc
          }, {})
        }
      } catch (error) {
        return {
          errors: {
            unknownError: true
          }
        }
      }
    })
  }))

export default SessionStore
