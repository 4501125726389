import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import Icon from 'lasagna/components/icon'
import { COLOR_WHITE } from 'lasagna/constants'

const Chevron = styled(Icon).attrs(props => ({
  color: props.color || COLOR_WHITE,
  size: props.size || 16,
  set: 'MaterialCommunityIcons',
  name: props.open ? 'chevron-up' : 'chevron-down'
}))`
  margin-left: 5px;
  margin-top: 1px;
`

Chevron.propTypes = {
  open: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.number
}

export default Chevron
