import { types } from 'mobx-state-tree'

const CurrentUser = types
  .model('CurrentUser', {
    id: types.identifier,
    email: types.string,
    firstname: types.string,
    lastname: types.string,
    mobile: types.string,
    role: types.maybeNull(types.string)
  })
  .views(self => ({
    get fullname() {
      return `${self.firstname} ${self.lastname}`
    }
  }))

export default CurrentUser
