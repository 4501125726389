import React from 'react'
import { TouchableOpacity, Image, Platform } from 'react-native'
import PropTypes from 'lasagna/prop_types'
import Text from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import Card from 'lasagna/components/layout/card'
import styled from 'styled-components/native'
import { useNavigation } from '../../helpers/navigation'
import { newBookingFormPath, newBookingFunnelPath } from '../../helpers/urls'
import { SERVICE_TYPES } from '../../constants/service_types'
import { COLOR_TEXT_SECONDARY, COLOR_TEXT_LIGHT, COLOR_HIGHLIGHT_BLUE } from '../../constants/layout'
import { useResponsive } from 'lasagna/components/responsive'

const Container = styled(Card)`
  width: ${({ highlighted, desktop }) => (highlighted || !desktop ? '49%' : '22%')};
  padding: 0;
  margin-bottom: 24px;
`

const Content = styled(TouchableOpacity)`
  padding: 24px;
`

const ServiceIcon = styled(Image)`
  height: ${({ highlighted }) => (highlighted ? '120px' : '100px')};
  width: ${({ highlighted }) => (highlighted ? '120px' : '100%')};
`

const Title = styled(Text)`
  font-weight: bold;
  margin-top: ${({ highlighted }) => (highlighted ? '16px' : '6px')};
  ${({ highlighted }) => !highlighted && 'text-align: center;'};
`

const Description = styled(Text)`
  line-height: 150%;
  margin-bottom: 8px;
  margin-top: 4px;
`

const Active = styled(Text)`
  text-align: center;
  align-self: center;
  background: ${COLOR_TEXT_LIGHT};
  border-radius: 20px;
  color: ${COLOR_HIGHLIGHT_BLUE};
  font-weight: bold;
  padding: 6px 24px;
  line-height: 100%;
  font-size: 14px;
  margin-top: 12px;
`

const ServiceCard = ({ serviceType, highlighted, active, contractId, token }) => {
  const navigate = useNavigation()
  const { isDesktop } = useResponsive()

  const goToBookingForm = () => {
    const path = SERVICE_TYPES[serviceType]?.hasBookingFunnel
      ? newBookingFunnelPath(serviceType, contractId, token)
      : newBookingFormPath(serviceType, contractId, token)
    navigate(path)
  }

  return (
    <Container highlighted={highlighted} desktop={isDesktop}>
      <Content testID='NewBookingServiceCard' onPress={goToBookingForm}>
        {SERVICE_TYPES[serviceType]?.icon && (
          <ServiceIcon
            testID='NewBookingServiceCardIcon'
            highlighted={highlighted}
            resizeMode='contain'
            source={
              Platform.OS === 'web'
                ? require(`../../${SERVICE_TYPES[serviceType].icon}.svg`)
                : require(`../../${SERVICE_TYPES[serviceType].icon}.png`)
            }
          />
        )}
        <Title highlighted={highlighted}>{i18n.t(`service_types.${serviceType}.title`)}</Title>
        {highlighted && (
          <>
            <Description testID='NewBookingServiceCardDescription' numberOfLines={3}>
              {i18n.t(`service_types.${serviceType}.description_short`)}
            </Description>
            <Icon name='arrow-right' set='MaterialCommunityIcons' size={20} color={COLOR_TEXT_SECONDARY} />
          </>
        )}
        {active && <Active testID='NewBookingServiceCardActive'>{i18n.t('new_booking.active')}</Active>}
      </Content>
    </Container>
  )
}

ServiceCard.propTypes = {
  serviceType: PropTypes.string,
  highlighted: PropTypes.bool,
  active: PropTypes.bool,
  contractId: PropTypes.string,
  token: PropTypes.string
}

export default ServiceCard
