import React from 'react'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Card from 'lasagna/components/layout/card'
import { useResponsive } from 'lasagna/components/responsive'
import { COLOR_TEXT_DARK } from '../../constants/layout'

const Header = styled(View)`
  ${props => props.isDesktop && 'flex-direction: row;'}
  margin: 30px 20px 12px 10px;
  align-items: center;
  justify-content: space-between;
`

const SectionTitle = styled(Text)`
  font-size: 20px;
  color: ${COLOR_TEXT_DARK};
  font-weight: 600;
`

const Description = styled(Card)`
  padding: 24px;
`

const AdditionalAgreements = ({ agreements }) => {
  const { isDesktop } = useResponsive()
  return (
    <>
      <Header isDesktop={isDesktop}>
        <SectionTitle>{i18n.t('contracts.details.additional_agreements')}</SectionTitle>
      </Header>
      <Description>
        <Text>{agreements}</Text>
      </Description>
    </>
  )
}

AdditionalAgreements.propTypes = {
  agreements: PropTypes.string
}

export default observer(AdditionalAgreements)
