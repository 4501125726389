import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import Card from 'lasagna/components/layout/card'
import styled from 'styled-components/native'
import Icon from 'lasagna/components/icon'
import { COLOR_WHITE } from 'lasagna/constants/colors'
import PropTypes from 'lasagna/prop_types'
import { useNavigation } from '../../helpers/navigation'
import { newBookingFormPath } from '../../helpers/urls'
import { COLOR_BACKGROUND_DARK, COLOR_TEXT_SECONDARY } from '../../constants/layout'
import { GENERIC_SERVICE } from '../../constants/service_types'
import { useResponsive } from 'lasagna/components/responsive'

const Container = styled(Card)`
  padding: 20px;
`
const Content = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Row = styled(View)`
  display: flex;
  flex-direction: row;
  flex: 1;
`
const IconContainer = styled(View)`
  background-color: ${COLOR_BACKGROUND_DARK};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 5px;
`
const Info = styled(View)`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  justify-content: center;
`
const QuestionText = styled(Text)`
  color: ${COLOR_TEXT_SECONDARY};
  width: ${({ desktop }) => (desktop ? '100%' : '80%')};
`
const SuggestText = styled(Text)`
  font-weight: 700;
  margin-top: 2px;
`

function SuggestNewServiceCard({ contractId, token }) {
  const navigate = useNavigation()
  const { isDesktop } = useResponsive()

  return (
    <Container>
      <Content onPress={() => navigate(newBookingFormPath(GENERIC_SERVICE, contractId, token))}>
        <Row>
          <IconContainer>
            <Icon name='add' size={14} color={COLOR_WHITE} />
          </IconContainer>
          <Info>
            <QuestionText desktop={isDesktop}>{i18n.t('new_booking.generic_service_card.question')}</QuestionText>
            <SuggestText>{i18n.t('new_booking.generic_service_card.suggest')}</SuggestText>
          </Info>
        </Row>

        <Icon name='arrow-right' set='MaterialCommunityIcons' size={20} color={COLOR_TEXT_SECONDARY} />
      </Content>
    </Container>
  )
}

SuggestNewServiceCard.propTypes = {
  contractId: PropTypes.string,
  token: PropTypes.string
}

export default SuggestNewServiceCard
