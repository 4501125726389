import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components/native'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import Icon from 'lasagna/components/icon'
import { useResponsive } from 'lasagna/components/responsive'
import { Calendar } from 'react-native-calendars'
import AppointmentsList from './selected_date_list'
import FacilityFilter from '../facility_filter'
import { useStore } from '../../helpers/store'
import { useNavigation } from '../../helpers/navigation'
import { COLOR_LIGHT_ICON, COLOR_ACTION_LIGHT, COLOR_BLACK } from '../../constants/layout'
import { contactUsEventPath } from '../../helpers/urls'

const ARROWS = {
  left: <Icon name='keyboard-arrow-left' color={COLOR_LIGHT_ICON} />,
  right: <Icon name='keyboard-arrow-right' color={COLOR_LIGHT_ICON} />
}

const Events = styled(View)`
  margin-left: 16px;
  width: 300px;
`

const ScheduleDetails = styled(View)`
  width: 100%;
  ${props => (props.isDesktop ? 'flex-direction: row;' : 'align-items: center;')}
`

const CalendarContainer = styled(Card)`
  flex-grow: 1;
`

const Day = styled(Text)`
  font-size: 16px;
  color: ${COLOR_BLACK};
  margin-bottom: 4px;
`

const EmptyEvents = styled(Text)`
  padding: 10px 0;
`

const SelectedDay = styled(Card)`
  margin-bottom: 10px
  padding: 12px 20px;
`

const ListContainer = styled(View)`
  max-height: 450px;
  overflow: scroll;
  margin-bottom: 18px;
`

const CancellationWarning = styled(Card).attrs(() => ({ elevation: 1 }))``

const CancellationWarningText = styled(Text)`
  text-align: justify;
  padding: 10px;
`

const ScheduleCalendar = () => {
  const {
    appointments: {
      fetchAppointmentsForMonth,
      selectedDate,
      selectDate,
      formattedSelectedDate,
      selectedDateAppointments,
      calendarUiEvents,
      selectedCustomer,
      setSelectedCustomer
    },
    customers: { customers }
  } = useStore()

  useEffect(() => {
    fetchAppointmentsForMonth()
  }, [])

  const navigate = useNavigation()

  const uiEvents = calendarUiEvents.toJSON()

  const { isDesktop } = useResponsive()

  const reportIssue = eventId => navigate(contactUsEventPath(eventId))

  return (
    <ScheduleDetails isDesktop={isDesktop} testID='ScheduleCalendar'>
      {customers.length > 1 && (
        <FacilityFilter selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} />
      )}
      <CalendarContainer>
        <Calendar
          renderArrow={direction => ARROWS[direction]}
          markingType='multi-dot'
          markedDates={{
            ...uiEvents,
            [selectedDate]: {
              ...uiEvents[selectedDate],
              selected: true
            }
          }}
          onMonthChange={month => {
            fetchAppointmentsForMonth(month.year, month.month)
          }}
          onDayPress={day => selectDate(day.dateString)}
          theme={{
            selectedDayBackgroundColor: COLOR_ACTION_LIGHT,
            selectedDayTextColor: COLOR_BLACK
          }}
        />
      </CalendarContainer>
      <Events elevation={1} isDesktop={isDesktop}>
        <SelectedDay>
          <Day>{formattedSelectedDate}</Day>
          <Text>
            ({selectedDateAppointments.length} {i18n.t('schedule.selected_date.events')})
          </Text>
        </SelectedDay>
        {!selectedDateAppointments.length && <EmptyEvents>{i18n.t('schedule.selected_date.empty')}</EmptyEvents>}
        <ListContainer>
          {selectedDateAppointments.map((appointment, index) => (
            <AppointmentsList
              key={appointment.id}
              reportIssue={reportIssue}
              appointment={appointment}
              last={index === selectedDateAppointments.length - 1}
            />
          ))}
        </ListContainer>
        {!!selectedDateAppointments.length && (
          <CancellationWarning>
            <CancellationWarningText>{i18n.t('schedule.cancellation_warning')}</CancellationWarningText>
          </CancellationWarning>
        )}
      </Events>
    </ScheduleDetails>
  )
}

export default observer(ScheduleCalendar)
