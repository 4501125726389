import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { observer } from 'mobx-react'
import styled from 'styled-components/native'
import PropTypes from 'lasagna/prop_types'
import Icon from 'lasagna/components/icon'
import Text from 'lasagna/components/text'
import { COLOR_BLACK } from '../../../../constants/layout'
import { useNavigation } from '../../../../helpers/navigation'
import { useStore } from '../../../../helpers/store'
import {
  signInPagePath,
  homePagePath,
  schedulePath,
  contactUsPath,
  invoicesPath,
  accountDetailsPath,
  contractsPath
} from '../../../../helpers/urls'
import SidebarContainer from './sidebar_container'
import NewBookingButton from '../new_booking'

const CloseButtonContainer = styled(View)`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1000;
`

const SidebarItems = styled(View)`
  padding: 80px 0 30px 0;
`

const SidebarItemContainer = styled(TouchableOpacity)`
  padding: 20px 50px;
`

const SidebarItemText = styled(Text)`
  color: ${COLOR_BLACK};
  font-size: 18px;
`

const NewBookingButtonContainer = styled(View)`
  padding: 20px 50px 20px 45px;
`

const SidebarItem = ({ onPress, children }) => (
  <SidebarItemContainer onPress={onPress}>
    <SidebarItemText>{children}</SidebarItemText>
  </SidebarItemContainer>
)

SidebarItem.propTypes = {
  onPress: PropTypes.func,
  children: PropTypes.node
}

const SidebarContent = observer(() => {
  const navigate = useNavigation()
  const {
    session: { closeSidebar, signOut, currentUser }
  } = useStore()

  const onSignOut = () => signOut().then(() => navigate(signInPagePath()))

  const redirect = path => {
    closeSidebar()
    navigate(path)
  }

  return (
    <SidebarContainer>
      <CloseButtonContainer>
        <TouchableOpacity onPress={closeSidebar}>
          <Icon name='close' color={COLOR_BLACK} size={48} />
        </TouchableOpacity>
      </CloseButtonContainer>
      <SidebarItems>
        <SidebarItem onPress={() => redirect(homePagePath())}>{i18n.t('navigation.overview')}</SidebarItem>
        {currentUser && (
          <>
            <SidebarItem onPress={() => redirect(contractsPath())}>{i18n.t('navigation.contracts')}</SidebarItem>
            <SidebarItem onPress={() => redirect(schedulePath())}>{i18n.t('navigation.schedule')}</SidebarItem>
            <SidebarItem onPress={() => redirect(contactUsPath())}>{i18n.t('navigation.contact_us')}</SidebarItem>
            <SidebarItem onPress={() => redirect(invoicesPath())}>{i18n.t('navigation.invoices')}</SidebarItem>
            <SidebarItem onPress={() => redirect(accountDetailsPath())}>
              {i18n.t('navigation.account_details')}
            </SidebarItem>
            <SidebarItem onPress={onSignOut}>{i18n.t('navigation.sign_out')}</SidebarItem>
            <NewBookingButtonContainer>
              <NewBookingButton />
            </NewBookingButtonContainer>
          </>
        )}
      </SidebarItems>
    </SidebarContainer>
  )
})

export default SidebarContent
