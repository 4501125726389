import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from '../helpers/store'
import useQueryParams from '../helpers/hooks/use_query_params'
import { observer } from 'mobx-react'
import { useResponsive } from 'lasagna/components/responsive'
import StatusBar from '../components/offer/status_bar'
import Summary from '../components/offer/summary'
import MaintenanceCleaningServiceDetails from '../components/offer/maintenance_cleaning_service_details'
import GenericServiceDetails from '../components/offer/generic_service_details'
import Price from '../components/offer/price'
import Actions from '../components/offer/actions'
import Terms from '../components/offer/terms'
import AcceptedNotification from '../components/offer/accepted_notification'
import ContentContainer from '../components/layout/content_container'
import Spinner from '../components/spinner'
import ContractNotFound from '../components/contracts/not_found'
import OverviewLink from '../components/contracts/overview_link'
import AdditionalAgreements from '../components/offer/additional_agreements'
import VerticalsModal from '../components/offer/verticals_modal'
import { PUBLIC } from '../constants/access'

const OfferPage = ({
  match: {
    params: { id, token }
  }
}) => {
  const query = useQueryParams()
  const rawCustomerId = query.get('id')
  const locale = query.get('locale')

  const [showVerticalsModal, setShowVerticalsModal] = useState(false)

  const {
    contracts: { fetchContract, get, acceptContractOffer },
    session: { currentUser, updateLocale },
    analytics: { autoTrack }
  } = useStore()

  useEffect(() => {
    fetchContract(Number(id), token)
    if (rawCustomerId) autoTrack('finish form', rawCustomerId, 'EventTracking', 'transaction')
    if (locale) updateLocale(locale)
  }, [])

  const { isDesktop } = useResponsive()

  const contract = get(id)

  if (contract === undefined) return <Spinner />

  if (contract === null) return <ContractNotFound access={PUBLIC} />

  const { accepted, fromAutomatedLead, additionalAgreements, serviceDetail, serviceType } = contract

  const acceptOffer = async () => {
    const success = await acceptContractOffer(parseInt(id), token)
    if (success) {
      if (fromAutomatedLead) {
        autoTrack('customer accepted', 'automated contracts')
      }
      if (serviceType !== 'water_dispenser' && serviceType !== 'fruit_basket_delivery') {
        setShowVerticalsModal(true)
      }
    }
  }

  return (
    <ContentContainer access={PUBLIC} testID='Offer' banner={<AcceptedNotification accepted={accepted} />}>
      {currentUser && <OverviewLink />}
      {isDesktop && !currentUser && <StatusBar />}
      <Summary contract={contract} />
      {additionalAgreements && <AdditionalAgreements agreements={additionalAgreements} />}
      {serviceDetail?.serviceDescription && <GenericServiceDetails contract={contract} />}
      {serviceDetail?.areas && <MaintenanceCleaningServiceDetails contract={contract} />}
      <Price contract={contract} />
      {!accepted && <Actions id={id} token={token} automated={fromAutomatedLead} acceptOffer={acceptOffer} />}
      {showVerticalsModal && <VerticalsModal handleClose={() => setShowVerticalsModal(false)} id={id} token={token} />}
      <Terms />
    </ContentContainer>
  )
}

OfferPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      token: PropTypes.string
    })
  })
}

export default observer(OfferPage)
