import React from 'react'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import PropTypes from 'lasagna/prop_types'
import styled from 'styled-components/native'
import { COLOR_WHITE, COLOR_BLACK } from '../../constants/layout'
import { useResponsive } from 'lasagna/components/responsive'
import { useNavigation } from '../../helpers/navigation'
import { rootPath } from '../../helpers/urls'
import SidebarButton from './main_header/sidebar_button'
import Navbar from './main_header/navbar'
import LanguageSelector from './main_header/language_selector'
import Logo from './logo'

const Container = styled(View)`
  background: ${COLOR_WHITE};
  height: 72px;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

const Content = styled(View)`
  width: 100%;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 72px;
`

const LeftSideContainer = styled(View)`
  flex: 1;
  justify-content: center;
`

const Title = styled(Text).attrs(() => ({
  numberOfLines: 1
}))`
  font-size: 18px;
  color: ${COLOR_BLACK};
`

const MainHeader = ({ title }) => {
  const navigate = useNavigation()
  const { isDesktop } = useResponsive()

  return (
    <Container>
      <Content>
        <LeftSideContainer>
          {title ? <Title>{title}</Title> : <Logo onPress={() => navigate(rootPath())} />}
        </LeftSideContainer>
        {!isDesktop && <LanguageSelector />}
        {isDesktop ? <Navbar /> : <SidebarButton />}
      </Content>
    </Container>
  )
}

MainHeader.propTypes = {
  title: PropTypes.string
}

export default MainHeader
