import React, { useEffect } from 'react'
import { View, TouchableOpacity } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import AppointmentCard from './card'
import { COLOR_BLACK } from '../../constants/layout'
import { useStore } from '../../helpers/store'
import Spinner from '../spinner'

const Title = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: 400;
  font-size: 18px;
  font-weight: 600;
  margin: 18px 6px 6px 0;
`

const ShowMore = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: 600;
  font-size: 18px;
  text-decoration: underline;
`

const ButtonContainer = styled(View)`
  align-items: center;
  margin: 16px 0 32px;
`

const FutureAppointments = () => {
  const {
    appointments: { fetchFutureAppointments, currentWeekAppointments, futureAppointments, loading, hasMoreFutureEvents }
  } = useStore()

  useEffect(() => {
    fetchFutureAppointments(true)
  }, [])

  if (loading && !futureAppointments.length) return <Spinner />

  return (
    <View>
      <Title>{i18n.t('schedule.future.this_week')}</Title>
      {currentWeekAppointments.map(appointment => (
        <AppointmentCard key={appointment.id} appointment={appointment} withActions />
      ))}
      {!currentWeekAppointments.length && !loading && <Text>{i18n.t('schedule.future.empty_this_week')}</Text>}

      <Title>{i18n.t('schedule.future.upcoming')}</Title>
      {futureAppointments.map(appointment => (
        <AppointmentCard key={appointment.id} appointment={appointment} withActions />
      ))}
      {!futureAppointments.length && !loading && <Text>{i18n.t('schedule.future.empty_upcoming')}</Text>}

      {hasMoreFutureEvents && (
        <ButtonContainer>
          <TouchableOpacity onPress={() => fetchFutureAppointments(false)}>
            <ShowMore>{i18n.t('schedule.show_more')}</ShowMore>
          </TouchableOpacity>
        </ButtonContainer>
      )}
    </View>
  )
}

export default observer(FutureAppointments)
