import { types, flow } from 'mobx-state-tree'
import api from '../api'

const ContactFormStore = types.model('ContactFormStore', {}).actions(self => ({
  submitContactUsForm: flow(function* (reason, body, customerId, eventId) {
    try {
      const {
        data: {
          submitFacilityServicesCustomerContactForm: { success }
        }
      } = yield api(self).submitContactUsForm({ reason, body, customerId: customerId, eventId })

      return success
    } catch (e) {
      return false
    }
  })
}))

export default ContactFormStore
