import React, { useEffect, Fragment } from 'react'
import { observer } from 'mobx-react'
import { View } from 'react-native'
import PropTypes from 'lasagna/prop_types'
import styled from 'styled-components/native'
import { useResponsive } from 'lasagna/components/responsive'
import { withNavigation } from '../../helpers/navigation'
import { signInPagePath } from '../../helpers/urls'
import { useStore } from '../../helpers/store'
import { USER, PUBLIC } from '../../constants/access'
import MainHeader from './main_header'
import Sidebar from './main_header/sidebar'
import Wrapper from './wrapper'

const SafeAreaContainer = styled(View)`
  flex: 1 1 auto;
  background: ${props => props.background};
  ${props =>
    props.isDesktop &&
    `
    align-items: center;
  `}
`

const ContentContainer = ({
  header = true,
  banner,
  background = '#F1F5F9',
  children,
  access = USER,
  navigate,
  ...props
}) => {
  const {
    session: { currentUser, locale }
  } = useStore()

  useEffect(() => {
    if (!currentUser && access !== PUBLIC) {
      navigate(signInPagePath())
    }
  })

  const { isDesktop } = useResponsive()

  const Container = isDesktop ? Fragment : Sidebar

  return (
    <Container key={locale}>
      {header && <MainHeader />}
      {banner}
      <SafeAreaContainer isDesktop={isDesktop} background={background} {...props}>
        <Wrapper>{children}</Wrapper>
      </SafeAreaContainer>
    </Container>
  )
}

ContentContainer.propTypes = {
  header: PropTypes.bool,
  banner: PropTypes.node,
  background: PropTypes.string,
  scrolling: PropTypes.bool,
  centered: PropTypes.bool,
  padding: PropTypes.number, // Android disallows boolean padding
  fullWidthOnDesktop: PropTypes.bool,
  children: PropTypes.node,
  contentContainerStyle: PropTypes.object,
  navigate: PropTypes.func,
  access: PropTypes.string
}

export default withNavigation(observer(ContentContainer))
