import { Platform } from 'react-native'
import { getEnvironmentVariable } from '../../constants/environment'

export const createHeaders = (headers, accessToken) => {
  // required for staging and testing
  const baseAuth = getEnvironmentVariable('EXPO_PUBLIC_API_AUTH')

  return {
    ...headers,
    ...(accessToken ? { 'X-Auth-Token': accessToken } : {}),
    ...(baseAuth ? { Authorization: `Basic ${baseAuth}` } : {}),
    'X-Platform': Platform.OS
  }
}
