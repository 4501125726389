import React, { useState } from 'react'
import Modal from 'react-native-modal'
import { View, TouchableOpacity, TextInput, ActivityIndicator } from 'react-native'
import { observer } from 'mobx-react'
import { DateTime } from 'luxon'
import styled from 'styled-components/native'
import PropTypes from 'lasagna/prop_types'
import Icon from 'lasagna/components/icon'
import Card from 'lasagna/components/layout/card'
import Text from 'lasagna/components/text'
import { useResponsive } from 'lasagna/components/responsive'
import AppointmentCard from './card'
import Button from '../button'
import {
  COLOR_LIGHT_ICON,
  TRANSPARENT,
  COLOR_ACTION,
  COLOR_BLACK,
  COLOR_BACKGROUND_DARK,
  COLOR_TEXT_SECONDARY,
  COLOR_TABLE_BORDER,
  COLOR_WHITE,
  COLOR_RED_ERROR_TEXT
} from '../../constants/layout'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import AppointmentModel from '../../models/appointment'
import { useStore } from '../../helpers/store'
import {
  EXCLUDED_DAYS,
  MAX_ALLOWED_RESCHEDULE_DATE,
  MIN_ALLOWED_RESCHEDULE_DATE,
  RESCHEDULE_EVENT_CUSTOMER_REASONS
} from '../../constants/reschedule_event'
import { Picker, ScrollView } from 'react-native-web'
import { COLOR_INPUT_LIGHT_BORDER } from 'lasagna/constants'
import { v4 as uuidv4 } from 'uuid'
import { RESCHEDULE_EVENT_SUCCESS } from '../../constants/request_banner'

const Content = styled(Card)`
  padding: 20px 32px 32px 32px;
  ${({ isDesktop }) =>
    isDesktop
      ? `width: 60%;
      max-height: 100%;
    `
      : `
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    max-height: 90%;
    overflow-y: scroll;
  `};
`

const Container = styled(Modal)`
  ${({ isDesktop }) =>
    isDesktop ? 'justify-content: center; align-items: center;' : 'justify-content: flex-end; margin: 0;'}
`

const HeaderContainer = styled(View)`
  display: flex;
  flex-direction: row;
`

const Close = styled(TouchableOpacity)`
  position: absolute;
  top: 16px;
  right: 20px;
`

const Title = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_LIGHT_ICON};
  padding-bottom: 16px;
  margin: 16px 0;
`

const ActionButton = styled(Button)`
  width: 240px;
  margin: 24px 12px 0 0;
`

const ActionText = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: 600;
  margin-left: 4px;
  font-size: 16px;
`

const Label = styled(Text)`
  margin: 32px 0 8px;
`

const Message = styled(TextInput)`
  border: 1px solid ${COLOR_LIGHT_ICON};
  border-radius: 5px;
  padding: 8px 16px;
  ${({ isDesktop }) => !isDesktop && 'min-height: 50px;'}
`

const ConfirmMessage = styled(Text)`
  color: ${COLOR_BACKGROUND_DARK};
`

const Confirmation = styled(View)`
  ${({ isDesktop }) => isDesktop && 'flex-direction: row;'}
  justify-content: space-between;
  margin: 24px 0;
  align-items: center;
`

const ConfirmationCard = styled(Card)`
  padding: 20px;
  margin-top: 8px;
  border-width: 1px;
  border-color: ${COLOR_LIGHT_ICON};
  ${({ gray }) => !gray && `background: ${COLOR_TABLE_BORDER};`}
`

const EventSection = styled(View)`
  width: ${({ isDesktop }) => (isDesktop ? '45%' : '80%')};
  margin-bottom: 8px;
`

const Row = styled(View)`
  ${({ isDesktop }) => isDesktop && 'flex-direction: row;'}
  align-items: center;
  width: 100%;
  ${({ withMargin }) => withMargin && 'margin-bottom: 8px;'}
`

const ConfirmationCardIcon = styled(Icon)`
  margin-right: 4px;
`

const StyledInput = styled(TextInput)`
  border: 1px solid ${COLOR_LIGHT_ICON};
  border-radius: 5px;
  padding: 8px 16px;
  margin-top: 10px;
`

const PickerWrapper = styled(View)`
  max-width: 400px;
`

const StartTimeOptions = styled(View)`
  max-width: 400px;
  z-index: 99;
`

const StyledPicker = styled(Picker)`
  border: 1px solid ${COLOR_INPUT_LIGHT_BORDER};
  background: ${COLOR_WHITE};
  padding: 10px 16px;
  border-radius: 5px;
  color: ${COLOR_BLACK};
`

const ErrorMessage = styled(Text)`
  margin: 20px 0px;
  color: ${COLOR_RED_ERROR_TEXT};
`

const RescheduleModal = ({ appointment, handleClose }) => {
  const { isDesktop } = useResponsive()
  const [showConfirmation, setShowConfirmation] = useState()
  const [reason, setReason] = useState()
  const [newDate, setNewDate] = useState()
  const [newDateOption2, setNewDateOption2] = useState()
  const [newDateOption3, setNewDateOption3] = useState()
  const [message, setMessage] = useState()
  const [error, setError] = useState()
  const [requesting, setRequesting] = useState(false)
  const {
    appointments: { reschedule, setShowRequestBanner, fetchAppointmentsForMonth }
  } = useStore()

  const { id, startTimeObj, reschedulingRequestPending } = appointment

  const reasonOptions = RESCHEDULE_EVENT_CUSTOMER_REASONS.map(key => ({
    key,
    label: i18n.t(`schedule.actions.reschedule.reasons.${key}`)
  }))

  const filterDate = date => !EXCLUDED_DAYS.includes(new Date(date).getDay())

  const selectedDateOptions = [newDate, newDateOption2, newDateOption3].filter(Boolean)

  const requestRescheduleAction = async () => {
    setRequesting(true)
    const { success, error } = await reschedule(id, reason, selectedDateOptions, message)
    await fetchAppointmentsForMonth()
    if (success) {
      handleClose()
      setShowRequestBanner(RESCHEDULE_EVENT_SUCCESS)
    } else {
      setShowConfirmation(false)
      setError(error)
    }
    setRequesting(false)
  }

  return (
    <Container isVisible isDesktop={isDesktop}>
      <Content isDesktop={isDesktop}>
        <HeaderContainer>
          <Close onPress={handleClose}>
            <Icon name='close' color={COLOR_LIGHT_ICON} />
          </Close>
          <Title>
            {i18n.t(
              reschedulingRequestPending
                ? 'schedule.actions.reschedule.edit_reschedule_title'
                : 'schedule.actions.reschedule.title'
            )}
          </Title>
        </HeaderContainer>
        <ScrollView>
          {!showConfirmation && (
            <>
              <AppointmentCard appointment={appointment} />
              <Label>{i18n.t('schedule.actions.reschedule.select_reason')}</Label>
              <PickerWrapper>
                <StyledPicker value={reason} onChange={e => setReason(e.target.value)}>
                  <Picker.Item value={null} />
                  {reasonOptions.map(({ key, label }) => (
                    <Picker.Item key={key} label={label} value={key} />
                  ))}
                </StyledPicker>
              </PickerWrapper>
              <Label>{i18n.t('schedule.actions.reschedule.select_time')}</Label>
              <StartTimeOptions>
                <DatePicker
                  selected={newDate}
                  onChange={selected => setNewDate(selected)}
                  dateFormat='dd/MM/yyyy HH:mm'
                  showTimeSelect
                  customInput={<StyledInput />}
                  minDate={MIN_ALLOWED_RESCHEDULE_DATE}
                  maxDate={MAX_ALLOWED_RESCHEDULE_DATE}
                  filterDate={filterDate}
                  isClearable
                />
                <DatePicker
                  selected={newDateOption2}
                  onChange={selected => setNewDateOption2(selected)}
                  dateFormat='dd/MM/yyyy HH:mm'
                  showTimeSelect
                  customInput={<StyledInput />}
                  minDate={MIN_ALLOWED_RESCHEDULE_DATE}
                  maxDate={MAX_ALLOWED_RESCHEDULE_DATE}
                  filterDate={filterDate}
                  isClearable
                />
                <DatePicker
                  selected={newDateOption3}
                  onChange={selected => setNewDateOption3(selected)}
                  dateFormat='dd/MM/yyyy HH:mm'
                  showTimeSelect
                  customInput={<StyledInput />}
                  minDate={MIN_ALLOWED_RESCHEDULE_DATE}
                  maxDate={MAX_ALLOWED_RESCHEDULE_DATE}
                  filterDate={filterDate}
                  isClearable
                />
              </StartTimeOptions>
              <Label>{i18n.t('schedule.actions.reschedule.add_message')}</Label>
              <Message numberOfLines={5} multiline onChangeText={setMessage} />
              {error && <ErrorMessage>{i18n.t('schedule.actions.reschedule.generic_message_error')}</ErrorMessage>}
              <Row isDesktop={isDesktop}>
                <ActionButton
                  onPress={() => setShowConfirmation(true)}
                  color={COLOR_ACTION}
                  textColor={COLOR_BLACK}
                  borderColor={COLOR_ACTION}
                  borderWidth={1}
                  disabled={!newDate || !reason}
                >
                  <ActionText>{i18n.t('schedule.actions.reschedule.send_request')}</ActionText>
                </ActionButton>
                <ActionButton
                  onPress={handleClose}
                  color={TRANSPARENT}
                  textColor={COLOR_BLACK}
                  borderColor={COLOR_BLACK}
                  borderWidth={1}
                >
                  <ActionText>{i18n.t('schedule.actions.reschedule.cancel')}</ActionText>
                </ActionButton>
              </Row>
            </>
          )}
          {showConfirmation && (
            <>
              <ConfirmMessage>{i18n.t('schedule.actions.reschedule.confirm_details')}</ConfirmMessage>
              <Confirmation isDesktop={isDesktop}>
                <EventSection isDesktop={isDesktop}>
                  <Text>{i18n.t('schedule.actions.reschedule.from')}</Text>
                  <ConfirmationCard>
                    <Row withMargin isDesktop>
                      <ConfirmationCardIcon
                        set='MaterialCommunityIcons'
                        name='calendar-month'
                        color={COLOR_TEXT_SECONDARY}
                        size={16}
                      />
                      <Text>{startTimeObj.toLocaleString(DateTime.DATE_HUGE)}</Text>
                    </Row>
                    <Row isDesktop>
                      <ConfirmationCardIcon
                        set='MaterialCommunityIcons'
                        name='clock-outline'
                        color={COLOR_TEXT_SECONDARY}
                        size={16}
                      />
                      <Text>{startTimeObj.toLocaleString(DateTime.TIME_SIMPLE)}</Text>
                    </Row>
                  </ConfirmationCard>
                </EventSection>
                <Icon set='MaterialCommunityIcons' name={isDesktop ? 'arrow-right' : 'arrow-down'} />
                <EventSection isDesktop={isDesktop}>
                  <Text>{i18n.t('schedule.actions.reschedule.to')}</Text>
                  {selectedDateOptions.map(newDate => (
                    <ConfirmationCard gray key={uuidv4()}>
                      <Row withMargin isDesktop>
                        <ConfirmationCardIcon
                          set='MaterialCommunityIcons'
                          name='calendar-month'
                          color={COLOR_TEXT_SECONDARY}
                          size={16}
                        />
                        <Text>{DateTime.fromISO(newDate.toISOString()).toLocaleString(DateTime.DATE_HUGE)}</Text>
                      </Row>
                      <Row isDesktop>
                        <ConfirmationCardIcon
                          set='MaterialCommunityIcons'
                          name='clock-outline'
                          color={COLOR_TEXT_SECONDARY}
                          size={16}
                        />
                        <Text>{DateTime.fromISO(newDate.toISOString()).toLocaleString(DateTime.TIME_SIMPLE)}</Text>
                      </Row>
                    </ConfirmationCard>
                  ))}
                </EventSection>
              </Confirmation>
              <Row isDesktop={isDesktop}>
                <ActionButton
                  onPress={requestRescheduleAction}
                  color={COLOR_ACTION}
                  textColor={COLOR_BLACK}
                  borderColor={COLOR_ACTION}
                  borderWidth={1}
                  disabled={requesting}
                >
                  <ActionText>{i18n.t('schedule.actions.reschedule.confirm')}</ActionText>
                  {requesting && <ActivityIndicator size={18} color={COLOR_BLACK} style={{ marginLeft: 5 }} />}
                </ActionButton>
                <ActionButton
                  onPress={() => setShowConfirmation(false)}
                  color={TRANSPARENT}
                  textColor={COLOR_BLACK}
                  borderColor={COLOR_BLACK}
                  borderWidth={1}
                >
                  <ActionText>{i18n.t('schedule.actions.reschedule.reselect_time')}</ActionText>
                </ActionButton>
              </Row>
            </>
          )}
        </ScrollView>
      </Content>
    </Container>
  )
}

RescheduleModal.propTypes = {
  appointment: PropTypes.modelOf(AppointmentModel),
  handleClose: PropTypes.func
}

export default observer(RescheduleModal)
