import { types, flow, getRoot } from 'mobx-state-tree'
import Contract from '../models/contracts'
import api from '../api'

const ContractsStore = types
  .model('ContractsStore', {
    collection: types.map(types.maybeNull(Contract)),
    loading: types.optional(types.boolean, false),
    error: types.optional(types.boolean, false)
  })
  .actions(self => ({
    fetchContracts: flow(function* () {
      self.loading = true

      const {
        data: {
          facilityServices: {
            offers: { nodes: offers },
            activeContracts: { nodes: activeContracts }
          }
        }
      } = yield api(self).fetchContractsForCustomerContact()

      const contracts = offers.concat(activeContracts).map(offer => self.put(offer))

      self.loading = false

      return contracts
    }),
    fetchContract: flow(function* (id, token) {
      self.loading = true
      const {
        data: {
          facilityServices: {
            customerContracts: { nodes }
          }
        }
      } = yield api(self).fetchCustomerContractById({ id: Number(id), token })

      nodes.forEach(offer => self.put(offer))

      if (!nodes.length) self.collection.set(id, null)

      self.loading = false
    }),
    acceptContractOffer: flow(function* (id, token) {
      try {
        const {
          data: {
            acceptFacilityServicesCustomerContract: { success }
          }
        } = yield api(self).acceptContractOffer({ id, token })

        if (success) {
          const contract = self.collection.get(id)
          contract.accepted = true
        } else {
          self.error = true
        }
        return success
      } catch (e) {
        self.error = true
        return false
      }
    }),
    rejectContractOffer: flow(function* (id, token, reason, notes) {
      try {
        const {
          data: {
            rejectFacilityServicesCustomerContract: { success }
          }
        } = yield api(self).rejectContractOffer({ id, token, notes, reason })

        if (success) {
          const contract = self.collection.get(id)
          contract.rejected = true
        }
        return success
      } catch (e) {
        return false
      }
    }),
    put(contract) {
      const { address, facility, customerMonthlyPrice, pause, ...rest } = contract

      const offer = {
        ...rest,
        customer: getRoot(self).customers.put(facility),
        address: address.address,
        price: customerMonthlyPrice / 100,
        pauseEndDate: pause?.endAt
      }
      return self.collection.put(offer)
    }
  }))
  .views(self => ({
    get(id) {
      return self.collection.get(id)
    },

    get contracts() {
      return Array.from(self.collection.values())
    },

    get offers() {
      return self.contracts.filter(({ accepted }) => !accepted)
    },

    get activeContracts() {
      return self.contracts.filter(({ accepted }) => accepted)
    }
  }))

export default ContractsStore
