import { types } from 'mobx-state-tree'
import { DateTime } from 'luxon'
import Provider from './provider'

const Appointment = types
  .model('Appointment', {
    id: types.identifier,
    startTime: types.string,
    customerId: types.string,
    serviceType: types.string,
    serviceName: types.maybeNull(types.string),
    cancelled: types.boolean,
    extra: types.boolean,
    provider: types.maybeNull(Provider),
    serviceAddress: types.maybeNull(types.string),
    confirmed: types.boolean,
    canRequestReschedule: types.boolean,
    reschedulingRequestPending: types.boolean,
    isBankHoliday: types.boolean
  })
  .views(self => ({
    get startTimeObj() {
      return DateTime.fromISO(self.startTime)
    },
    get startDate() {
      return self.startTimeObj.toFormat('yyyy-MM-dd')
    },
    get month() {
      return self.startTimeObj.month
    }
  }))

export default Appointment
