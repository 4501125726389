import React from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react'
import styled from 'styled-components/native'
import { DateTime } from 'luxon'
import PropTypes from 'lasagna/prop_types'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import { RECURRENCY } from '../../../constants/contracts'
import ContractModel from '../../../models/contracts'
import { COLOR_HIGHLIGHT_RED } from '../../../constants/layout'

const Container = styled(View)`
  padding-top: 12px;
`

const DetailCard = styled(Card)`
  margin-bottom: 8px;
`

const Row = styled(View)`
  flex-direction: row;
  margin-bottom: 8px;
`

const Label = styled(Text)`
  font-weight: 700;
  width: 120px;
  min-width: 120px;
`

const Value = styled(Text)`
  padding-left: 4px;
`

const ContractEndDate = styled(Value)`
  color: ${COLOR_HIGHLIGHT_RED};
`

const Price = styled(ContractEndDate)`
  font-weight: 700;
`

const State = styled(Value)`
  font-weight: 700;
`

const GeneralInfo = ({ contract }) => {
  const {
    customer: { name: companyName, facilityType, facilityTypeNote },
    firstServiceAt,
    address,
    duration,
    price,
    serviceDetail: { size, recurrency },
    paused,
    pauseEndDate,
    endAt,
    showEndDate,
    additionalAgreements
  } = contract
  return (
    <Container>
      <DetailCard>
        <Row>
          <Label>{i18n.t('contracts.details.contract_type')}</Label>
          <Value>{i18n.t('service_types.maintenance_cleaning.title')}</Value>
        </Row>
        <Row>
          <Label>{i18n.t('contracts.details.monthly_price')}</Label>
          <Price>{i18n.t('contracts.details.formatted_price', { price })}</Price>
        </Row>
        {paused && (
          <Row>
            <Label>{i18n.t('contracts.details.state.title')}</Label>
            <State>{i18n.t('contracts.details.state.paused')}</State>
          </Row>
        )}
        {!paused && showEndDate && (
          <Row>
            <Label>{i18n.t('contracts.details.state.title')}</Label>
            <State>{i18n.t('contracts.details.state.stopped')}</State>
          </Row>
        )}
      </DetailCard>
      <DetailCard>
        <Row>
          <Label>{i18n.t('contracts.details.company_name')}</Label>
          <Value>{companyName}</Value>
        </Row>
        <Row>
          <Label>{i18n.t('contracts.details.period', { duration: '' })}</Label>
          <Value>
            {duration} {i18n.t('contracts.details.duration_months')}
          </Value>
        </Row>
        {facilityType && (
          <Row>
            <Label>{i18n.t('contracts.details.facility_type')}</Label>
            <Value>
              {i18n.t(`contracts.details.facility_types.${facilityType}`)}
              {facilityTypeNote && ` - ${facilityTypeNote}`}
            </Value>
          </Row>
        )}
      </DetailCard>
      <DetailCard>
        <Row>
          <Label>{i18n.t('contracts.start_date', { date: '' })}</Label>
          <Value>{DateTime.fromISO(firstServiceAt).toLocaleString(DateTime.DATE_SHORT)}</Value>
        </Row>
        {paused && (
          <Row>
            <Label>{i18n.t('contracts.details.pause_end_date')}</Label>
            <ContractEndDate>{pauseEndDate.toLocaleString(DateTime.DATE_SHORT)}</ContractEndDate>
          </Row>
        )}
        {!paused && showEndDate && (
          <Row>
            <Label>{i18n.t('contracts.details.contract_end_date')}</Label>
            <ContractEndDate>{endAt.toLocaleString(DateTime.DATE_SHORT)}</ContractEndDate>
          </Row>
        )}
      </DetailCard>
      <DetailCard>
        <Row>
          <Label>{i18n.t('contracts.details.service_address')}</Label>
          <Value>{address}</Value>
        </Row>
      </DetailCard>
      <DetailCard>
        <Row>
          <Label>{i18n.t('contracts.details.size')}</Label>
          <Value>{i18n.t('contracts.details.area', { size })}</Value>
        </Row>
        <Row>
          <Label>{i18n.t('contracts.details.recurrency.title')}</Label>
          <Value>
            {recurrency >= RECURRENCY.weekly && i18n.t('contracts.details.recurrency.week', { recurrency })}
            {recurrency === RECURRENCY.monthly && i18n.t('contracts.details.recurrency.month')}
            {recurrency === RECURRENCY.fortnightly && i18n.t('contracts.details.recurrency.fortnight')}
          </Value>
        </Row>
      </DetailCard>
      {additionalAgreements && (
        <DetailCard>
          <Row>
            <Label>{i18n.t('contracts.details.additional_agreements')}</Label>
            <Value>{additionalAgreements}</Value>
          </Row>
        </DetailCard>
      )}
    </Container>
  )
}

GeneralInfo.propTypes = {
  contract: PropTypes.modelOf(ContractModel)
}

export default observer(GeneralInfo)
