import React from 'react'
import BaseText from 'lasagna/components/text'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Card from 'lasagna/components/layout/card'
import { COLOR_BLACK } from 'lasagna/constants/colors'

const Text = styled(BaseText)`
  color: ${COLOR_BLACK};
`

const SectionTitle = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
`

const Agreements = styled(Card)`
  padding: 24px;
  margin-top: 16px;
`

const AdditionalAgreements = ({ agreements }) => (
  <Agreements>
    <SectionTitle>{i18n.t('contracts.details.additional_agreements')}</SectionTitle>
    <Text>{agreements}</Text>
  </Agreements>
)

AdditionalAgreements.propTypes = {
  agreements: PropTypes.string
}

export default observer(AdditionalAgreements)
