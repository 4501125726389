import React from 'react'
import PropTypes from 'prop-types'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import Icon from 'lasagna/components/icon'
import { COLOR_BLACK } from '../../constants/layout'
import { useNavigation } from '../../helpers/navigation'
import { schedulePath } from '../../helpers/urls'

const Header = styled(View)`
  align-items: flex-end;
  margin: 16px 0 12px;
`

const LinkText = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: 600;
  text-decoration: underline;
  margin-right: 4px;
`

const Link = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
`

const LinkIcon = styled(View)`
  border: 2px solid ${COLOR_BLACK};
  border-radius: 100%;
`

const ScheduleTabHeader = ({ customerId }) => {
  const navigate = useNavigation()
  return (
    <Header>
      <Link onPress={() => navigate(schedulePath(customerId))}>
        <LinkText>{i18n.t('contract.details.all_appointments')}</LinkText>
        <LinkIcon>
          <Icon name='arrow-forward' color={COLOR_BLACK} size={18} />
        </LinkIcon>
      </Link>
    </Header>
  )
}

ScheduleTabHeader.propTypes = {
  customerId: PropTypes.string
}

export default ScheduleTabHeader
