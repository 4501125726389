export const COLOR_WHITE = '#FFFFFF'
export const COLOR_BACKGROUND_DARK = '#014692'
export const COLOR_BACKGROUND_LIGHT = '#E5ECF4'
export const COLOR_TEXT_PRIMARY = '#B5B5B5'
export const COLOR_TEXT_SECONDARY = '#828282'
export const COLOR_TEXT_LIGHT = '#EBF1FA'
export const COLOR_TEXT_DARK = '#363636'
export const COLOR_BORDER_INPUT = '#E0E7EE'
export const COLOR_LIGHT_ICON = '#cccccc'
export const COLOR_BLACK = '#000000'
export const COLOR_LABEL_LIGHT = '#F1F5F9'
export const COLOR_HIGHLIGHT_BLUE = '#289AE0'
export const COLOR_ACTION = '#FFCD00'
export const COLOR_ACTION_LIGHT = '#FCD100'
export const TRANSPARENT = 'transparent'
export const COLOR_HIGHLIGHT_YELLOW = '#FCE7C4'
export const COLOR_HIGHLIGHT_RED = '#DE6063'
export const COLOR_HIGHLIGHT_GREEN = '#00C74F'
export const COLOR_TABLE_BORDER = '#DDDDDD'
export const COLOR_TABLE_HEADER_BACKGROUND = '#EDEDED'
export const COLOR_NOTIFICATION_BANNER = '#C7D3E9'
export const COLOR_RED_ACTION = '#e04428'
export const COLOR_GREEN_ACTION = '#b1dd8c'
export const COLOR_RED_ERROR_TEXT = '#cf4229'
export const COLOR_ALERT_BACKGROUND = '#e0c7b6'
