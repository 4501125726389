import createi18nStore from 'lasagna/translations'
import { urlToLocales } from '../../helpers/urls'
import { debug } from '../../helpers/sentry'

const LOCALES_MAP_KEY = 'h2fs-customer-app'

const LOCAL_TRANSLATIONS = {
  ch: require('../../translations/ch.json'),
  ch_en: require('../../translations/ch_en.json'),
  ch_fr: require('../../translations/ch_fr.json'),
  de: require('../../translations/de.json'),
  de_en: require('../../translations/de_en.json'),
  en: require('../../translations/en.json'),
  fr: require('../../translations/fr.json'),
  ie: require('../../translations/ie.json'),
  it: require('../../translations/it.json'),
  nl: require('../../translations/nl.json'),
  nl_en: require('../../translations/nl_en.json'),
  sg: require('../../translations/sg.json'),
  uk: require('../../translations/uk.json')
}

export default createi18nStore({
  mapKey: LOCALES_MAP_KEY,
  localesUrl: (country) => urlToLocales('/locales', country),
  localTranslations: LOCAL_TRANSLATIONS,
  debug: (message, opts) => debug('phrase', message, opts)
})
