import React from 'react'
import { View } from 'react-native'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Card from 'lasagna/components/layout/card'
import { useResponsive } from 'lasagna/components/responsive'
import { COLOR_TEXT_DARK, COLOR_LIGHT_ICON } from '../../constants/layout'
import AdditionalAgreements from './additional_agreements'

const Header = styled(View)`
  ${props => props.isDesktop && 'flex-direction: row;'}
  margin: 30px 20px 12px 10px;
  align-items: center;
  justify-content: space-between;
`

const SectionTitle = styled(Text)`
  font-size: 20px;
  color: ${COLOR_TEXT_DARK};
  font-weight: 600;
`

const ServiceDescription = styled(Card)`
  padding: 24px;
`

const Separator = styled(View)`
  border-top-width: 1px;
  border-top-color: ${COLOR_LIGHT_ICON};
  margin: 24px 0 0;
`

const ContractDetails = ({ description, agreements }) => {
  const { isDesktop } = useResponsive()
  return (
    <>
      <Separator />
      {agreements && <AdditionalAgreements agreements={agreements} />}
      <Header isDesktop={isDesktop}>
        <SectionTitle>{i18n.t('contracts.details.service_overview.title')}</SectionTitle>
      </Header>
      <ServiceDescription>
        <Text>{description}</Text>
      </ServiceDescription>
    </>
  )
}

ContractDetails.propTypes = {
  description: PropTypes.string,
  agreements: PropTypes.string
}

export default observer(ContractDetails)
