import React from 'react'
import Text from 'lasagna/components/text'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import PropTypes from 'lasagna/prop_types'
import Card from 'lasagna/components/layout/card'
import { COLOR_BLACK } from 'lasagna/constants/colors'
import ContractModel from '../../models/contracts'

const Description = styled(Text)`
  color: ${COLOR_BLACK};
`

const Title = styled(Description)`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
`

const Container = styled(Card)`
  padding: 24px;
  margin: 16px 0;
`

const ContractDetails = ({
  contract: {
    serviceDetail: { serviceDescription }
  }
}) => {
  return (
    <Container>
      <Title>{i18n.t('contracts.details.service_overview.title')}</Title>
      <Description>{serviceDescription}</Description>
    </Container>
  )
}

ContractDetails.propTypes = {
  contract: PropTypes.modelOf(ContractModel)
}

export default observer(ContractDetails)
