import { types, flow, getRoot } from 'mobx-state-tree'
import api from '../../api'

const DashboardStore = types
  .model('DashboardStore', {
    loading: types.optional(types.boolean, false),
    activeContractsCount: types.optional(types.number, 0),
    activeServiceTypes: types.array(types.string)
  })
  .actions(self => ({
    fetchFirstInvoice: flow(function* () {
      const {
        data: {
          facilityServices: {
            customerInvoices: { edges }
          }
        }
      } = yield api(self).fetchInvoices({
        first: 1
      })

      const invoices = edges.map(invoice => getRoot(self).invoices.put(invoice.node))

      return invoices
    }),
    fetchOffers: flow(function* () {
      const {
        data: {
          facilityServices: {
            offers: { nodes: offers }
          }
        }
      } = yield api(self).fetchOffersForCustomerContact()

      const contracts = offers.map(offer => getRoot(self).contracts.put(offer))

      return contracts
    }),
    fetchLatestActiveContracts: flow(function* () {
      const {
        data: {
          facilityServices: {
            customerContracts: { edges: activeContracts, totalCount }
          }
        }
      } = yield api(self).fetchActiveContracts({ first: 3 })
      self.activeContractsCount = totalCount
      activeContracts.forEach(({ node }) => getRoot(self).contracts.put(node))
    }),
    fetchActiveServiceTypes: flow(function* () {
      const {
        data: {
          facilityServices: {
            customerContracts: { nodes }
          }
        }
      } = yield api(self).fetchActiveContractTypes()
      const allServiceTypes = nodes.map(({ serviceType }) => serviceType)
      self.activeServiceTypes = [...new Set(allServiceTypes)]
    }),
    fetch: flow(function* () {
      self.loading = true

      yield Promise.all([
        self.fetchFirstInvoice(),
        self.fetchOffers(),
        self.fetchLatestActiveContracts(),
        self.fetchActiveServiceTypes()
      ])

      self.loading = false
    })
  }))
  .views(self => ({
    get latestActiveContracts() {
      return getRoot(self).contracts.activeContracts.slice(0, 3)
    },
    get latestInvoice() {
      return getRoot(self).invoices.invoices.slice(0, 1)
    }
  }))

export default DashboardStore
