import { types, flow } from 'mobx-state-tree'
import api from '../api'

const VerticalsStore = types.model('VerticalsStore', {}).actions(self => ({
  requestNewService: flow(function* ({service, customerContractId, notes, token, facilityId}) {
    try {
      const {
        data: {
          requestFacilityServicesService: { success }
        }
      } = yield api(self).requestFacilityServicesService({ service, customerContractId, notes, token, facilityId  })

      return success
    } catch (e) {
      return false
    }
  })
}))

export default VerticalsStore
