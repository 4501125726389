import React from 'react'
import PropTypes from 'prop-types'
import { View, Text } from 'react-native'
import styled from 'styled-components/native'
import { COLOR_WHITE } from 'lasagna/constants/colors'

import VERSION from '../constants/version'

const VersionNumberContainer = styled(View)`
  position: absolute;
  bottom: 15px;
  right: 15px;
`

const VersionNumberText = styled(Text)`
  opacity: ${(props) => props.opacity};
  color: ${(props) => props.color};
`

const VersionNumber = ({ textColor = COLOR_WHITE, opacity = 0.3 }) => (
  <VersionNumberContainer>
    <VersionNumberText color={textColor} opacity={opacity}>
      {VERSION}
    </VersionNumberText>
  </VersionNumberContainer>
)

VersionNumber.propTypes = {
  textColor: PropTypes.string,
  opacity: PropTypes.number
}

export default VersionNumber
