import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import styled from 'styled-components/native'
import { COLOR_BLACK } from '../constants/layout'

const SpinnerContainer = styled(View)`
  justify-content: center;
  align-items: center;
  padding: 50px;
`

const Spinner = () => {
  return (
    <SpinnerContainer>
      <ActivityIndicator color={COLOR_BLACK} size='large' />
    </SpinnerContainer>
  )
}

export default Spinner
